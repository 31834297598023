/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { isFulfilled } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import DatePicker from '@mui/lab/DatePicker'
import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  CardActions,
  Box,
  MenuItem,
  Button,
} from '@mui/material'

/**
 * The internal imports
 */
import CreateOffDay from '../../Store/OffDay/Create'
import GetFilterableFieldsService from '../../Services/GetFilterableFields'
import { TextField, Select, FileUploader, Loader } from '../../Components'
import useStyles from '../../Theme/Components/Forms'
import UpdateOffDay from '../../Store/OffDay/Update'
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import FetchOneOffDay from '../../Store/OffDay/FetchOne'

const FormOffDays = ({ id }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { control, watch, setValue, handleSubmit } = useForm()
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [filterableFields, setFilterableFields] = useState({})
  const [offDay, setOffDay] = useState({})
  const [justifications, setJustifications] = useState([])
  const [justificationsToRemove, setJustificationsToRemove] = useState([])
  const [errors, setErrors] = useState({})

  // Get values from the store
  const createOffDayLoading = useSelector(state => state.offDay.create.loading)
  const updateOffDayLoading = useSelector(state => state.offDay.update.loading)

  const watchStartDate = watch('start_date')

  useEffect(() => {
    if (watchStartDate) {
      setValue('end_date', watchStartDate)
    }
  }, [watchStartDate])

  useEffect(async () => {
    const filterableFieldsResponse = await GetFilterableFieldsService(
      'off_days',
    )
    setFilterableFields(filterableFieldsResponse)

    if (id) {
      const offDayResponse = await dispatch(
        FetchOneOffDay.action({ url: `off_days/${id}` }),
      )
      if (isFulfilled(offDayResponse)) {
        setOffDay({
          ...offDayResponse.payload,
          start_date_hour: new Date(
            offDayResponse.payload.start_date,
          ).getHours(),
          end_date_hour: new Date(offDayResponse.payload.end_date).getHours(),
        })
        setJustifications(
          offDayResponse.payload.justifications_info.map(justification => ({
            id: justification.id,
            path: justification.filename,
          })),
        )
      }
    } else {
      setOffDay({})
    }
    setLoading(false)
  }, [])

  /**
   * Create new leave request
   */
  const onSubmit = async params => {
    const start_date = new Date(params.start_date)
    start_date.setHours(params.start_date_hour)
    start_date.setMinutes(0)
    start_date.setSeconds(0)

    const end_date = new Date(params.end_date)
    end_date.setHours(params.end_date_hour)
    end_date.setMinutes(0)
    end_date.setSeconds(0)

    if (start_date.getTime() > end_date.getTime()) {
      setErrors({
        start_date: [t('components.off_days.validations.in_future')],
        end_date: [t('components.off_days.validations.in_future')],
      })
      return null
    } else {
      setErrors({})
    }

    const data = {
      ...params,
      start_date,
      end_date,
      justifications: justifications.filter(justification => !justification.id),
    }

    delete data.start_date_hour
    delete data.end_date_hour

    if (id) {
      data.justificationsToRemove = justificationsToRemove
      const updateOffDayResponse = await dispatch(
        UpdateOffDay.action({ offDayId: id, params: data }),
      )
      if (isFulfilled(updateOffDayResponse)) {
        if (updateOffDayResponse.payload.success) {
          enqueueSnackbar(t('notifications.off_day_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateOffDayResponse.payload.errors)
        }
      }
    } else {
      const newOffDayResponse = await dispatch(CreateOffDay.action(data))
      if (isFulfilled(newOffDayResponse)) {
        if (newOffDayResponse.payload.success) {
          enqueueSnackbar(t('notifications.off_day_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newOffDayResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('pages.profile.off_days')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Divider />
        <CardContent>
          <Controller
            name="imputable"
            control={control}
            defaultValue={offDay.imputable}
            render={({ field }) => (
              <Select
                name="imputable"
                id="imputable"
                label={t('off_day.imputable')}
                error={errors.imputable?.length > 0}
                helperText={errors.imputable && errors.imputable[0]}
                {...field}
              >
                {filterableFields.imputable.map(imputableItem => (
                  <MenuItem value={imputableItem}>
                    {t(`off_day.imputable_items.${imputableItem}`)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <h3>{t('components.off_days.from')}</h3>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="start_date"
                control={control}
                defaultValue={offDay.start_date || new Date()}
                render={({ field }) => (
                  <DatePicker
                    mask="__.__.____"
                    label={t('off_day.date')}
                    renderInput={params => <TextField {...params} />}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="start_date_hour"
                control={control}
                defaultValue={
                  offDay.start_date_hour
                    ? String(offDay.start_date_hour)
                    : offDay.start_date_hour
                }
                render={({ field }) => (
                  <Select
                    label={t('off_day.hour')}
                    name="start_date_hour"
                    id="start_date_hour"
                    error={errors.start_date?.length > 0}
                    helperText={errors.start_date && errors.start_date[0]}
                    {...field}
                  >
                    <MenuItem value="8">08:00</MenuItem>
                    <MenuItem value="12">12:00</MenuItem>
                    <MenuItem value="18">18:00</MenuItem>
                  </Select>
                )}
              />
            </Grid>
          </Grid>
          <h3>{t('components.off_days.to')}</h3>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="end_date"
                control={control}
                defaultValue={offDay.end_date || new Date()}
                render={({ field }) => (
                  <DatePicker
                    mask="__.__.____"
                    label={t('off_day.date')}
                    renderInput={params => <TextField {...params} />}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="end_date_hour"
                control={control}
                defaultValue={
                  offDay.end_date_hour
                    ? String(offDay.end_date_hour)
                    : offDay.end_date_hour
                }
                render={({ field }) => (
                  <Select
                    label={t('off_day.hour')}
                    name="end_date_hour"
                    id="end_date_hour"
                    error={errors.end_date?.length > 0}
                    helperText={errors.end_date && errors.end_date[0]}
                    {...field}
                  >
                    <MenuItem value="8">08:00</MenuItem>
                    <MenuItem value="12">12:00</MenuItem>
                    <MenuItem value="18">18:00</MenuItem>
                  </Select>
                )}
              />
            </Grid>
          </Grid>

          <Controller
            name="reason"
            control={control}
            defaultValue={offDay.reason || ''}
            render={({ field }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                label={t('off_day.reason')}
                name="reason"
                multiline
                rows={4}
                error={errors.reason?.length > 0}
                helperText={errors.reason && errors.reason[0]}
                {...field}
              />
            )}
          />

          <FileUploader
            files={justifications}
            setFiles={setJustifications}
            filesToRemove={justificationsToRemove}
            setFilesToRemove={setJustificationsToRemove}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={classes.submitAction}>
            <Button
              onClick={() => dispatch(ToggleVisibility.action())}
              variant="contained"
              size="large"
              color="secondary"
              disabled={createOffDayLoading || updateOffDayLoading}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={createOffDayLoading || updateOffDayLoading}
            >
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default FormOffDays
