/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import Create from './Create'
import GetAll from './GetAll'
import FetchOne from './FetchOne'
import Update from './Update'

export default buildSlice('offDay', [Create, GetAll, FetchOne, Update], {
  update: {},
  create: {},
  getAll: { item: {} },
  fetchOne: { item: {} },
}).reducer
