/**
 * The external imports
 */
import {
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import FetchAllService from '../../Services/FetchAll'

export default {
  initialState: {
    getAll: {
      item: {
        off_days: [],
        refund_requests: [],
        late_maintenances: [],
      },
      loading: false,
      error: null,
    },
  },
  action: buildAsyncActions('notifications/getAll', FetchAllService),
  reducers: buildAsyncReducers({
    itemKey: 'getAll.item',
    errorKey: 'getAll.error',
    loadingKey: 'getAll.loading',
  }),
}
