/**
 * The external imports
 */
import React from 'react'
import Lottie from 'react-lottie'

/**
 * The internal imports
 */
import meteor from './Lotties/meteor.json'
import beer from './Lotties/beer.json'
import covid19 from './Lotties/covid19.json'
import happyDog from './Lotties/happyDog.json'
import llama from './Lotties/llama.json'
import mario from './Lotties/mario.json'

const Loader = () => {
  const loaders = [mario, meteor, beer, covid19, happyDog, llama]
  const randomLoader = loaders[Math.floor(Math.random() * loaders.length)]

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: randomLoader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return <Lottie options={defaultOptions} height={200} width={200} />
}

export default Loader
