/**
 * The external imports
 */
import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import parse from 'html-react-parser'
import { Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

/**
 * The internal imports
 */
import { customFormat } from '../../Utils/Date'
import { openModal } from '../../Utils/Modal'
import { ExpandMore } from '..'
import InvoiceLinesTable from './InvoiceLinesTable'

const MaintenancePeriodCard = ({ maintenancePeriod, anchor, setAnchor }) => {
  const { start_date, end_date, id, description, invoice_lines } =
    maintenancePeriod
  const { t } = useTranslation()
  const { clientId, maintenanceId } = useParams()

  const [expanded, setExpanded] = useState(false)
  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      action: () =>
        openModal({
          type: 'formMaintenancePeriod',
          id,
          options: { clientId, maintenanceId },
        }),
    },
    {
      label: t('components.maintenance_period_card.add_invoice_line'),
      action: () =>
        openModal({
          type: 'formInvoiceLine',
          options: {
            clientId,
            maintenanceId,
            maintenancePeriodId: maintenancePeriod.id,
          },
        }),
    },
  ])

  /**
   * Closes the menu
   */
  const closeMenu = () => {
    setAnchor({ element: null, id: null })
  }

  /**
   * Closes the menu before calling the action associated with the option
   * @param {*} action function
   */
  const handleOptionClick = action => {
    closeMenu()
    action()
  }

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        action={
          <div>
            <ExpandMore
              expand={expanded}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
            <IconButton
              aria-label="menu"
              aria-haspopup="true"
              onClick={event => setAnchor({ element: event.currentTarget, id })}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`maintenance_period-menu-${id}`}
              anchorEl={anchor.element}
              open={anchor.id === id}
              onClose={closeMenu}
            >
              {menuOptions.map(option => (
                <MenuItem
                  key={`menu-item-${option.label}`}
                  onClick={() => handleOptionClick(option.action)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        }
        title={
          <Typography>
            {t('components.maintenance_period_card.title_start')}{' '}
            <b>{customFormat(new Date(start_date), 'dd MMMM yyyy')}</b>{' '}
            {t('components.maintenance_period_card.title_middle')}{' '}
            <b>{customFormat(new Date(end_date), 'dd MMMM yyyy')}</b>
          </Typography>
        }
      />
      <CardActions disableSpacing />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            {description.body ? parse(description.body) : ''}
          </Typography>
        </CardContent>
        <InvoiceLinesTable
          maintenancePeriodId={maintenancePeriod.id}
          invoiceLines={invoice_lines}
        />
      </Collapse>
    </Card>
  )
}

export default MaintenancePeriodCard
