/**
 * The external imports
 */
import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import Slide from '@mui/material/Slide'
import Appsignal from '@appsignal/javascript'
import { ErrorBoundary } from '@appsignal/react'

/**
 * The internal imports
 */
import Theme from './Theme'
import { store, persistor } from './Store'
import useStyles from './Theme/Components/Snackbar'
import axiosInstance from './Services'
import responseErrorInterceptor from './Services/responseErrorInterceptor'
import requestInterceptor from './Services/requestInterceptor'

const appsignal = new Appsignal({
  key: process.env.REACT_APP_APP_SIGNAL_KEY,
})

const FallbackComponent = () => <div>Uh oh! You f*cked up :(</div>

function App() {
  const classes = useStyles()

  // Axios request interceptor
  axiosInstance.interceptors.request.use(
    async config => requestInterceptor(config),
    error => Promise.reject(error),
  )

  // Axios response interceptor
  axiosInstance.interceptors.response.use(
    response => response,
    async error => await responseErrorInterceptor(error),
  )

  return (
    <ErrorBoundary
      instance={appsignal}
      fallback={error => <FallbackComponent />}
    >
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              classes={{
                root: classes.snackContainer,
              }}
              TransitionComponent={Slide}
              maxSnack={3}
            >
              <Theme />
            </SnackbarProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default App
