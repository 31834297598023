/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { Container, Typography, Button, MenuItem, Grid } from '@mui/material'
import {
  BarChart,
  LineChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import getYear from 'date-fns/getYear'
import { useForm, Controller } from 'react-hook-form'
import DatePicker from '@mui/lab/DatePicker'
import { saveAs } from 'file-saver'
import { isFulfilled } from '@reduxjs/toolkit'
import { useTheme } from '@mui/material/styles'

/**
 * The internal imports
 */
import GetStats from '../../Store/Statistics/GetStats'
import { Loader, TextField, Select } from '../../Components'
import DownloadExepense from '../../Store/Expense/Download'
import useStyles from '../../Theme/Pages/Statistics/index.style'
import { Config } from '../../Config'
import { monthAndYear } from '../../Utils/Date'

const Statistics = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const { control, handleSubmit } = useForm()
  const theme = useTheme()

  const statistics = useSelector(state => state.statistics.item)

  const [loading, setLoading] = useState(true)
  const [labelStyle] = useState({ color: 'black' })
  const [currentYear] = useState(getYear(new Date()))

  /**
   * Handles validation and get export
   */
  const onSubmit = async data => {
    const expenseResponse = await dispatch(
      DownloadExepense.action({
        params: data,
        url: 'expenses/monthly_export',
      }),
    )

    if (isFulfilled(expenseResponse)) {
      const blob = new Blob([expenseResponse.payload], {
        type: 'application/octet-stream',
      })
      saveAs(blob, `${monthAndYear(data.date)} - ${data.sector}.xlsx`)
    }
  }

  useEffect(async () => {
    if (!loading) {
      setLoading(true)
    }
    await dispatch(GetStats.action())
    setLoading(false)
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <Container maxWidth="xl">
      <Typography className={classes.titleWrapper}>
        {t('pages.statistics.export')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Grid
          container
          spacing={2}
          sx={{ pb: 2, mb: 2 }}
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Grid item xs={4}>
            <Controller
              name="date"
              control={control}
              defaultValue={new Date()}
              render={({ field }) => (
                <DatePicker
                  views={['year', 'month']}
                  required
                  label={t('pages.statistics.date')}
                  renderInput={params => <TextField {...params} />}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="sector"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <Select
                  label={t('pages.statistics.sector')}
                  variant="filled"
                  margin="normal"
                  required
                  name="marital_status"
                  id="marital_status"
                  fullWidth
                  {...field}
                >
                  {Config.SECTORS.map(sector => (
                    <MenuItem key={sector} value={sector}>
                      {t(`pages.statistics.sector_items.${sector}`)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={4} alignItems="center" justifyContent="center">
            <Button type="submit" variant="contained" color="primary">
              {t('actions.download')}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Typography className={classes.titleWrapper}>
        {t('pages.statistics.monthly_turnover_split')}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={statistics.monthly_turnover_bar}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Bar type="monotone" dataKey="web_turnover" fill="#8884d8" />
          <Bar type="monotone" dataKey="web_income" fill="hotpink" />
          <Bar type="monotone" dataKey="dev_turnover" fill="orange" />
          <Bar type="monotone" dataKey="dev_income" fill="green" />
          <Tooltip
            labelStyle={labelStyle}
            cursor={{ fill: 'transparent' }}
            formatter={(value, name) => [
              `${Number(value.toFixed(2)).toLocaleString()} CHF`,
              t(`pages.statistics.${name}`),
            ]}
          />
          <Legend formatter={value => t(`pages.statistics.${value}`)} />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={tick => tick.toLocaleString()} />
        </BarChart>
      </ResponsiveContainer>

      <Typography className={classes.titleWrapper}>
        {t('pages.statistics.monthly_turnover_year')}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={statistics.monthly_turnover_line}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Line type="monotone" dataKey={currentYear - 2} stroke="#8884d8" />
          <Line type="monotone" dataKey={currentYear - 1} stroke="hotpink" />
          <Line type="monotone" dataKey={currentYear} stroke="orange" />
          <Tooltip
            labelStyle={labelStyle}
            cursor={{ fill: 'transparent' }}
            formatter={(value, name) => [
              `${Number(value.toFixed(2)).toLocaleString()} CHF`,
              name,
            ]}
          />
          <Legend />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={tick => tick.toLocaleString()} />
        </LineChart>
      </ResponsiveContainer>

      <Typography className={classes.titleWrapper}>
        {t('pages.statistics.monthly_income_year')}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={statistics.monthly_income_line}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Line type="monotone" dataKey={currentYear - 2} stroke="#8884d8" />
          <Line type="monotone" dataKey={currentYear - 1} stroke="hotpink" />
          <Line type="monotone" dataKey={currentYear} stroke="orange" />
          <Tooltip
            labelStyle={labelStyle}
            cursor={{ fill: 'transparent' }}
            formatter={(value, name) => [
              `${Number(value.toFixed(2)).toLocaleString()} CHF`,
              name,
            ]}
          />
          <Legend />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={tick => tick.toLocaleString()} />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default Statistics
