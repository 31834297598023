/**
 * The internal imports
 */
import { store } from '../Store'
import AddUser from '../Store/User/Add'

/**
 * Handles the error returned from the api
 * @param message
 * @param data
 * @param status
 * @returns {Promise<unknown>}
 */
const handleError = ({ message, data, status }) =>
  Promise.reject({ message, data, status })

export default async error => {
  if (error.response) {
    // Default response
    let errorMessage = `Response status code <> 200 (${error.message})`

    // Response given by the application
    if (error.response.data.errors) {
      if (Array.isArray(error.response.data.errors)) {
        errorMessage = error.response.data.errors[0]
      } else {
        errorMessage = error.response.data.errors
      }
    }

    if (error.response.status === 401) {
      await store.dispatch(AddUser.action(error.response.data.data))
    }

    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return handleError({
      message: errorMessage,
      status: error.response.status,
      data: error.response.data,
    })
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js

    return handleError({
      message: `No response received (${error.message})`,
    })
  }
  // Something happened in setting up the request that triggered an Error
  return handleError({
    message: `Unknown error (${error.message})`,
  })
}
