/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { MoreVert } from '@mui/icons-material'

/**
 * The internal imports
 */
import { TableColumns } from '../../Config/TableColumns'
import CalculateParams from '../../Utils/CalculateParams'

const RowMenu = ({
  source,
  anchor,
  setAnchor,
  menuOptions,
  tableMeta,
  customFilter = null,
}) => {
  const [menu, setMenu] = useState({ visible: true, options: [] })
  const [rowDataId] = useState(
    tableMeta.rowData[
      TableColumns[source].findIndex(column => column.label === 'id')
    ],
  )

  useEffect(() => {
    let filteredMenuItems
    if (customFilter) {
      filteredMenuItems = customFilter(tableMeta, menuOptions, setAnchor)
    } else {
      filteredMenuItems = menuOptions.map(option => (
        <MenuItem
          key={`menu_item-${option.label}`}
          onClick={() => handleClick(option)}
        >
          {option.label}
        </MenuItem>
      ))
    }
    setMenu({
      visible: filteredMenuItems.length > 0,
      options: filteredMenuItems,
    })
  }, [tableMeta])

  /**
   * Builds the params required during the click action and executes the action
   * @param option
   */
  const handleClick = option => {
    closeMenu()
    const params = CalculateParams(option, tableMeta, source)
    option.action(params)
  }

  /**
   * Closes the menu
   */
  const closeMenu = () => {
    setAnchor({ element: null, id: null })
  }

  return (
    menu.visible && (
      <div>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={event =>
            setAnchor({
              element: event.currentTarget,
              id: rowDataId,
            })
          }
          size="large"
        >
          <MoreVert />
        </IconButton>
        <Menu
          id={`${source}-menu-${rowDataId}`}
          anchorEl={anchor.element}
          open={anchor.id === rowDataId}
          onClose={closeMenu}
        >
          {menu.options}
        </Menu>
      </div>
    )
  )
}

export default RowMenu
