/**
 * The external imports
 */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import clsx from 'clsx'
import { Container, Button, MenuItem, Typography } from '@mui/material'

/**
 * The internal imports
 */
import {
  MyRefundRequest,
  PendingRefundRequest,
  AllRefundRequest,
  Tabs,
} from '../../Components'
import { TableColumns } from '../../Config/TableColumns'
import useLayout from '../../Theme/Layouts/index'
import { openModal } from '../../Utils/Modal'

const RefundRequests = () => {
  const { t } = useTranslation()
  const layout = useLayout()

  const currentUser = useSelector(state => state.user.item)
  const [isAdmin] = useState(currentUser.role === 'admin')

  const handleEdit = ({ id }) => {
    openModal({
      type: 'formRefundRequest',
      id,
    })
  }

  /**
   * Custom filter for the menu options
   * @param tableMeta
   * @param menuOptions
   * @param setAnchor
   * @returns {[]}
   */
  const customMenuFilter = (tableMeta, menuOptions, setAnchor) => {
    const statusColIndex = TableColumns.refund_requests.findIndex(
      column => column.label === 'status',
    )
    const rowDataId =
      tableMeta.rowData[
        TableColumns.refund_requests.findIndex(column => column.label === 'id')
      ]

    const isEditable = tableMeta.rowData[statusColIndex] === 'pending'
    const filteredMenuItems = []
    menuOptions.forEach(option => {
      if ((option.type === 'edit' && isEditable) || option.type !== 'edit') {
        filteredMenuItems.push(
          <MenuItem
            key={rowDataId}
            onClick={() => {
              setAnchor({ element: null, id: null })
              option.action(rowDataId)
            }}
          >
            {option.label}
          </MenuItem>,
        )
      }
    })
    return filteredMenuItems
  }
  const tabs = [
    {
      label: t('pages.refund_requests.mine'),
      content: (
        <MyRefundRequest
          customMenuFilter={customMenuFilter}
          handleEditClick={handleEdit}
        />
      ),
      url: '/mine',
    },
    {
      label: t('pages.refund_requests.to_review'),
      content: <PendingRefundRequest handleEditClick={handleEdit} />,
      condition: isAdmin,
      url: '/to_review',
    },
    {
      label: t('pages.refund_requests.all'),
      content: (
        <AllRefundRequest
          customMenuFilter={customMenuFilter}
          handleEditClick={handleEdit}
        />
      ),
      condition: isAdmin,
      url: '/all',
    },
  ]
  return (
    <Container maxWidth="xl">
      <div className={clsx(layout.row, layout.flex)}>
        <Typography variant="h4" color="inherit">
          {t('pages.refund_requests.title')}
        </Typography>

        <Button
          onClick={() => openModal({ type: 'formRefundRequest' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Tabs tabs={tabs} baseUrl="/refund_requests" />
    </Container>
  )
}

export default RefundRequests
