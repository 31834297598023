/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import GetCashflow from './GetCashflow'

export default buildSlice('cashflow', [GetCashflow], {
  item: {},
}).reducer
