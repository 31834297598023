/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { isFulfilled } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  MenuItem,
  Grid,
  Divider,
  Box,
  Switch,
  FormControlLabel,
} from '@mui/material'
import DatePicker from '@mui/lab/DatePicker'

/**
 * The internal imports
 */
import UpdateUser from '../../Store/User/Update'
import { TextField, Select, Loader, FileUploader } from '../../Components'
import FetchOneUser from '../../Store/User/FetchOne'
import GetFilterableFieldsService from '../../Services/GetFilterableFields'
import { Config } from '../../Config'
import useForms from '../../Theme/Components/Forms'
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'

const EditUsers = ({ id }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()

  const [filterableFields, setFilterableFields] = useState({})
  const [loading, setLoading] = useState(true)

  // Get values from the store
  const currentUser = useSelector(state => state.user.item)
  const [user, setUser] = useState({})
  const updateUserLoading = useSelector(state => state.user.update.loading)
  const visibleModal = useSelector(state => state.modal.visible)
  const updateUserError = useSelector(state => state.user.update.error)
  const [documents, setDocuments] = useState([])
  const [documentsToRemove, setDocumentsToRemove] = useState([])
  const classes = useForms()

  useEffect(async () => {
    const filterableFieldsResponse = await GetFilterableFieldsService('users')
    setFilterableFields(filterableFieldsResponse)
    const userResponse = await dispatch(
      FetchOneUser.action({ url: `users/${id}` }),
    )
    if (isFulfilled(userResponse)) {
      setUser(userResponse.payload)
      setDocuments(
        userResponse.payload.documents_info.map(document => ({
          id: document.id,
          path: document.filename,
        })),
      )
    }
    setLoading(false)
  }, [])

  /**
   * Update user info
   */
  const onSubmit = async params => {
    const data = {
      ...params,
      documents: documents.filter(document => !document.id),
    }
    if (currentUser !== 'admin') {
      data.role = user.role
      data.approved = user.approved
    }
    data.documentsToRemove = documentsToRemove
    setDocumentsToRemove([])
    const updateUserResponse = await dispatch(UpdateUser.action({ data }))
    if (isFulfilled(updateUserResponse) && updateUserResponse.payload.success) {
      enqueueSnackbar(t('notifications.updated'), {
        variant: 'success',
      })
      if (visibleModal) {
        dispatch(ToggleVisibility.action())
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('pages.profile.general_info')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Divider />
        <CardContent>
          <Controller
            name="id"
            control={control}
            defaultValue={user.id}
            render={({ field }) => <input type="hidden" {...field} />}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controller
                name="first_name"
                control={control}
                defaultValue={user.first_name}
                render={({ field }) => (
                  <TextField
                    required
                    id="first_name"
                    label={t('user.first_name')}
                    name="first_name"
                    error={updateUserError?.message?.first_name?.length > 0}
                    helperText={
                      updateUserError?.message?.first_name &&
                      updateUserError?.message?.first_name[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name="middle_name"
                control={control}
                defaultValue={user.middle_name || ''}
                render={({ field }) => (
                  <TextField
                    id="middle_name"
                    label={t('user.middle_name')}
                    name="middle_name"
                    error={updateUserError?.message?.middle_name?.length > 0}
                    helperText={
                      updateUserError?.message?.middle_name &&
                      updateUserError?.message?.middle_name[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="last_name"
                control={control}
                defaultValue={user.last_name}
                render={({ field }) => (
                  <TextField
                    required
                    id="last_name"
                    label={t('user.last_name')}
                    name="last_name"
                    error={updateUserError?.message?.last_name?.length > 0}
                    helperText={
                      updateUserError?.message?.last_name &&
                      updateUserError?.message?.last_name[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="birth_date"
                control={control}
                defaultValue={user.birth_date || new Date()}
                render={({ field }) => (
                  <DatePicker
                    mask="__.__.____"
                    label={t('user.birth_date')}
                    renderInput={params => <TextField {...params} />}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="marital_status"
                control={control}
                defaultValue={user.marital_status}
                render={({ field }) => (
                  <Select
                    label={t('user.marital_status')}
                    variant="filled"
                    margin="normal"
                    name="marital_status"
                    id="marital_status"
                    fullWidth
                    {...field}
                  >
                    {filterableFields.marital_status.map(maritalStatus => (
                      <MenuItem key={maritalStatus} value={maritalStatus}>
                        {t(`user.marital_status_items.${maritalStatus}`)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          </Grid>

          {currentUser.role === 'admin' && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="role"
                  control={control}
                  defaultValue={user.role}
                  render={({ field }) => (
                    <Select
                      label={t('user.role')}
                      variant="filled"
                      margin="normal"
                      name="role"
                      id="role"
                      fullWidth
                      {...field}
                    >
                      {filterableFields.role.map(role => (
                        <MenuItem key={role} value={role}>
                          {t(`user.role_items.${role}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  label={t('user.approved')}
                  control={
                    <Controller
                      control={control}
                      defaultValue={user.approved}
                      name="approved"
                      render={({ field: { value, onChange, ref } }) => (
                        <Switch
                          color="primary"
                          inputRef={ref}
                          checked={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  }
                />
              </Grid>
            </Grid>
          )}

          <Controller
            name="avs"
            control={control}
            defaultValue={user.avs}
            render={({ field }) => (
              <TextField
                required
                id="avs"
                label={t('user.avs')}
                name="avs"
                error={updateUserError?.message?.avs?.length > 0}
                helperText={
                  updateUserError?.message?.avs &&
                  updateUserError?.message?.avs[0]
                }
                {...field}
              />
            )}
          />

          <Controller
            name="origin"
            control={control}
            defaultValue={user.origin}
            render={({ field }) => (
              <TextField
                required
                id="origin"
                label={t('user.origin')}
                name="origin"
                error={updateUserError?.message?.origin?.length > 0}
                helperText={
                  updateUserError?.message?.origin &&
                  updateUserError?.message?.origin[0]
                }
                {...field}
              />
            )}
          />

          <Controller
            name="iban"
            control={control}
            defaultValue={user.iban}
            render={({ field }) => (
              <TextField
                required
                id="iban"
                label={t('user.iban')}
                name="iban"
                error={updateUserError?.message?.iban?.length > 0}
                helperText={
                  updateUserError?.message?.iban &&
                  updateUserError?.message?.iban[0]
                }
                {...field}
              />
            )}
          />

          <Controller
            name="address.id"
            control={control}
            defaultValue={user.address?.id || ''}
            render={({ field }) => <input type="hidden" {...field} />}
          />

          <Controller
            name="address.street"
            control={control}
            defaultValue={user.address?.street}
            render={({ field }) => (
              <TextField
                required
                id="[address]street"
                label={t('address.street')}
                name="street"
                error={updateUserError?.message?.address?.length > 0}
                helperText={
                  updateUserError?.message?.address &&
                  updateUserError?.message?.address[0]
                }
                {...field}
              />
            )}
          />

          <Controller
            name="address.zip_code"
            control={control}
            defaultValue={user.address?.zip_code}
            render={({ field }) => (
              <TextField
                required
                id="zip_code"
                label={t('address.zip_code')}
                name="zip_code"
                error={updateUserError?.message?.zip_code?.length > 0}
                helperText={
                  updateUserError?.message?.zip_code &&
                  updateUserError?.message?.zip_code[0]
                }
                {...field}
              />
            )}
          />

          <Controller
            name="address.city"
            control={control}
            defaultValue={user.address?.city}
            render={({ field }) => (
              <TextField
                required
                id="city"
                label={t('address.city')}
                name="city"
                error={updateUserError?.message?.city?.length > 0}
                helperText={
                  updateUserError?.message?.city &&
                  updateUserError?.message?.city[0]
                }
                {...field}
              />
            )}
          />

          <Controller
            name="address.country"
            control={control}
            defaultValue={user.address?.country}
            render={({ field }) => (
              <Select
                labelId="country-label"
                label={t('address.country')}
                name="country"
                id="country"
                variant="filled"
                margin="normal"
                fullWidth
                {...field}
              >
                {Config.COUNTRIES.map(countryCode => (
                  <MenuItem key={countryCode} value={countryCode}>
                    {t(`address.countries.${countryCode}`)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          <FileUploader
            files={documents}
            setFiles={setDocuments}
            filesToRemove={documentsToRemove}
            setFilesToRemove={setDocumentsToRemove}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={classes.submitAction}>
            <Button
              onClick={() => dispatch(ToggleVisibility.action())}
              variant="contained"
              size="large"
              color="secondary"
              disabled={updateUserLoading}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={updateUserLoading}
            >
              {t('actions.save')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default EditUsers
