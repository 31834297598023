/**
 * The internal imports
 */
import lightPalette from '../Palettes/Light'
import darkPalette from '../Palettes/Dark'
import { store } from '../../Store'

const darkMode = store.getState().theme.darkMode
const palette = darkMode ? darkPalette : lightPalette

export default {
  base: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: palette.secondary.light,
    borderStyle: 'dashed',
    backgroundColor: palette.grey[200],
    color: palette.secondary.light,
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  active: {
    backgroundColor: palette.primary.main,
  },
  accept: {
    backgroundColor: palette.success.main,
  },
  reject: {
    backgroundColor: palette.error.main,
  },
}
