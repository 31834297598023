/**
 * The external imports
 */
import React from 'react'
import { Select, InputLabel, FormControl, FormHelperText } from '@mui/material'

/**
 * The internal imports
 */
import useStyles from '../../Theme/Components/Input/Select'

export default props => {
  const classes = useStyles()
  const { id, required, label, labelId, name, children, error, helperText } =
    props

  return (
    <FormControl fullWidth error={error} variant="filled">
      <InputLabel classes={{ root: classes.inputLabel }} id={labelId} required>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        name={name}
        id={id}
        fullWidth
        required={required}
        {...props}
      >
        {children}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
