import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  lineButton: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
  },
}))
export default useStyles
