/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateKindOfCost from '../../Store/KindOfCost/Create'
import UpdateKindOfCost from '../../Store/KindOfCost/Update'
import { Loader, TextField } from '../../Components/index'
import FetchOneKindOfCost from '../../Store/KindOfCost/FetchOne'
import useForms from '../../Theme/Components/Forms'

const KindOfCostForm = ({ id }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [kindOfCost, setKindOfCost] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(async () => {
    if (id) {
      const kindOfCostResponse = await dispatch(
        FetchOneKindOfCost.action({ url: `kind_of_costs/${id}` }),
      )
      if (isFulfilled(kindOfCostResponse)) {
        setKindOfCost(kindOfCostResponse.payload)
      }
    } else {
      setKindOfCost({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new kind of cost form to the db
   */
  const onSubmit = async data => {
    if (id) {
      const updateKindOfCostResponse = await dispatch(
        UpdateKindOfCost.action({
          url: `kind_of_costs/${id}`,
          params: { kind_of_cost: data },
        }),
      )
      if (isFulfilled(updateKindOfCostResponse)) {
        if (updateKindOfCostResponse.payload.success) {
          enqueueSnackbar(t('notifications.kind_of_cost_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateKindOfCostResponse.payload.errors)
        }
      }
    } else {
      const newKindOfCostResponse = await dispatch(
        CreateKindOfCost.action({
          url: 'kind_of_costs',
          params: { kind_of_cost: data },
        }),
      )
      if (isFulfilled(newKindOfCostResponse)) {
        if (newKindOfCostResponse.payload.success) {
          enqueueSnackbar(t('notifications.kind_of_cost_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newKindOfCostResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.kind_of_cost_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="name"
            control={control}
            defaultValue={kindOfCost.name || ''}
            render={({ field }) => (
              <TextField
                required
                id="name"
                label={t('kind_of_cost.name')}
                name="name"
                error={errors.name?.length > 0}
                helperText={errors.name && errors.name[0]}
                {...field}
              />
            )}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default KindOfCostForm
