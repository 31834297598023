/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import DatePicker from '@mui/lab/DatePicker'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  MenuItem,
  Grid,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateWork from '../../Store/Work/Create'
import GetFilterableFieldsService from '../../Services/GetFilterableFields'
import { Loader, TextField, Select } from '../index'
import FetchOneWork from '../../Store/Work/FetchOne'
import UpdateWork from '../../Store/Work/Update'
import useForms from '../../Theme/Components/Forms'
import FetchAllService from '../../Services/FetchAll'

const WorkForm = ({ id }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [errors, setErrors] = useState({})
  const [work, setWork] = useState({})
  const [filterableFields, setFilterableFields] = useState({})

  const client = useSelector(state => state.client.fetchOne.item)

  useEffect(async () => {
    const productsResponse = await FetchAllService({
      url: `clients/${client.id}/products`,
    })
    setProducts(productsResponse)
    const filterableFieldsResponse = await GetFilterableFieldsService(
      `clients/${client.id}/works`,
    )
    setFilterableFields(filterableFieldsResponse)

    if (id) {
      const workResponse = await dispatch(
        FetchOneWork.action({
          url: `clients/${client.id}/works/${id}`,
        }),
      )
      if (isFulfilled(workResponse)) {
        const data = workResponse.payload
        setWork(data)
      }
    } else {
      setWork({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new refund_request form to the db
   */
  const onSubmit = async data => {
    if (id) {
      const updateWorkResponse = await dispatch(
        UpdateWork.action({
          url: `clients/${client.id}/works/${id}`,
          params: { work: data },
        }),
      )
      if (isFulfilled(updateWorkResponse)) {
        if (updateWorkResponse.payload.success) {
          enqueueSnackbar(t('notifications.maintenance_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateWorkResponse.payload.errors)
        }
      }
    } else {
      const newWorkResponse = await dispatch(
        CreateWork.action({
          url: `clients/${client.id}/works`,
          params: { work: data },
        }),
      )
      if (isFulfilled(newWorkResponse)) {
        if (newWorkResponse.payload.success) {
          enqueueSnackbar(t('notifications.client_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newWorkResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.work_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="name"
            control={control}
            defaultValue={work.name || ''}
            render={({ field }) => (
              <TextField
                required
                id="name"
                label={t('work.name')}
                name="name"
                error={errors.name?.length > 0}
                helperText={errors.name && errors.name[0]}
                {...field}
              />
            )}
          />

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controller
                name="start_date"
                control={control}
                defaultValue={work.start_date || new Date()}
                render={({ field }) => (
                  <DatePicker
                    mask="__.__.____"
                    label={t('work.start_date')}
                    renderInput={params => <TextField {...params} />}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="state"
                control={control}
                defaultValue={work.state || 'ongoing'}
                render={({ field }) => (
                  <Select
                    label={t('work.state')}
                    error={errors.state?.length > 0}
                    helperText={errors.state && errors.state[0]}
                    {...field}
                  >
                    {filterableFields.state.map(state => (
                      <MenuItem key={`work-${state}`} value={state}>
                        {t(`work.state_items.${state}`)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          </Grid>
          <Controller
            name="description"
            control={control}
            defaultValue={work.description || ''}
            render={({ field }) => (
              <TextField
                margin="normal"
                fullWidth
                id="description"
                label={t('work.description')}
                name="description"
                multiline
                rows={4}
                error={errors.description?.length > 0}
                helperText={errors.description && errors.description[0]}
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="product"
            defaultValue={work.product?.name || ''}
            render={props => (
              <Autocomplete
                freeSolo
                value={props.field.value || ''}
                options={products.map(product => product.name)}
                onChange={(_, data) => props.field.onChange(data)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('work.product')}
                    margin="normal"
                    variant="filled"
                    onChange={event => props.field.onChange(event.target.value)}
                    error={errors.product_id?.length > 0}
                    helperText={errors.product_id && errors.product_id[0]}
                  />
                )}
              />
            )}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default WorkForm
