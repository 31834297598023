/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import { Datatable } from '../index'
import GetAllRefundRequests from '../../Store/RefundRequest/GetAll'
import UpdateRefundRequest from '../../Store/RefundRequest/Update'

const PendingRefundRequest = ({ handleEditClick }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { data, total } = useSelector(state => state.refundRequest.getAll.item)
  const refundRequestsError = useSelector(
    state => state.refundRequest.getAll.error,
  )
  const [excludedColumns] = useState(['id'])

  const createRefundRequestLoading = useSelector(
    state => state.refundRequest.create.loading,
  )
  const updateRefundRequestLoading = useSelector(
    state => state.refundRequest.update.loading,
  )

  const [tableState, setTableState] = useState({
    filter: { status: [0] },
  })

  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: ({ id }) => handleEditClick({ id }),
    },
    {
      label: t('datatables.accept'),
      columns: ['id'],
      action: params => updateRefundRequestStatus(params, 'accept'),
    },
    {
      label: t('datatables.refuse'),
      columns: ['id'],
      action: params => updateRefundRequestStatus(params, 'refuse'),
    },
  ])

  /**
   * Updates the refund_request status to accepted or refused
   * @param params: {id}
   * @param newStatus
   */
  const updateRefundRequestStatus = ({ id }, newStatus) => {
    const refundRequestData = {
      status: newStatus === 'accept' ? 1 : 2,
    }
    dispatch(
      UpdateRefundRequest.action({
        refundRequestId: id,
        params: refundRequestData,
      }),
    )
  }

  useEffect(async () => {
    if (!createRefundRequestLoading && !updateRefundRequestLoading) {
      await dispatch(
        GetAllRefundRequests.action({
          params: { tableState, isAdmin: true },
          url: 'refund_requests',
        }),
      )
    }
  }, [tableState, createRefundRequestLoading, updateRefundRequestLoading])

  return (
    <Datatable
      source="refund_requests"
      data={data}
      total={total}
      tableState={tableState}
      setTableState={setTableState}
      error={refundRequestsError}
      menuOptions={menuOptions}
      excludedColumns={excludedColumns}
      elevation={1}
    />
  )
}

export default PendingRefundRequest
