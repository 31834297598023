import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  subHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  submitAction: {
    margin: theme.spacing(1),
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  mt: {
    marginTop: theme.spacing(4),
  },
  fieldMargin: {
    margin: theme.spacing(2, 0),
  },
}))
export default useStyles
