/**
 * The external imports
 */
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

/**
 * The internal imports
 */
import Admin from './Admin'
import Auth from './Auth'

export const withAdminLayout =
  (Component, restricted = true) =>
  props => {
    const currentUser = useSelector(state => state.user.item)

    if (!currentUser.id) {
      return (
        <Redirect
          to={{
            pathname: '/auth/sign-in',
            state: { from: props.location },
          }}
        />
      )
    }

    if (!restricted) {
      return (
        <Admin>
          <Component {...props} />
        </Admin>
      )
    }

    if (currentUser.approved && restricted && currentUser.role === 'admin') {
      return (
        <Admin>
          <Component {...props} />
        </Admin>
      )
    } else {
      return (
        <Redirect
          to={{
            pathname: '/not-authorized',
            state: { from: props.location },
          }}
        />
      )
    }
  }

export const withAuthLayout = Component => props =>
  (
    <Auth>
      <Component {...props} />
    </Auth>
  )
