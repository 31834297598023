/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Box,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Typography, Card, CardContent, CardHeader } from '@mui/material'
import clsx from 'clsx'
import { isFulfilled } from '@reduxjs/toolkit'
import { MoreVert, Download } from '@mui/icons-material'
import { saveAs } from 'file-saver'

/**
 * The internal imports
 */
import { Loader, Link, Empty } from '../../Components'
import FetchOneReceivableBill from '../../Store/ReceivableBill/FetchOne'
import DownloadReceivableBill from '../../Store/ReceivableBill/Download'
import FetchOneClient from '../../Store/Client/FetchOne'
import useLayout from '../../Theme/Layouts'
import { openModal } from '../../Utils/Modal'
import useStyles from '../../Theme/Pages/PrepaidHours/Show.style'
import { formatDate } from '../../Utils/Date'

const ReceivableBill = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id } = useParams()
  const layout = useLayout()
  const classes = useStyles()

  const receivableBill = useSelector(
    state => state.receivableBill.fetchOne.item,
  )
  const client = useSelector(state => state.client.fetchOne.item)
  const downloadLoading = useSelector(
    state => state.receivableBill.download.loading,
  )

  const invoiceLineLoading = useSelector(
    state => state.invoiceLine.destroy.loading,
  )

  const [loading, setLoading] = useState(true)
  const [anchor, setAnchor] = useState({ element: null, id: null })

  useEffect(async () => {
    const receivableBillResponse = await dispatch(
      FetchOneReceivableBill.action({
        url: `receivable_bills/${id}`,
      }),
    )

    if (isFulfilled(receivableBillResponse)) {
      dispatch(
        FetchOneClient.action({
          url: `clients/${receivableBillResponse.payload.product.client_id}`,
        }),
      )
    }

    setLoading(false)
  }, [invoiceLineLoading])

  /**
   * Builds the params required during the click action and executes the action
   * @param option
   */
  const handleClick = (type, anchorId, options) => {
    openModal({
      type,
      id: anchorId,
      options,
    })
    closeMenu()
  }

  /**
   * Closes the menu
   */
  const closeMenu = () => {
    setAnchor({ element: null, id: null })
  }

  /**
   * Download generated bill
   */
  const downloadBill = async () => {
    const receivableBillResponse = await dispatch(
      DownloadReceivableBill.action({
        params: {},
        url: `receivable_bills/${id}/download`,
      }),
    )

    if (isFulfilled(receivableBillResponse)) {
      const blob = new Blob([receivableBillResponse.payload], {
        type: 'application/octet-stream',
      })
      saveAs(blob, `${receivableBill.reference}.pdf`)
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Container maxWidth="xl">
      <div className={clsx(classes.titleWrapper, layout.breadcrumb)}>
        <Link
          to={`/clients/${client.id}`}
          variant="body2"
          className={clsx(layout.breadcrumb, layout.breadcrumbLink)}
        >
          {client.name}
        </Link>
        <div className={layout.breadcrumbSeparator} />
        {t('components.receivable_bill.show.invoice')}
        <div className={layout.breadcrumbSeparator} />#
        {receivableBill.reference}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h6" component="span">
                  #{receivableBill.reference}
                </Typography>
              }
            />
            <CardContent>
              <div>
                <Typography color="primary">
                  {t('receivable_bill.sending_date')}
                </Typography>
                {formatDate(new Date(receivableBill.sending_date))}
              </div>
              <div>
                <Typography color="primary">
                  {t('receivable_bill.payment_date')}
                </Typography>
                {receivableBill.payment_date ? (
                  formatDate(new Date(receivableBill.payment_date))
                ) : (
                  <i>{t('components.receivable_bill.show.not_payed')}</i>
                )}
              </div>
              <div>
                <Typography color="primary">
                  {t('receivable_bill.amount')}
                </Typography>
                CHF {receivableBill.amount}
              </div>
              <div>
                <Typography variant="h6" color="primary">
                  {t('receivable_bill.ttc_amount')}
                </Typography>
                <Typography variant="h6">
                  CHF {receivableBill.amount_ttc}
                </Typography>
              </div>
            </CardContent>
          </Card>

          <Box mt={3}>
            <Button
              onClick={() =>
                openModal({
                  type: 'formReceivableBill',
                  id,
                  options: { clientId: client.id },
                })
              }
              variant="contained"
              size="large"
              color="primary"
              className={classes.fullWidth}
            >
              {t('actions.update')}
            </Button>
          </Box>

          <Box mt={3}>
            <Button
              onClick={downloadBill}
              variant="contained"
              size="large"
              color="primary"
              className={classes.fullWidth}
              disabled={
                downloadLoading || receivableBill.invoice_lines?.length < 1
              }
            >
              <Download />
              {t('actions.download')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={9}>
          {receivableBill.invoice_lines.length > 0 ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t('datatables.tables.invoice_lines.product_name')}
                    </TableCell>
                    <TableCell>
                      {t('datatables.tables.invoice_lines.wording')}
                    </TableCell>
                    <TableCell>
                      {t('datatables.tables.invoice_lines.amount')}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {receivableBill.invoice_lines.map(invoiceLine => (
                    <TableRow key={invoiceLine.id} hover>
                      <TableCell>{invoiceLine.product_name}</TableCell>
                      <TableCell>{invoiceLine.wording}</TableCell>
                      <TableCell>CHF {invoiceLine.amount}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={event =>
                            setAnchor({
                              element: event.currentTarget,
                              id: invoiceLine.id,
                            })
                          }
                          size="large"
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          id={`invoice_line-menu-${invoiceLine.id}`}
                          anchorEl={anchor.element}
                          open={anchor.id === invoiceLine.id}
                          onClose={closeMenu}
                        >
                          <MenuItem
                            key={`invoice-line-edit-${anchor.id}`}
                            onClick={() =>
                              handleClick('formInvoiceLine', anchor.id, {
                                clientId: client.id,
                              })
                            }
                          >
                            {t('datatables.edit')}
                          </MenuItem>

                          <MenuItem
                            key={`invoice-line_${anchor.id}`}
                            onClick={() =>
                              handleClick('destroyInvoiceLine', anchor.id, {})
                            }
                          >
                            {t('datatables.delete')}
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Empty />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ReceivableBill
