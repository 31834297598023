import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    '& .MuiTableHead-root th': {
      backgroundColor: theme.palette.grey[500],
      '& span': {
        color: theme.palette.grey[300],
      },
    },
    '& .MuiTableCell-root:last-child': {
      textAlign: 'end',
    },
    '& .MuiChip-root': {
      display: 'none',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  maintenancesWrapper: {
    '& .MuiTableHead-root th': {
      backgroundColor: theme.palette.grey[500],
      '& span': {
        color: theme.palette.grey[300],
      },
    },
    '& .MuiTableCell-root:nth-child(-n + 2)': {
      textAlign: 'start',
    },
    '& .MuiTableCell-root': {
      textAlign: 'center',
    },
  },
  dateBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  header: {
    textAlign: 'center',
  },
  today: {
    paddingBottom: 5,
    borderBottomStyle: 'solid',
    borderBottomWidth: 5,
    borderBottomColor: theme.palette.primary.main,
  },
  planned: { fill: theme.palette.success.main },
  missed: {
    opacity: 1,
    backgroundColor: theme.palette.error.main,
  },
  next: {
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  available: {
    opacity: 0.2,
  },
  applyFiltersButton: {
    marginTop: theme.spacing(2),
  },
  box: {
    marginRight: 10,
  },
  keyWrapper: { display: 'flex', marginTop: 30 },
  key: { display: 'flex', marginRight: 40, alignItems: 'center' },
  late_maintenance: {
    paddingBottom: 5,
    borderBottomColor: theme.palette.error.main,
    borderBottomWidth: 5,
    borderBottomStyle: 'solid',
    lineHeight: 2,
  },
  boolean: {
    height: 18,
    width: 18,
    backgroundColor: theme.palette.error.main,
    borderRadius: 18,
  },
  booleanTrue: {
    backgroundColor: theme.palette.success.main,
  },
  client_name: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  project_name: {
    fontStyle: 'italic',
  },
}))
export default useStyles
