/**
 * The internal imports
 */
import api from './index'
import AddUser from '../Store/User/Add'
import AddUnmaskedUser from '../Store/User/AddUnmasked'
import { store } from '../Store'

export default async ({ url }) => {
  const response = await api.get(url)

  if (response.status === 200) {
    localStorage.setItem('access_token', response.headers['access-token'])
    localStorage.setItem('client', response.headers.client)
    localStorage.setItem('expiry', response.headers.expiry)
    localStorage.setItem('uid', response.headers.uid)

    await store.dispatch(AddUnmaskedUser.action({}))
    await store.dispatch(AddUser.action(response.data.data))
  }

  return response.data
}
