/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import { Datatable } from '../index'
import UpdateOffDay from '../../Store/OffDay/Update'
import GetAllOffDays from '../../Store/OffDay/GetAll'

const PendingOffDays = ({ handleEditClick }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [excludedColumns] = useState(['id'])

  const { data, total } = useSelector(state => state.offDay.getAll.item)
  const getAllOffDaysError = useSelector(state => state.offDay.getAll.error)

  const createOffDayLoading = useSelector(state => state.offDay.create.loading)
  const updateOffDayLoading = useSelector(state => state.offDay.update.loading)

  const [tableState, setTableState] = useState({
    filter: { status: [0] },
  })

  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: params => handleEditClick({ id: params.id }),
    },
    {
      label: t('datatables.accept'),
      columns: ['id'],
      action: params => updateOffDayStatus(params, 'accept'),
    },
    {
      label: t('datatables.refuse'),
      columns: ['id'],
      action: params => updateOffDayStatus(params, 'refuse'),
    },
  ])

  /**
   * Updates the offDay status to accepted or refused
   * @param offDayId
   * @param newStatus
   */
  const updateOffDayStatus = ({ id }, newStatus) => {
    const offDayData = {
      status: newStatus === 'accept' ? 1 : 2,
    }
    dispatch(UpdateOffDay.action({ offDayId: id, params: offDayData }))
  }

  useEffect(async () => {
    if (!createOffDayLoading && !updateOffDayLoading) {
      await dispatch(
        GetAllOffDays.action({
          params: { tableState, isAdmin: true },
          url: 'off_days',
        }),
      )
    }
  }, [tableState, createOffDayLoading, updateOffDayLoading])

  return (
    <Datatable
      source="off_days"
      data={data}
      total={total}
      tableState={tableState}
      setTableState={setTableState}
      error={getAllOffDaysError}
      menuOptions={menuOptions}
      excludedColumns={excludedColumns}
      elevation={1}
    />
  )
}

export default PendingOffDays
