import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  yearWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  todayButtonWrapper: { display: 'flex', justifyContent: 'center' },
  todayButton: { color: theme.palette.primary.main },
  monthsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: 220,
    padding: 5,
  },
}))
export default useStyles
