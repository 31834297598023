export default {
  light: '#F6F5F0',
  white: '#FFF',
  action: {
    selected: '#EAB83E',
  },
  success: {
    main: '#00E676',
  },
  publicHoliday: {
    main: '#ADD8E650',
  },
  weekend: {
    main: '#3D4A58',
  },
  holiday: {
    main: '#FFD07B70',
  },
  army: {
    main: '#78866b50',
  },
  wagon: {
    main: '#E60F0550',
  },
  additional_hour: {
    main: '#00800050',
  },
  sickness: {
    main: '#A52A2A50',
  },
  other: {
    main: '#80008050',
  },
  default: {
    main: 'transparent',
  },
  pendingAbsence: {
    main: '#5D5D5D',
  },
}
