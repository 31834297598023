/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'

import { Button, Typography, Box, Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'

/**
 * The internal imports
 */
import AcceptInvitationAuth from '../../Store/Auth/AcceptInvitation'
import ClearAuth from '../../Store/Auth/Clear'
import useStyles from '../../Theme/Pages/Auth/SignIn'
import { Link, AuthTextInput } from '../../Components'

const InvitationAccepted = () => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const params = new URLSearchParams(useLocation().search)
  const [redirected, setRedirected] = useState(false)

  // Get values from the store
  const acceptInvitationError = useSelector(
    state => state.auth.acceptInvitation.error,
  )

  const acceptInvitationLoading = useSelector(
    state => state.auth.acceptInvitation.loading,
  )

  useEffect(() => {
    if (acceptInvitationError?.message) {
      if (acceptInvitationError.message.full_messages) {
        enqueueSnackbar(acceptInvitationError.message.full_messages[0], {
          variant: 'error',
        })
      } else {
        enqueueSnackbar(acceptInvitationError.message, { variant: 'error' })
      }
    }
  }, [acceptInvitationError])

  const onSubmit = async ({ password, passwordConfirmation }) => {
    const acceptInvitationResult = await dispatch(
      AcceptInvitationAuth.action({
        password,
        passwordConfirmation,
        invitationToken: params.get('invitation_token'),
      }),
    )

    if (isFulfilled(acceptInvitationResult)) {
      setRedirected(true)
      enqueueSnackbar(t('notifications.updated'), { variant: 'success' })
      setTimeout(async () => {
        await dispatch(ClearAuth.action())
        history.push('/auth/sign-in')
      }, 5000)
    }
  }

  return (
    <div>
      <Typography component="h1" variant="h5" align="center">
        <Box mb={4}>{t('pages.auth.invitation_accepted.title')}</Box>
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <AuthTextInput
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder={t('user.password')}
              type="password"
              id="password"
              autoComplete="current-password"
              error={acceptInvitationError?.data?.password?.length > 0}
              helperText={
                acceptInvitationError?.data?.password &&
                acceptInvitationError?.data?.password[0]
              }
              {...field}
            />
          )}
        />

        <Controller
          name="passwordConfirmation"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <AuthTextInput
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="passwordConfirmation"
              placeholder={t('user.password_confirmation')}
              type="password"
              id="passwordConfirmation"
              error={
                acceptInvitationError?.data?.password_confirmation?.length > 0
              }
              helperText={
                acceptInvitationError?.data?.password_confirmation &&
                acceptInvitationError?.data?.password_confirmation[0]
              }
              {...field}
            />
          )}
        />

        {redirected && (
          <Typography component="div" variant="body1">
            <Box mt={2} display="flex" justifyContent="center">
              {t('pages.auth.redirection')}
            </Box>
          </Typography>
        )}

        <Button
          type="submit"
          fullWidth
          variant="outlined"
          size="large"
          disabled={acceptInvitationLoading || redirected}
          className={classes.submit}
        >
          {t('actions.send')}
        </Button>

        <Grid container>
          <Grid item xs>
            <Link to="sign-in" variant="body2">
              {t('pages.auth.sign_in_url')}
            </Link>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default InvitationAccepted
