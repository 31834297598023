/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateContact from '../../Store/Contact/Create'
import UpdateContact from '../../Store/Contact/Update'
import { Loader, TextField } from '../index'
import FetchOneContact from '../../Store/Contact/FetchOne'
import useForms from '../../Theme/Components/Forms'

const ContactForm = ({ id }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [contact, setContact] = useState({})
  const [errors, setErrors] = useState({})

  const client = useSelector(state => state.client.fetchOne.item)

  useEffect(async () => {
    if (id) {
      const contactResponse = await dispatch(
        FetchOneContact.action({
          url: `clients/${client.id}/contacts/${id}`,
        }),
      )
      if (isFulfilled(contactResponse)) {
        setContact(contactResponse.payload)
      }
    } else {
      setContact({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new contact form to the db
   */
  const onSubmit = async data => {
    if (id) {
      const updateContactResponse = await dispatch(
        UpdateContact.action({
          url: `clients/${client.id}/contacts/${id}`,
          params: { contact: data },
        }),
      )
      if (isFulfilled(updateContactResponse)) {
        if (updateContactResponse.payload.success) {
          enqueueSnackbar(t('notifications.contact_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateContactResponse.payload.errors)
        }
      }
    } else {
      const newContactResponse = await dispatch(
        CreateContact.action({
          url: `clients/${client.id}/contacts`,
          params: { contact: data },
        }),
      )
      if (isFulfilled(newContactResponse)) {
        if (newContactResponse.payload.success) {
          enqueueSnackbar(t('notifications.contact_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newContactResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.contact_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="first_name"
                control={control}
                defaultValue={contact.first_name || ''}
                render={({ field }) => (
                  <TextField
                    required
                    id="first_name"
                    label={t('contact.first_name')}
                    name="first_name"
                    error={errors.first_name?.length > 0}
                    helperText={errors.first_name && errors.first_name[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="last_name"
                control={control}
                defaultValue={contact.last_name || ''}
                render={({ field }) => (
                  <TextField
                    required
                    id="last_name"
                    label={t('contact.last_name')}
                    name="last_name"
                    error={errors.last_name?.length > 0}
                    helperText={errors.last_name && errors.last_name[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="email"
                control={control}
                defaultValue={contact.email || ''}
                render={({ field }) => (
                  <TextField
                    required
                    margin="normal"
                    fullWidth
                    id="email"
                    label={t('contact.email')}
                    name="email"
                    error={errors.email?.length > 0}
                    helperText={errors.email && errors.email[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="phone"
                control={control}
                defaultValue={contact.phone || ''}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    id="phone"
                    label={t('contact.phone')}
                    name="phone"
                    error={errors.phone?.length > 0}
                    helperText={errors.phone && errors.phone[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default ContactForm
