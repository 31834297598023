/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateTechnology from '../../Store/Technology/Create'
import UpdateTechnology from '../../Store/Technology/Update'
import { Loader, TextEditor, TextField } from '../../Components/index'
import FetchOneTechnology from '../../Store/Technology/FetchOne'
import useForms from '../../Theme/Components/Forms'

const TechnologyForm = ({ id }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [technology, setTechnology] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(async () => {
    if (id) {
      const technologyResponse = await dispatch(
        FetchOneTechnology.action({ url: `technologies/${id}` }),
      )
      if (isFulfilled(technologyResponse)) {
        setTechnology(technologyResponse.payload)
      }
    } else {
      setTechnology({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new refund_request form to the db
   */
  const onSubmit = async data => {
    if (id) {
      const updateTechnologyResponse = await dispatch(
        UpdateTechnology.action({
          url: `technologies/${id}`,
          params: { technology: data },
        }),
      )
      if (isFulfilled(updateTechnologyResponse)) {
        if (updateTechnologyResponse.payload.success) {
          enqueueSnackbar(t('notifications.technology_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateTechnologyResponse.payload.errors)
        }
      }
    } else {
      const newTechnologyResponse = await dispatch(
        CreateTechnology.action({
          url: 'technologies',
          params: { technology: data },
        }),
      )
      if (isFulfilled(newTechnologyResponse)) {
        if (newTechnologyResponse.payload.success) {
          enqueueSnackbar(t('notifications.technology_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newTechnologyResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.technology_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="name"
            control={control}
            defaultValue={technology.name || ''}
            render={({ field }) => (
              <TextField
                required
                id="name"
                label={t('technology.name')}
                name="name"
                error={errors.name?.length > 0}
                helperText={errors.name && errors.name[0]}
                {...field}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            defaultValue={technology.description?.body || ''}
            render={({ field }) => (
              <TextEditor value={field.value} onChange={field.onChange} />
            )}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default TechnologyForm
