/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { Container, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Card, Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import clsx from 'clsx'

/**
 * The internal imports
 */
import FetchOneMaintenance from '../../Store/Maintenance/FetchOne'
import FetchOneClient from '../../Store/Client/FetchOne'
import useLayout from '../../Theme/Layouts'
import FetchAllService from '../../Services/FetchAll'
import { openModal } from '../../Utils/Modal'
import useStyles from '../../Theme/Pages/Maintenances/Show.style'
import {
  Loader,
  Link,
  ServiceTable,
  MaintenancePeriodCard,
} from '../../Components'

const Maintenance = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { clientId, maintenanceId } = useParams()
  const layout = useLayout()
  const classes = useStyles()

  const client = useSelector(state => state.client.fetchOne.item)
  const maintenance = useSelector(state => state.maintenance.fetchOne.item)
  const createServiceLoading = useSelector(
    state => state.service.create.loading,
  )
  const updateServiceLoading = useSelector(
    state => state.service.update.loading,
  )
  const createMaintenancePeriodLoading = useSelector(
    state => state.maintenancePeriod.create.loading,
  )
  const updateMaintenancePeriodLoading = useSelector(
    state => state.maintenancePeriod.update.loading,
  )
  const createInvoiceLineLoading = useSelector(
    state => state.invoiceLine.create.loading,
  )
  const destroyInvoiceLineLoading = useSelector(
    state => state.invoiceLine.destroy.loading,
  )

  const [loading, setLoading] = useState(true)
  const [services, setServices] = useState([])
  const [anchor, setAnchor] = useState({ element: null, id: null })
  const [maintenancePeriods, setMaintenancePeriods] = useState([])

  useEffect(async () => {
    if (clientId && maintenanceId) {
      if (!loading) {
        setLoading(true)
      }
      await dispatch(
        FetchOneMaintenance.action({
          url: `clients/${clientId}/maintenances/${maintenanceId}`,
        }),
      )
      await dispatch(FetchOneClient.action({ url: `clients/${clientId}` }))
      setLoading(false)
    }
  }, [clientId, maintenanceId])

  useEffect(async () => {
    if (clientId && maintenanceId) {
      if (!loading) {
        setLoading(true)
      }
      if (
        !createMaintenancePeriodLoading &&
        !updateMaintenancePeriodLoading &&
        !destroyInvoiceLineLoading &&
        !createInvoiceLineLoading
      ) {
        const maintenancePeriodsResponse = await FetchAllService({
          url: `clients/${clientId}/maintenances/${maintenanceId}/maintenance_periods`,
        })
        setMaintenancePeriods(maintenancePeriodsResponse)
        setLoading(false)
      }
    }
  }, [
    clientId,
    maintenanceId,
    createMaintenancePeriodLoading,
    updateMaintenancePeriodLoading,
    destroyInvoiceLineLoading,
    createInvoiceLineLoading,
  ])

  useEffect(async () => {
    if (!createServiceLoading && !updateServiceLoading) {
      const servicesResponse = await FetchAllService({
        url: `clients/${clientId}/maintenances/${maintenanceId}/services`,
      })
      setServices(servicesResponse)
    }
  }, [createServiceLoading, updateServiceLoading])

  /**
   * Opens the formService modal to add a new service
   */
  const handleAddService = () => {
    openModal({
      type: 'formService',
      options: { maintenanceId },
    })
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }
  return (
    <Container maxWidth="xl">
      <div className={clsx(classes.titleWrapper, layout.breadcrumb)}>
        <Link
          to={`/clients/${clientId}`}
          variant="body2"
          className={clsx(layout.breadcrumb, layout.breadcrumbLink)}
        >
          {client.name}
        </Link>
        <div className={layout.breadcrumbSeparator} />
        {maintenance.name}
      </div>

      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Card>
            <ServiceTable rows={services} />
            <Grid padding={1}>
              <Button startIcon={<Add />} onClick={handleAddService}>
                {t('actions.add_service')}
              </Button>
            </Grid>
          </Card>
          <Button
            onClick={() =>
              openModal({
                type: 'formMaintenancePeriod',
                options: { clientId, maintenanceId },
              })
            }
            variant="contained"
            color="primary"
            startIcon={<Add />}
            sx={{ mt: 2, width: 1 }}
          >
            {t('actions.add_period')}
          </Button>
        </Grid>
        <Grid item xs={9}>
          {maintenancePeriods.map(maintenancePeriod => (
            <MaintenancePeriodCard
              key={`maintenance_period-${maintenancePeriod.id}`}
              maintenancePeriod={maintenancePeriod}
              anchor={anchor}
              setAnchor={setAnchor}
            />
          ))}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Maintenance
