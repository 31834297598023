/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => {
  return {
    statsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    monthlyTurnoverWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    tooltipWrapper: {
      padding: 0,
    },
    contentWrapper: {
      fontSize: 14,
      fontWeight: 'bold',
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 10,
      paddingBottom: 10,
      lineHeight: 1.5,
      borderRadius: 4,
      border: '2px solid white',
    },
    statLabel: {
      textTransform: 'uppercase',
      fontSize: theme.typography.fontSize,
    },
    statValue: {
      fontWeight: 'bold',
      fontSize: theme.typography.h5.fontSize,
      marginRight: 20,
    },
    percentageWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    percentageValue: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    positive: {
      color: theme.palette.success.main,
    },
    negative: {
      color: theme.palette.error.main,
    },
  }
})

export default useStyles
