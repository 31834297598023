/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import clsx from 'clsx'
import { Add } from '@mui/icons-material'

/**
 * The internal imports
 */
import GetAllOffer from '../../Store/Offer/GetAll'
import { Datatable } from '../index'
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import SetParams from '../../Store/Modal/SetParams'
import useLayout from '../../Theme/Layouts'

const ClientOffers = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [excludedColumns] = useState(['id'])
  const layout = useLayout()

  // Get values from the store
  const client = useSelector(state => state.client.fetchOne.item)
  const { data, total } = useSelector(state => state.offer.getAll.item)

  const createOfferLoading = useSelector(state => state.offer.create.loading)
  const updateOfferLoading = useSelector(state => state.offer.update.loading)

  const [tableState, setTableState] = useState({})

  /**
   * Opens the offer form modal
   * @returns {Promise<void>}
   */
  const handleAddClick = async () => {
    await dispatch(SetParams.action({ type: 'formOffer' }))
    await dispatch(ToggleVisibility.action())
  }

  /**
   * Opens the offer form modal
   * @param id
   * @returns {Promise<void>}
   */
  const handleEditClick = async ({ id }) => {
    await dispatch(SetParams.action({ type: 'formOffer', id }))
    await dispatch(ToggleVisibility.action())
  }

  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: ({ id }) => handleEditClick({ id }),
    },
  ])

  useEffect(async () => {
    if (!createOfferLoading && !updateOfferLoading) {
      await dispatch(
        GetAllOffer.action({
          params: { tableState },
          url: `clients/${client.id}/offers`,
        }),
      )
    }
  }, [tableState, createOfferLoading, updateOfferLoading])

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={handleAddClick}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        source="offers"
        data={data}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
      />
    </div>
  )
}

export default ClientOffers
