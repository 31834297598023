/**
 * The external imports
 */
import TextField from '@mui/material/TextField'
import { withStyles } from '@mui/styles'

export default withStyles({
  root: {
    '&  .MuiFilledInput-underline:after': {
      border: 0,
    },
    '& .MuiInputBase-input': {
      padding: 20,
    },
  },
})(TextField)
