/**
 * The external imports
 */
import React from 'react'
import { Typography } from '@mui/material'

/**
 * The internal imports
 */
import useStyles from '../../Theme/Components/Address'

const AddressDisplay = ({ title, address }) => {
  const classes = useStyles()

  return (
    <div>
      <Typography color="primary" className={classes.title}>
        {title}
      </Typography>
      {address.attention_of ? (
        <>
          {address.attention_of} <br />
        </>
      ) : null}
      {address.street} <br />
      {address.additional ? (
        <>
          {address.additional} <br />
        </>
      ) : null}
      {`${address.zip_code}  ${address.city}`} <br />
      {`${address.country}`} <br />
    </div>
  )
}

export default AddressDisplay
