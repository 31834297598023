/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import GetStats from './GetStats'

export default buildSlice('stats', [GetStats], {
  item: {},
}).reducer
