import common from './Common'

export default {
  mode: 'dark',
  ...common,
  primary: {
    main: '#FDA92D',
    dark: '#B1761F',
    light: '#8E826F',
    contrastText: '#F5F5F5',
  },
  secondary: {
    main: '#161C24',
    light: '#5D5D5D',
    dark: '#252525',
    contrastText: '#FFF',
  },
  background: {
    paper: '#212B36',
    default: '#161C24',
  },
  error: {
    main: '#F44336',
    light: '#E57373',
    dark: '#D32F2F',
  },
  timeSheetText: '#FDA92D',
  grey: {
    200: '#EFEBDF',
    500: '#343D49',
    800: '#21262E',
  },
}
