/**
 * The external imports
 */
import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Typography,
} from '@mui/material'
import { Delete, Download } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'

/**
 * The internal imports
 */
import { fullDate } from '../../Utils/Date'
import { UploadLoader } from '../../Components'
import dropzoneStyles from '../../Theme/Components/Dropzone'
import UpdateUser from '../../Store/User/Update'
import FetchOneUser from '../../Store/User/FetchOne'

const audio = new Audio(
  'https://coop.s3.eu-central-1.amazonaws.com/uploadSound2.mp3',
)

const UserDocuments = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.fetchOne.item)

  const {
    getRootProps,
    acceptedFiles,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone()

  const style = useMemo(
    () => ({
      ...dropzoneStyles.base,
      ...(isDragActive ? dropzoneStyles.active : {}),
      ...(isDragAccept ? dropzoneStyles.accept : {}),
      ...(isDragReject ? dropzoneStyles.reject : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  useEffect(() => {
    if (isDragActive) {
      audio.play()
    } else {
      audio.pause()
    }
  }, [isDragActive])

  /**
   * Removes the file given
   * @param documentId
   */
  const removeFile = documentId => {
    update([], [documentId])
  }

  /**
   * Updates the attached files list when acceptedFiles changes
   */
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      update(acceptedFiles, [])
    }
  }, [acceptedFiles])

  /**
   * Update user's document
   * @param {Array} documents
   * @param {Array} documentsToRemove
   */
  const update = async (documents, documentsToRemove) => {
    const data = {
      ...user,
      documentsToRemove,
      documents,
    }
    await dispatch(UpdateUser.action({ data }))
    dispatch(FetchOneUser.action({ url: `users/${user.id}` }))
  }

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{t('components.file_uploader.drop_text')}</p>
      </div>
      {isDragActive ? (
        <UploadLoader />
      ) : user.documents_info.length === 0 ? (
        <Typography variant="h5" align="center">
          {t('empty')}
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {user.documents_info.map(document => (
                <TableRow key={document.id} hover>
                  <TableCell>{document.filename}</TableCell>
                  <TableCell align="center">{document.size} Mo</TableCell>
                  <TableCell align="right">
                    {fullDate(new Date(document.created_at))}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => window.open(document.url, '_blank')}
                      size="large"
                    >
                      <Download />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => removeFile(document.id)}
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default UserDocuments
