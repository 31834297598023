import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
  },
  header: {
    backgroundColor: theme.palette.grey[500],
    padding: theme.spacing(2),
    display: 'flex',
  },
  dates: {
    flexGrow: 1,
    alignSelf: 'center',
  },
  button: {
    color: theme.palette.timeSheetText,
  },
  buttonRight: {
    color: theme.palette.timeSheetText,
  },
  today: {
    backgroundColor: theme.palette.primary.light,
    borderLeft: `4px ${theme.palette.primary.main} solid`,
  },
  day: { marginTop: 0, marginBottom: 0 },
  positive: {
    color: theme.palette.success.main,
  },
  negative: {
    color: theme.palette.error.main,
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr 3fr 3fr 3fr 3fr 1fr',
    '& > div': {
      margin: theme.spacing(1.5),
      alignSelf: 'center',
    },
    '& input': {
      textAlign: 'center',
    },
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      display: 'none',
    },
  },
}))
export default useStyles
