/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  dateBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  table: {
    width: '100%',
    tableLayout: 'auto',
  },
  day: {
    padding: '15px 0',
    textAlign: 'center',
  },
  tooltipWrapper: { padding: 0 },
  column: { fontWeight: 'bold' },
  today: {
    textDecoration: 'underline',
    textDecorationThickness: 5,
    textDecorationColor: theme.palette.primary.main,
  },
  balancesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 30,
    marginTop: 5,
  },
  balanceInnerWrapper: { display: 'flex', alignItems: 'center' },
  balanceIcons: { marginRight: 10, fill: theme.palette.primary.main },
  employeeName: { fontWeight: 'bold' },
  lastTableRow: { borderBottom: '2px solid black' },
  totalRow: { fontWeight: 'bold' },
  box: {
    height: 20,
    width: 20,
    border: '1px solid black',
    clear: 'both',
    marginRight: 10,
  },
  todayBox: {
    width: 20,
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: 5,
    borderBottomStyle: 'solid',
    marginRight: 10,
  },
  keyWrapper: { display: 'flex', marginTop: 30 },
  key: { display: 'flex', marginRight: 40 },
  publicHoliday: { backgroundColor: theme.palette.publicHoliday.main },
  weekend: { backgroundColor: theme.palette.weekend.main },
  holiday: { backgroundColor: theme.palette.holiday.main },
  wagon: { backgroundColor: theme.palette.wagon.main },
  army: { backgroundColor: theme.palette.army.main },
  additional_hour: { backgroundColor: theme.palette.additional_hour.main },
  sickness: { backgroundColor: theme.palette.sickness.main },
  other: { backgroundColor: theme.palette.other.main },
  default: { backgroundColor: theme.palette.default.main },
  pending: {
    background: `repeating-linear-gradient(-45deg, transparent, transparent 5px, ${theme.palette.pendingAbsence.main} 5px, ${theme.palette.pendingAbsence.main} 10px)`,
  },
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}))

export default useStyles
