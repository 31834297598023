/**
 * The external imports
 */
import React, { useEffect } from 'react'
import { Button, Typography, Box, Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { isFulfilled } from '@reduxjs/toolkit'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import Tracker from '@openreplay/tracker/cjs'

/**
 * The internal imports
 */
import NewSessionAuth from '../../Store/Auth/NewSession'
import useStyles from '../../Theme/Pages/Auth/SignIn'
import { Link, AuthTextInput } from '../../Components'

const tracker = new Tracker({
  projectKey: 'eevr3pQRZ7eJdS68cC1R',
})

const SignIn = () => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const { control, handleSubmit } = useForm()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  // Get values from the store
  const newSessionError = useSelector(state => state.auth.newSession.error)
  const newSessionLoading = useSelector(state => state.auth.newSession.loading)

  useEffect(() => {
    newSessionError &&
      enqueueSnackbar(newSessionError.message, { variant: 'error' })
  }, [newSessionError])

  useEffect(() => {
    tracker.start()
  }, [])

  // Dispatches the user information to open a new session
  const onSubmit = async ({ email, password }) => {
    const newSessionResponse = await dispatch(
      NewSessionAuth.action({ email, password }),
    )

    if (isFulfilled(newSessionResponse)) {
      let { from } = location.state || { from: { pathname: '/' } }

      tracker.setUserID(email)
      history.replace(from)
    }
  }

  return (
    <div>
      <Typography component="h1" variant="h5" align="center">
        <Box mb={4}>{t('pages.auth.sign_in.title')}</Box>
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          // CAN'T USE NODE_ENV FOR SOME REASON
          // defaultValue={
          //   process.env.NODE_ENV === 'development'
          //     ? 'alain.fresco@wavemind.ch'
          //     : ''
          // }
          render={({ field }) => (
            <AuthTextInput
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder={t('user.email')}
              name="email"
              autoComplete="email"
              autoFocus
              {...field}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          // CAN'T USE NODE_ENV FOR SOME REASON
          // defaultValue={process.env.NODE_ENV === 'development' ? '123456' : ''}
          render={({ field }) => (
            <AuthTextInput
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder={t('user.password')}
              type="password"
              id="password"
              autoComplete="current-password"
              {...field}
            />
          )}
        />

        <Button
          type="submit"
          fullWidth
          variant="outlined"
          size="large"
          disabled={newSessionLoading}
          className={classes.submit}
        >
          {t('actions.login')}
        </Button>

        <Grid container>
          <Grid item xs>
            <Link to="forgot-password" variant="body2">
              {t('pages.auth.forgot_password_url')}
            </Link>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default SignIn
