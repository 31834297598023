/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import DatePicker from '@mui/lab/DatePicker'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Button,
  Box,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateSettings from '../../Store/Settings/Create'
import UpdateSettings from '../../Store/Settings/Update'
import { Loader, TextField } from '../index'
import FetchOneSettings from '../../Store/Settings/FetchOne'
import useForms from '../../Theme/Components/Forms'

const SettingsForm = ({ id }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [settings, setSettings] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(async () => {
    if (id) {
      const settingsResponse = await dispatch(
        FetchOneSettings.action({ url: `settings/${id}` }),
      )
      if (isFulfilled(settingsResponse)) {
        setSettings(settingsResponse.payload)
      }
    } else {
      setSettings({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new settings form to the db
   */
  const onSubmit = async data => {
    if (id) {
      const updateSettingsResponse = await dispatch(
        UpdateSettings.action({
          url: `settings/${id}`,
          params: { settings: data },
        }),
      )
      if (isFulfilled(updateSettingsResponse)) {
        if (updateSettingsResponse.payload.success) {
          enqueueSnackbar(t('notifications.settings_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateSettingsResponse.payload.errors)
        }
      }
    } else {
      const newSettingsResponse = await dispatch(
        CreateSettings.action({
          url: 'settings',
          params: { settings: data },
        }),
      )
      if (isFulfilled(newSettingsResponse)) {
        if (newSettingsResponse.payload.success) {
          enqueueSnackbar(t('notifications.settings_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newSettingsResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.settings_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="date"
            control={control}
            defaultValue={settings.date || new Date()}
            render={({ field }) => (
              <DatePicker
                mask="__.__.____"
                label={t('settings.date')}
                renderInput={params => <TextField {...params} />}
                {...field}
              />
            )}
          />
          <Divider />
          <div className={formClasses.subHeader}>
            <Typography component="h3" variant="h5">
              {t('components.settings_form.sub_title')}
            </Typography>
          </div>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={4}>
              <Controller
                name="daily_hours"
                control={control}
                defaultValue={settings.daily_hours || ''}
                render={({ field }) => (
                  <TextField
                    required
                    id="daily_hours"
                    label={t('settings.daily_hours')}
                    name="name"
                    error={errors.daily_hours?.length > 0}
                    helperText={errors.daily_hours && errors.daily_hours[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="vacation_days"
                control={control}
                defaultValue={settings.vacation_days || ''}
                render={({ field }) => (
                  <TextField
                    required
                    id="vacation_days"
                    label={t('settings.vacation_days')}
                    name="name"
                    error={errors.vacation_days?.length > 0}
                    helperText={errors.vacation_days && errors.vacation_days[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="payment_days"
                control={control}
                defaultValue={settings.payment_days || ''}
                render={({ field }) => (
                  <TextField
                    required
                    id="payment_days"
                    label={t('settings.payment_days')}
                    name="name"
                    error={errors.payment_days?.length > 0}
                    helperText={errors.payment_days && errors.payment_days[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={4}>
              <Controller
                name="avs_percent"
                control={control}
                defaultValue={settings.avs_percent || ''}
                render={({ field }) => (
                  <TextField
                    required
                    id="avs_percent"
                    label={t('settings.avs_percent')}
                    name="name"
                    error={errors.avs_percent?.length > 0}
                    helperText={errors.avs_percent && errors.avs_percent[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="ac_percent"
                control={control}
                defaultValue={settings.ac_percent || ''}
                render={({ field }) => (
                  <TextField
                    required
                    id="ac_percent"
                    label={t('settings.ac_percent')}
                    name="name"
                    error={errors.ac_percent?.length > 0}
                    helperText={errors.ac_percent && errors.ac_percent[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="pc_percent"
                control={control}
                defaultValue={settings.pc_percent || ''}
                render={({ field }) => (
                  <TextField
                    required
                    id="pc_percent"
                    label={t('settings.pc_percent')}
                    name="name"
                    error={errors.pc_percent?.length > 0}
                    helperText={errors.pc_percent && errors.pc_percent[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Controller
                name="aanp_percent"
                control={control}
                defaultValue={settings.aanp_percent || ''}
                render={({ field }) => (
                  <TextField
                    required
                    id="aanp_percent"
                    label={t('settings.aanp_percent')}
                    name="name"
                    error={errors.aanp_percent?.length > 0}
                    helperText={errors.aanp_percent && errors.aanp_percent[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="assmal_percent"
                control={control}
                defaultValue={settings.assmal_percent || ''}
                render={({ field }) => (
                  <TextField
                    required
                    id="assmal_percent"
                    label={t('settings.assmal_percent')}
                    name="name"
                    error={errors.assmal_percent?.length > 0}
                    helperText={
                      errors.assmal_percent && errors.assmal_percent[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default SettingsForm
