/**
 * The external imports
 */
import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

/**
 * The internal imports
 */
import useStyles from '../../Theme/Components/Input/TextEditor.style'

const TextEditor = ({ value, onChange }) => {
  const classes = useStyles()

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['code-block'],
      ['link'],
      ['clean'],
    ],
  }

  const formats = [
    'header',
    'code-block',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ]

  return (
    <div className={classes.wrapper}>
      <ReactQuill
        value={value}
        className={classes.editor}
        onChange={onChange}
        modules={modules}
        formats={formats}
      />
    </div>
  )
}

export default TextEditor
