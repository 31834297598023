/**
 * The external imports
 */
import React from 'react'
import { Folder, Description } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { singular } from 'pluralize'
import { spacing } from '@mui/system'
import SickIcon from '@mui/icons-material/Sick'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed'

/**
 * The internal imports
 */
import i18n from '../Translations'
import { TableColumns } from '../Config/TableColumns'
import lightPalette from '../Theme/Palettes/Light'
import { formatDate, monthAndYear } from './Date'
import { openModal } from './Modal'
import { DatePicker, BooleanBadge } from '../Components'
import { formatCurrency } from './Number'

/**
 * Opens the modal to show the attached files
 * @param source
 * @param id
 * @returns {Promise<void>}
 */
const openFilesModal = async (source, id) => {
  if (source === 'off_days') {
    openModal({ type: 'justificationsOffDays', id: id })
  } else if (source === 'refund_requests') {
    openModal({ type: 'justificationsRefundRequest', id: id })
  }
}

const badgeStyle = {
  backgroundColor: lightPalette.primary.main,
  marginRight: spacing(1),
  width: 10,
  height: 10,
  borderRadius: 10,
  display: 'inline-flex',
}

const iconEnumStyle = {
  display: 'flex',
}

const inlineFlex = {
  display: 'inline-flex',
  marginLeft: 5,
  alignSelf: 'center',
}

export default (source, filterableFields, excludedColumns, defaultFilter) => {
  return TableColumns[source].map(column => {
    const isFilterable = Object.keys(filterableFields).includes(column.label)
    return {
      name: column.label,
      label: i18n.t(`datatables.tables.${source}.${column.label}`),
      options: {
        filter: isFilterable,
        sort: column.sortable || false,
        display: excludedColumns.includes(column.label) ? 'excluded' : true,
        filterOptions: {
          names: isFilterable
            ? filterableFields[column.label].map(filter => {
                if (column.type === 'enum' || column.type === 'method') {
                  return i18n.t(
                    `${singular(source)}.${column.label}_items.${filter}`,
                  )
                } else {
                  return filter
                }
              })
            : null,
        },
        customBodyRender: (value, tableMeta) => {
          const rowId = tableMeta.rowData[0]
          const cellValue = tableMeta.rowData[tableMeta.columnIndex]
          try {
            switch (column.type) {
              case 'date':
                return cellValue ? formatDate(new Date(cellValue)) : null
              case 'date_month_year':
                return cellValue ? monthAndYear(new Date(cellValue)) : null
              case 'date_button':
                if (cellValue === null) {
                  return (
                    <DatePicker
                      source={source}
                      id={rowId}
                      label={column.label}
                    />
                  )
                } else {
                  return cellValue ? formatDate(new Date(cellValue)) : null
                }
              case 'method':
              case 'enum':
                switch (cellValue) {
                  case 'additional_hour':
                    return (
                      <div style={iconEnumStyle}>
                        <MoreTimeIcon
                          sx={{ color: lightPalette.success.main }}
                        />
                        <div style={inlineFlex}>
                          {i18n.t(
                            `${singular(source)}.${
                              column.label
                            }_items.${cellValue}`,
                          )}
                        </div>
                      </div>
                    )
                  case 'wagon':
                    return (
                      <div style={iconEnumStyle}>
                        <DirectionsBusIcon sx={{ color: '#fd1015' }} />
                        <div style={inlineFlex}>
                          {i18n.t(
                            `${singular(source)}.${
                              column.label
                            }_items.${cellValue}`,
                          )}
                        </div>
                      </div>
                    )
                  case 'holiday':
                    return (
                      <div style={iconEnumStyle}>
                        <BeachAccessIcon
                          sx={{ color: lightPalette.primary.main }}
                        />
                        <div style={inlineFlex}>
                          {i18n.t(
                            `${singular(source)}.${
                              column.label
                            }_items.${cellValue}`,
                          )}
                        </div>
                      </div>
                    )
                  case 'army':
                    return (
                      <div style={iconEnumStyle}>
                        <GpsNotFixedIcon />
                        <div style={inlineFlex}>
                          {i18n.t(
                            `${singular(source)}.${
                              column.label
                            }_items.${cellValue}`,
                          )}
                        </div>
                      </div>
                    )
                  case 'sickness':
                    return (
                      <div style={iconEnumStyle}>
                        <SickIcon sx={{ color: '#F6D0A9' }} />
                        <div style={inlineFlex}>
                          {i18n.t(
                            `${singular(source)}.${
                              column.label
                            }_items.${cellValue}`,
                          )}
                        </div>
                      </div>
                    )
                  case 'accepted':
                  case 'finish':
                  case 'sent':
                    return (
                      <div>
                        <div
                          style={{
                            ...badgeStyle,
                            backgroundColor: lightPalette.success.main,
                          }}
                        />
                        &nbsp;
                        <div style={inlineFlex}>
                          {i18n.t(
                            `${singular(source)}.${
                              column.label
                            }_items.${cellValue}`,
                          )}
                        </div>
                      </div>
                    )
                  case 'refused':
                    return (
                      <div>
                        <div
                          style={{
                            ...badgeStyle,
                            backgroundColor: lightPalette.error.main,
                          }}
                        />
                        &nbsp;
                        <div style={inlineFlex}>
                          {i18n.t(
                            `${singular(source)}.${
                              column.label
                            }_items.${cellValue}`,
                          )}
                        </div>
                      </div>
                    )
                  case 'pending':
                  case 'sent':
                  case 'postponed':
                  default:
                    return (
                      <div>
                        <div style={badgeStyle} />
                        &nbsp;
                        <div style={inlineFlex}>
                          {i18n.t(
                            `${singular(source)}.${
                              column.label
                            }_items.${cellValue}`,
                          )}
                        </div>
                      </div>
                    )
                  case 'admin':
                  case 'employee':
                    return (
                      <div style={inlineFlex}>
                        {i18n.t(
                          `${singular(source)}.${
                            column.label
                          }_items.${cellValue}`,
                        )}
                      </div>
                    )
                }
              case 'currency':
                return formatCurrency(cellValue)
              case 'percentage':
                return parseFloat(cellValue).toFixed(2)
              case 'file':
                const files = tableMeta.rowData[tableMeta.columnIndex]
                if (files.length > 0) {
                  if (files.length === 1) {
                    return (
                      <IconButton
                        onClick={() => window.open(files[0].url, '_blank')}
                        size="large"
                      >
                        <Description />
                      </IconButton>
                    )
                  } else {
                    return (
                      <IconButton
                        onClick={() => openFilesModal(source, rowId)}
                        size="large"
                      >
                        <Folder />
                      </IconButton>
                    )
                  }
                }
                return null
              case 'boolean':
                return <BooleanBadge value={value} />
              case 'string':
                return cellValue.length > 40
                  ? cellValue.substr(0, 40 - 1) + '...'
                  : cellValue
              default:
                return cellValue
            }
          } catch (error) {
            return 'N/A'
          }
        },
      },
    }
  })
}
