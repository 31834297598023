/**
 * The internal imports
 */
import api from '../index'

export default async params => {
  const dataPost = new FormData()

  // Don't ask why. Just don't
  // https://gph.is/g/aj7vKVn
  params.justifications.forEach(justification => {
    dataPost.append('refund_request[justifications][]', justification)
  })

  dataPost.append('refund_request[user_id]', params.user_id)
  dataPost.append('refund_request[amount]', params.amount)
  dataPost.append('refund_request[description]', params.description)
  dataPost.append(
    'refund_request[refund_request_date]',
    params.refund_request_date,
  )

  const response = await api.post('refund_requests', dataPost)
  return response.data
}
