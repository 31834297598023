import { createTheme } from '@mui/material/styles'

import dark from './Dark'
import light from './Light'

export default mode =>
  createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: '6px 0',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: '0.75rem',
          },
        },
      },
    },
    palette: {
      mode,
      ...(mode === 'light' ? light : dark),
    },
    typography: {
      fontFamily: ['Barlow'],
      fontSize: 16,
      h5: {
        fontWeight: 700,
      },
      button: {
        color: '#FFF',
        fontWeight: 'bold',
      },
    },
    shape: {
      borderRadius: 0,
    },
  })
