/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import GetAll from './GetAll'
import GetAllPlanning from './GetAllPlanning'
import GetAllClient from './GetAllClient'
import FetchOne from './FetchOne'
import Create from './Create'
import Update from './Update'

export default buildSlice(
  'maintenance',
  [GetAll, GetAllPlanning, GetAllClient, Create, Update, FetchOne],
  {
    getAll: { item: {} },
    GetAllClient: { item: {} },
    getAllPlanning: { item: {} },
    fetchOne: { item: {} },
    create: {},
    update: {},
  },
).reducer
