/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/**
 * The internal imports
 */
import { Datatable } from '../index'
import GetAllMaintenance from '../../Store/Maintenance/GetAll'

const LateMaintenance = () => {
  const dispatch = useDispatch()

  // Get values from the store
  const { data, total } = useSelector(state => state.maintenance.getAll.item)

  const [excludedColumns] = useState(['id', 'client_id'])
  const [tableState, setTableState] = useState({})
  const [menuOptions] = useState([])

  useEffect(async () => {
    await dispatch(
      GetAllMaintenance.action({
        params: { tableState },
        url: 'maintenances',
      }),
    )
  }, [tableState])

  return (
    <Datatable
      source="maintenances_late"
      data={data}
      total={total}
      tableState={tableState}
      setTableState={setTableState}
      menuOptions={menuOptions}
      excludedColumns={excludedColumns}
      elevation={1}
      sortable
      searchable
    />
  )
}

export default LateMaintenance
