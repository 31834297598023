/**
 * The external imports
 */
import { IconButton, TableCell, TableRow } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import getYear from 'date-fns/getYear'
import clsx from 'clsx'

/**
 * The internal imports
 */
import { formatNumber } from '../../Utils/Number'
import useStyles from '../../Theme/Components/Cashflow/Row.style'

const Row = ({ name, row }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [currentYear] = useState(getYear(new Date()))

  /**
   * Defines the correct class based on value
   * @param {*} value number
   * @returns style class
   */
  const defineTotalsClass = value => {
    if (name === 'totals') {
      if (value > 0) {
        return classes.positive
      }
      if (value < 0) {
        return classes.negative
      }
    }
  }

  return (
    <React.Fragment>
      <TableRow className={name === 'totals' ? classes.totalsRow : null}>
        <TableCell>
          {row.sub_rows && (
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell className={classes.mainRow}>
          {t(`pages.cashflow.${name}`)}
        </TableCell>
        <TableCell
          align="right"
          className={clsx([
            defineTotalsClass(row[currentYear]),
            classes.mainRow,
          ])}
        >
          {formatNumber(row[currentYear])}
        </TableCell>
        <TableCell
          align="right"
          className={clsx([defineTotalsClass(row.interval), classes.mainRow])}
        >
          {formatNumber(row.interval)}
        </TableCell>
        {Object.values(row.monthly).map(value => (
          <TableCell
            key={`cashflow-row-${value}`}
            align="right"
            className={clsx([defineTotalsClass(value), classes.mainRow])}
          >
            {formatNumber(value)}
          </TableCell>
        ))}
      </TableRow>
      {row.sub_rows &&
        open &&
        row.sub_rows.map(subRow => (
          <TableRow key={subRow.date} className={classes.tableRow}>
            <TableCell />
            <TableCell className={classes.subRowTitle}>{subRow.name}</TableCell>
            <TableCell align="right">
              {formatNumber(subRow[currentYear])}
            </TableCell>
            <TableCell align="right">{formatNumber(subRow.interval)}</TableCell>
            {Object.values(subRow.monthly).map(value => (
              <TableCell align="right">{formatNumber(value)}</TableCell>
            ))}
          </TableRow>
        ))}
    </React.Fragment>
  )
}

export default Row
