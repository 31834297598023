import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  badgeStyle: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    width: 10,
    height: 10,
    borderRadius: 10,
    display: 'inline-flex',
  },
  sent: {
    backgroundColor: theme.palette.success.main,
  },
  not_attributed: {
    backgroundColor: theme.palette.error.main,
  },
  inlineFlex: {
    display: 'inline-flex',
    marginLeft: theme.spacing(1),
    alignSelf: 'center',
  },
  tableContainer: {
    margin: 'auto',
    width: '90%',
    marginBottom: theme.spacing(3),
  },
  tableHeader: {
    fontWeight: 'bold',
  },
  edit: {
    color: theme.palette.secondary.light,
    cursor: 'pointer',
    margin: theme.spacing(0, 1),
  },
}))
export default useStyles
