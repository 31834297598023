import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '30%',
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  groupText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  groupBadge: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1,
  },
}))

export default useStyles
