import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  footerText: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    color: theme.typography.footerText,
  },
}))
export default useStyles
