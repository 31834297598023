/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import GetAll from './GetAll'

export default buildSlice('notifications', [GetAll], {
  getAll: { item: {} },
}).reducer
