/**
 * The external imports
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Typography } from '@mui/material'
import { MenuItem } from '@mui/material'
import { Close } from '@mui/icons-material'

/**
 * The internal imports
 */
import { Select } from '../index'
import useStyles from '../../Theme/Components/ReceivableBill/InvoiceLineForm'
import { formatCurrency } from '../../Utils/String'

const InvoiceLineForm = ({
  setInvoiceLines,
  invoiceLines,
  onChange,
  setSelectedInvoiceLines,
  selectedInvoiceLines,
  errors,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  /**
   * Removes a selected invoiceLine from the list
   * @param {*} invoiceLineId integer
   */
  const removeSelectedInvoiceLine = invoiceLineToRemove => {
    const newSelectedInvoiceLines = selectedInvoiceLines.filter(
      invoiceLine => invoiceLine.id !== invoiceLineToRemove.id,
    )
    setSelectedInvoiceLines(newSelectedInvoiceLines)
    const newInvoiceLines = [...invoiceLines]
    newInvoiceLines.push(invoiceLineToRemove)
    newInvoiceLines.sort((a, b) => a.id - b.id)
    setInvoiceLines(newInvoiceLines)
  }

  if (invoiceLines.length < 1 && selectedInvoiceLines.length < 1) {
    return <div>{t('components.invoice_lines_form.no_invoice_line')}</div>
  }

  return (
    <div>
      {invoiceLines.length > 0 && (
        <Select
          label={t('components.invoice_lines_form.add_invoice_lines')}
          onChange={onChange}
          value={null}
        >
          {invoiceLines.map(invoiceLine => (
            <MenuItem
              key={`invoiceLine_option-${invoiceLine.id}`}
              value={invoiceLine}
            >
              {invoiceLine.wording}
            </MenuItem>
          ))}
        </Select>
      )}
      {selectedInvoiceLines.map(invoiceLine => (
        <div key={`invoiceLine-${invoiceLine.id}`} className={classes.wrapper}>
          <Typography variant="h6">{invoiceLine.wording}</Typography>
          <div className={classes.info}>
            <Typography variant="h6">
              {formatCurrency(invoiceLine.amount)} CHF
            </Typography>
            <IconButton onClick={() => removeSelectedInvoiceLine(invoiceLine)}>
              <Close color="error" />
            </IconButton>
          </div>
        </div>
      ))}
      <div className={classes.wrapper}>
        <Typography variant="body2" color="error">
          {errors.invoice_lines}
        </Typography>
      </div>
      <div className={classes.wrapperTotal}>
        <Typography variant="h6">
          {t('components.invoice_lines_form.total')}
        </Typography>
        <Typography variant="h6">
          {formatCurrency(
            selectedInvoiceLines.reduce(
              (sum, invoiceLine) => sum + parseFloat(invoiceLine.amount),
              0,
            ),
          )}{' '}
          CHF
        </Typography>
      </div>
    </div>
  )
}
export default InvoiceLineForm
