/**
 * The external imports
 */
import React from 'react'
import Lottie from 'react-lottie'

/**
 * import
 */
import errorData from '../Theme/Lotties/error'

function Error() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: errorData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return <Lottie options={defaultOptions} />
}
export default Error
