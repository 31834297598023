/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import GetAll from './GetAll'
import Create from './Create'
import Update from './Update'
import FetchOne from './FetchOne'

export default buildSlice('refundRequest', [GetAll, Create, Update, FetchOne], {
  getAll: { item: {} },
  create: {},
  update: {},
  fetchOne: { item: {} },
}).reducer
