/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  referenceText: {
    paddingTop: 7,
    paddingBottom: 0,
    fontSize: 16,
  },
  lineWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingBottom: 6,
    paddingTop: 7,
  },
  width10: {
    width: '10%',
  },
  width15: {
    width: '15%',
  },
  width20: {
    width: '20%',
  },
}))

export default useStyles
