/**
 * The external imports
 */
import React from 'react'
import { Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import { Tabs, CalendarMaintenance, LateMaintenance } from '../../Components'

const MaintenancesPage = () => {
  const { t } = useTranslation()

  const tabs = [
    {
      label: t('pages.maintenance_planning.calendar'),
      content: <CalendarMaintenance />,
      url: '/calendar',
    },
    {
      label: t('pages.maintenance_planning.late'),
      content: <LateMaintenance />,
      url: '/late',
    },
  ]

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" color="inherit" gutterBottom>
        {t('pages.maintenance_planning.title')}
      </Typography>

      <Tabs tabs={tabs} baseUrl="/maintenances" />
    </Container>
  )
}

export default MaintenancesPage
