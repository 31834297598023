/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Add, AttachMoney } from '@mui/icons-material'
import { Container, Button, Typography, Grid } from '@mui/material'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

/**
 * The internal imports
 */
import GetAllUsers from '../../Store/User/GetAll'
import { Datatable } from '../../Components'
import useLayout from '../../Theme/Layouts/index'
import { openModal } from '../../Utils/Modal'
import Mask from '../../Services/Mask'

const Users = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const layout = useLayout()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { data, total } = useSelector(state => state.user.getAll.item)

  const [tableState, setTableState] = useState({
    filter: { approved: [true] },
  })

  /**
   * Signs the admin in as the selected user
   * @param {*} id integer
   */
  const loginAs = async id => {
    const response = await Mask({ url: `users/${id}/mask` })
    const first_name = data.find(user => user.id === id).first_name
    if (response.success) {
      enqueueSnackbar(
        t('notifications.impersonation_successful', { first_name }),
        { variant: 'success' },
      )
      history.push('/')
    } else {
      enqueueSnackbar(
        t('notifications.impersonation_unsuccessful', { first_name }),
        { variant: 'error' },
      )
    }
  }

  const [menuOptions] = useState([
    {
      label: t('datatables.show'),
      columns: ['id'],
      action: ({ id }) => history.push(`/users/${id}`),
    },
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: ({ id }) => openModal({ type: 'editUser', id }),
    },
    {
      label: t('datatables.loginAs'),
      columns: ['id'],
      action: ({ id }) => loginAs(id),
    },
  ])

  const [excludedColumns] = useState(['id', 'marital_status'])

  useEffect(async () => {
    await dispatch(GetAllUsers.action({ params: { tableState }, url: 'users' }))
  }, [tableState])

  return (
    <Container maxWidth="xl">
      <div className={clsx(layout.row, layout.flex)}>
        <Grid>
          <Typography variant="h4" color="inherit" gutterBottom>
            {t('pages.users.title')}
          </Typography>
        </Grid>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={() => openModal({ type: 'formSalaries' })}
              variant="contained"
              startIcon={<AttachMoney />}
              className={layout.mb}
            >
              {t('actions.pay')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => openModal({ type: 'inviteUser' })}
              variant="contained"
              color="primary"
              startIcon={<Add />}
              className={layout.mb}
            >
              {t('actions.add')}
            </Button>
          </Grid>
        </Grid>
      </div>

      <Datatable
        data={data}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
        rowLink={({ id }) => `/users/${id}`}
        source="users"
        hasFilters={true}
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
        defaultFilter={tableState.filter}
      />
    </Container>
  )
}

export default Users
