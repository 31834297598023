/**
 * The external imports
 */
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useDropzone } from 'react-dropzone'

/**
 * The internal imports
 */
import dropzoneStyles from '../Theme/Components/FileUploader.style'
import useStyles from '../Theme/Components/Modal/RefundRequestForm.style'

const FileUploader = ({ files, setFiles, filesToRemove, setFilesToRemove }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone()

  const style = useMemo(
    () => ({
      ...dropzoneStyles.base,
      ...(isDragActive ? dropzoneStyles.active : {}),
      ...(isDragAccept ? dropzoneStyles.accept : {}),
      ...(isDragReject ? dropzoneStyles.reject : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  /**
   * Removes the file at the given index
   * @param index
   * @param id
   */
  const removeFile = (index, id) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
    if (id) {
      const newFilesToRemove = [...filesToRemove]
      newFilesToRemove.push(id)
      setFilesToRemove(newFilesToRemove)
    }
  }

  /**
   * Updates the attached files list when acceptedFiles changes
   */
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const newFiles = [...files]
      newFiles.push(...acceptedFiles)
      setFiles(newFiles)
    }
  }, [acceptedFiles])

  return (
    <section className={classes.dropzoneWrapper}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{t('components.file_uploader.drop_text')}</p>
      </div>
      <div>
        {files.length > 0 && (
          <div>
            <Typography variant="h6">
              {t('components.file_uploader.files')}
            </Typography>
            <div>
              {files.map((file, index) => (
                <div key={file.path} className={classes.dropzoneFile}>
                  {file.path}
                  <IconButton
                    onClick={() => removeFile(index, file.id)}
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default FileUploader
