/**
 * The external imports
 */
import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import DestroyClient from '../../Store/Client/Destroy'

const DeleteClient = ({ id }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  /**
   * Toggles visibility if the cancel button is pressed
   */
  const handleCancel = () => {
    dispatch(ToggleVisibility.action())
  }

  /**
   * Dispatches the destroy action if the confirm button is pressed
   * @returns {Promise<void>}
   */
  const handleConfirm = async () => {
    const response = await dispatch(
      DestroyClient.action({ url: `clients/${id}` }),
    )
    if (response.payload.success) {
      enqueueSnackbar(t('notifications.client_deleted'), {
        variant: 'success',
      })
      await dispatch(ToggleVisibility.action())
    } else {
      enqueueSnackbar(response.payload.errors[0], { variant: 'error' })
    }
  }

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {t('components.client_delete.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('pages.clients.delete_confirm')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('actions.cancel')}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {t('actions.confirm')}
        </Button>
      </DialogActions>
    </>
  )
}

export default DeleteClient
