/**
 * The internal imports
 */
import api from '../index'

export default async ({ data }) => {
  const dataPost = new FormData()
  dataPost.append('user[first_name]', data.first_name)
  dataPost.append('user[last_name]', data.last_name)
  dataPost.append('user[middle_name]', data.middle_name)
  dataPost.append('user[birth_date]', data.birth_date)
  dataPost.append('user[avs]', data.avs)
  dataPost.append('user[origin]', data.origin)
  dataPost.append('user[iban]', data.iban)
  dataPost.append('user[role]', data.role)
  dataPost.append('user[approved]', data.approved)
  dataPost.append('user[marital_status]', data.marital_status)
  if (data.documentsToRemove.length > 0) {
    dataPost.append('user[documents_to_remove][]', data.documentsToRemove)
  }

  data.documents.forEach(document => {
    dataPost.append('user[documents][]', document)
  })

  Object.keys(data.address).forEach(key =>
    dataPost.append(`user[address_attributes][${key}]`, data.address[key]),
  )

  const response = await api.put(`users/${data.id}`, dataPost)
  return response.data
}
