/**
 * The external imports
 */
import React from 'react'
import { Typography, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import {
  Tabs,
  ReceivableBills,
  InvoiceLines,
  LateReceivableBills,
} from '../../Components'

const ReceivableBillsIndex = () => {
  const { t } = useTranslation()

  const tabs = [
    {
      label: t('pages.invoices.tabs.invoices'),
      url: '/invoices',
      content: <ReceivableBills />,
    },
    {
      label: t('pages.invoices.tabs.invoice_lines'),
      url: '/invoice_lines',
      content: <InvoiceLines />,
    },
    {
      label: t('pages.invoices.tabs.late'),
      url: '/late',
      content: <LateReceivableBills />,
    },
  ]

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" color="inherit" gutterBottom>
        {t('pages.invoices.title')}
      </Typography>

      <Tabs tabs={tabs} baseUrl="/invoices" />
    </Container>
  )
}

export default ReceivableBillsIndex
