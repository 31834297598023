/**
 * The external imports
 */
import React from 'react'
import { isFulfilled } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Box,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import InviteUser from '../../Store/User/Invite'
import useStyles from '../../Theme/Components/Modal/RefundRequestForm.style'
import useForms from '../../Theme/Components/Forms'
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import { TextField } from '../../Components/index'

const FormUsers = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const formClasses = useForms()
  const classes = useStyles()

  // Get values from the store
  const inviteUserError = useSelector(state => state.user.invite.error)
  const inviteUserLoading = useSelector(state => state.user.invite.loading)

  /**
   * Send update modification for password
   */
  const onSubmit = async data => {
    const inviteUserResponse = await dispatch(
      InviteUser.action({ url: 'users', params: { user: data } }),
    )

    if (isFulfilled(inviteUserResponse)) {
      enqueueSnackbar(t('notifications.user_invited'), {
        variant: 'success',
      })
      dispatch(ToggleVisibility.action())
    }
  }

  return (
    <Card>
      <CardHeader title={t('user.form.title')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Divider />
        <CardContent>
          <Controller
            name="type"
            control={control}
            defaultValue="Employee"
            render={({ field }) => <input type="hidden" {...field} />}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('user.email')}
                name="email"
                autoFocus
                error={inviteUserError?.message?.email?.length > 0}
                helperText={
                  inviteUserError?.message?.email &&
                  inviteUserError?.message?.email[0]
                }
                {...field}
              />
            )}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="first_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="first_name"
                    label={t('user.first_name')}
                    id="first_name"
                    error={inviteUserError?.message?.first_name?.length > 0}
                    helperText={
                      inviteUserError?.message?.first_name &&
                      inviteUserError?.message?.first_name[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="last_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="last_name"
                    label={t('user.last_name')}
                    type="last_name"
                    id="last_name"
                    error={inviteUserError?.message?.last_name?.length > 0}
                    helperText={
                      inviteUserError?.message?.last_name &&
                      inviteUserError?.message?.last_name[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.leftButton}
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={inviteUserLoading}
            >
              {t('actions.send')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default FormUsers
