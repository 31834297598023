/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  MenuItem,
  Box,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateProduct from '../../Store/Product/Create'
import UpdateProduct from '../../Store/Product/Update'
import GetFilterableFieldsService from '../../Services/GetFilterableFields'
import { Loader, TextField, Select } from '../../Components/index'
import FetchOneProduct from '../../Store/Product/FetchOne'
import useForms from '../../Theme/Components/Forms'

const ProductForm = ({ id }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState({})
  const [errors, setErrors] = useState({})
  const [filterableFields, setFilterableFields] = useState({})

  const client = useSelector(state => state.client.fetchOne.item)
  const currentUserId = useSelector(state => state.user.fetchOne.item.id)

  useEffect(async () => {
    const filterableFieldsResponse = await GetFilterableFieldsService(
      'employees',
    )
    setFilterableFields(filterableFieldsResponse)
    if (id) {
      const productResponse = await dispatch(
        FetchOneProduct.action({
          url: `clients/${client.id}/products/${id}`,
        }),
      )
      if (isFulfilled(productResponse)) {
        setProduct(productResponse.payload)
      }
    } else {
      setProduct({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new product form to the db
   */
  const onSubmit = async data => {
    if (id) {
      const updateProductResponse = await dispatch(
        UpdateProduct.action({
          url: `clients/${client.id}/products/${id}`,
          params: { product: data },
        }),
      )
      if (isFulfilled(updateProductResponse)) {
        if (updateProductResponse.payload.success) {
          enqueueSnackbar(t('notifications.product_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateProductResponse.payload.errors)
        }
      }
    } else {
      const newProductResponse = await dispatch(
        CreateProduct.action({
          url: `clients/${client.id}/products`,
          params: { product: data },
        }),
      )
      if (isFulfilled(newProductResponse)) {
        if (newProductResponse.payload.success) {
          enqueueSnackbar(t('notifications.product_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newProductResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.product_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="name"
            control={control}
            defaultValue={product.name || ''}
            render={({ field }) => (
              <TextField
                required
                id="name"
                label={t('product.name')}
                name="name"
                error={errors.name?.length > 0}
                helperText={errors.name && errors.name[0]}
                {...field}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            defaultValue={product.description || ''}
            render={({ field }) => (
              <TextField
                margin="normal"
                fullWidth
                id="description"
                label={t('product.description')}
                name="description"
                multiline
                rows={4}
                error={errors.description?.length > 0}
                helperText={errors.description && errors.reason[0]}
                {...field}
              />
            )}
          />
          <Controller
            name="employee_id"
            control={control}
            defaultValue={product.employee_id || currentUserId}
            render={({ field }) => (
              <Select
                label={t('work.owner')}
                error={errors.employee_id?.length > 0}
                helperText={errors.employee_id && errors.employee_id[0]}
                {...field}
              >
                {filterableFields.map(employee => (
                  <MenuItem key={`employee-${employee.id}`} value={employee.id}>
                    {`${employee.first_name} ${employee.last_name}`}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default ProductForm
