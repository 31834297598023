export const TableColumns = {
  users: [
    { label: 'id', type: 'string' },
    { label: 'first_name', type: 'string' },
    { label: 'last_name', type: 'string' },
    { label: 'activity_rate', type: 'percentage' },
    { label: 'holidays_balance', type: 'string' },
    { label: 'overtime_balance_base', type: 'string' },
    { label: 'role', type: 'enum' },
    { label: 'approved', type: 'boolean' },
    { label: 'marital_status', type: 'enum' },
  ],
  clients: [
    { label: 'id', type: 'string' },
    { label: 'number', type: 'string' },
    { label: 'name', type: 'string' },
  ],
  products: [
    { label: 'id', type: 'string' },
    { label: 'number', type: 'string' },
    { label: 'name', type: 'string' },
    { label: 'created_at', type: 'date' },
    { label: 'owner', type: 'string' },
  ],
  suppliers: [
    { label: 'id', type: 'string' },
    { label: 'name', type: 'string' },
    { label: 'paid_amount', type: 'currency' },
  ],
  payable_bills: [
    { label: 'id', type: 'string' },
    { label: 'subject', type: 'string' },
    { label: 'concern', type: 'string' },
    { label: 'payment_date', type: 'date', sortable: true },
    { label: 'documents_info', type: 'file' },
    { label: 'amount_ttc', type: 'currency', sortable: true },
  ],
  refund_requests: [
    { label: 'id', type: 'string' },
    { label: 'user.full_name', type: 'string' },
    { label: 'description', type: 'string' },
    { label: 'created_at', type: 'date', sortable: true },
    { label: 'refund_request_date', type: 'date' },
    { label: 'amount', type: 'currency', sortable: true },
    { label: 'justifications_info', type: 'file' },
    { label: 'status', type: 'enum' },
  ],
  off_days: [
    { label: 'id', type: 'string' },
    { label: 'created_at', type: 'date', sortable: true },
    { label: 'user.full_name', type: 'string' },
    { label: 'reason', type: 'string' },
    { label: 'imputable', type: 'enum' },
    { label: 'start_date', type: 'date', sortable: true },
    { label: 'end_date', type: 'date', sortable: true },
    { label: 'days_taken', type: 'string' },
    { label: 'justifications_info', type: 'file' },
    { label: 'status', type: 'enum' },
  ],
  maintenances: [
    { label: 'id', type: 'string' },
    { label: 'number', type: 'string' },
    { label: 'name', type: 'string' },
    { label: 'product_id', type: 'string' },
    { label: 'product_name', type: 'string' },
    { label: 'start_date', type: 'date' },
  ],
  maintenances_late: [
    { label: 'number', type: 'string' },
    { label: 'product.client.name', type: 'string' },
    { label: 'product_name', type: 'string' },
    { label: 'end_date', type: 'date' },
    { label: 'in_arrears_of', type: 'number' },
  ],
  prepaid_hours: [
    { label: 'id', type: 'string' },
    { label: 'number', type: 'string' },
    { label: 'name', type: 'string' },
    { label: 'product_id', type: 'string' },
    { label: 'product_name', type: 'string' },
    { label: 'start_date', type: 'date' },
  ],
  spent_hours: [
    { label: 'id', type: 'string' },
    { label: 'name', type: 'string' },
    { label: 'request_date', type: 'date' },
    { label: 'hours', type: 'string' },
  ],
  maintenance_planning: [
    { label: 'id', type: 'string' },
    { label: 'product.name', type: 'string' },
    { label: 'product.client.name', type: 'string' },
    { label: 'start_date', type: 'date' },
    { label: 'is_late?', type: 'string' },
    { label: 'technologies', type: 'list' },
  ],
  technologies: [
    { label: 'id', type: 'string' },
    { label: 'name', type: 'string' },
  ],
  works: [
    { label: 'id', type: 'string' },
    { label: 'number', type: 'string' },
    { label: 'name', type: 'string' },
    { label: 'product_name', type: 'string' },
    { label: 'start_date', type: 'date' },
    { label: 'state', type: 'enum' },
  ],
  offers: [
    { label: 'id', type: 'string' },
    { label: 'full_number', type: 'string' },
    { label: 'description', type: 'string' },
    { label: 'status', type: 'enum' },
    { label: 'documents_info', type: 'file' },
    { label: 'amount', type: 'currency' },
    { label: 'created_at', type: 'date' },
  ],
  contacts: [
    { label: 'id', type: 'string' },
    { label: 'full_name', type: 'string' },
    { label: 'email', type: 'string' },
    { label: 'phone', type: 'string' },
  ],
  settings: [
    { label: 'id', type: 'string' },
    { label: 'date', type: 'date' },
    { label: 'daily_hours', type: 'percentage' },
    { label: 'vacation_days', type: 'string' },
    { label: 'avs_percent', type: 'percentage' },
    { label: 'ac_percent', type: 'percentage' },
    { label: 'pc_percent', type: 'percentage' },
    { label: 'aanp_percent', type: 'percentage' },
    { label: 'assmal_percent', type: 'percentage' },
    { label: 'vat', type: 'percentage' },
    { label: 'payment_days', type: 'string' },
  ],
  client_invoice_lines: [
    { label: 'id', type: 'string' },
    { label: 'wording', type: 'string' },
    { label: 'product_name', type: 'string' },
    { label: 'amount', type: 'currency' },
    { label: 'status', type: 'enum' },
  ],
  client_invoice: [
    { label: 'id', type: 'string' },
    { label: 'number', type: 'string', sortable: true },
    { label: 'product_name', type: 'string' },
    { label: 'sending_date', type: 'date', sortable: true },
    { label: 'payment_date', type: 'date_button', sortable: true },
    { label: 'amount', type: 'currency', sortable: true },
    { label: 'amount_ttc', type: 'currency' },
  ],
  public_holiday: [
    { label: 'id', type: 'string' },
    { label: 'name', type: 'string' },
    { label: 'date', type: 'date' },
  ],
  kind_of_cost: [
    { label: 'id', type: 'string' },
    { label: 'name', type: 'string' },
  ],
  kind_of_service: [
    { label: 'id', type: 'string' },
    { label: 'name', type: 'string' },
  ],
  kind_of_document: [
    { label: 'id', type: 'string' },
    { label: 'letter', type: 'string' },
    { label: 'name', type: 'string' },
  ],
  receivable_bill: [
    { label: 'id', type: 'string' },
    { label: 'reference', type: 'string' },
    { label: 'client_name', type: 'string' },
    { label: 'client_id', type: 'string' },
    { label: 'product_name', type: 'string' },
    { label: 'sending_date', type: 'date', sortable: true },
    { label: 'payment_date', type: 'date_button', sortable: true },
    { label: 'amount', type: 'currency' },
    { label: 'amount_ttc', type: 'currency' },
  ],
  invoice_lines: [
    { label: 'id', type: 'string' },
    { label: 'number', type: 'string' },
    { label: 'client_name', type: 'string' },
    { label: 'banking_transaction.product.client.id', type: 'string' },
    { label: 'product_name', type: 'string' },
    { label: 'wording', type: 'string' },
    { label: 'amount', type: 'currency' },
    { label: 'status', type: 'method' },
  ],
  salaries: [
    { label: 'id', type: 'string' },
    { label: 'date', type: 'date_month_year' },
    { label: 'gross', type: 'currency' },
    { label: 'bonus_amount', type: 'currency' },
    { label: 'net_salary', type: 'currency' },
  ],
}
