/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

/**
 * The internal imports
 */
import useLayout from '../../Theme/Layouts'
import { Datatable } from '../index'
import { openModal } from '../../Utils/Modal'
import GetAllKindOfService from '../../Store/KindOfService/GetAll'

const SettingsKindOfService = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const layout = useLayout()

  // Get values from the store
  const { data, total } = useSelector(state => state.kindOfService.getAll.item)
  const getAllKindOfServiceLoading = useSelector(
    state => state.kindOfService.getAll.loading,
  )

  const createKindOfServiceLoading = useSelector(
    state => state.kindOfService.create.loading,
  )
  const updateKindOfServiceLoading = useSelector(
    state => state.kindOfService.update.loading,
  )

  const [excludedColumns] = useState(['id'])
  const [tableState, setTableState] = useState({})
  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: ({ id }) => openModal({ type: 'formKindOfService', id }),
    },
  ])

  useEffect(async () => {
    if (!createKindOfServiceLoading && !updateKindOfServiceLoading) {
      await dispatch(
        GetAllKindOfService.action({
          params: { tableState },
          url: 'kind_of_services',
        }),
      )
    }
  }, [tableState, createKindOfServiceLoading, updateKindOfServiceLoading])

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={() => openModal({ type: 'formKindOfService' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        source="kind_of_service"
        data={data}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
        loading={getAllKindOfServiceLoading}
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
        searchable={false}
        sortable={false}
      />
    </div>
  )
}

export default SettingsKindOfService
