/**
 * Formats the number to include thousands separator
 * @param {*} number
 * @returns string
 */
export const formatNumber = number => {
  return Number(number).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const formatCurrency = number => {
  if (parseFloat(number).toFixed(1) !== '0.0') {
    return `CHF ${parseFloat(number).toFixed(2)}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      "'",
    )
  }
  return 'CHF 0'
}
