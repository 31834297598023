/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import MUIDataTable, { debounceSearchRender } from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom'

/**
 * The external imports
 */
import { DatatableRowMenu } from '../index'
import BuildTableColumns from '../../Utils/BuildTableColumns'
import GetFilterableFieldsService from '../../Services/GetFilterableFields'
import useStyles from '../../Theme/Components/Datatable'
import { Config } from '../../Config'
import { TableColumns } from '../../Config/TableColumns'
import clsx from 'clsx'

const Datatable = ({
  source,
  data,
  total,
  tableState,
  setTableState,
  menuOptions = [],
  rowLink,
  hasFilters = false,
  filterSource = null,
  elevation = 4,
  excludedColumns = [],
  customMenuFilter = null,
  searchable = true,
  sortable = true,
  printable = false,
  title = '',
  defaultFilter = {},
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [filterableFields, setFilterableFields] = useState([])
  const [anchor, setAnchor] = useState({ element: null, id: null })
  const [columns, setColumns] = useState([])

  useEffect(async () => {
    if (hasFilters) {
      setFilterableFields(
        await GetFilterableFieldsService(filterSource || source),
      )
    }
  }, [])

  useEffect(async () => {
    const baseColumns = BuildTableColumns(
      source,
      filterableFields,
      excludedColumns,
      defaultFilter,
    )
    baseColumns.push({
      name: '',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <DatatableRowMenu
            anchor={anchor}
            key={tableMeta.tableData[0][0]}
            setAnchor={setAnchor}
            menuOptions={menuOptions}
            source={source}
            tableMeta={tableMeta}
            customFilter={customMenuFilter}
            data={data}
          />
        ),
      },
    })
    setColumns(baseColumns)
  }, [anchor, filterableFields, data])

  /**
   * Changes the local tableState values
   * @param field
   * @param value
   */
  const handleTableStateChange = (field, value, resetPage = false) => {
    setTableState(prevState => ({
      ...prevState,
      page: resetPage ? 0 : prevState.page,
      [field]: value,
    }))
  }

  /**
   * Updates the local tableState with new rowsPerPage
   * @param numRows
   */
  const handleChangeRowsPerPage = numRows => {
    handleTableStateChange('rowsPerPage', numRows)
    if (numRows >= data.length) {
      handleTableStateChange('page', 0)
    }
  }

  /**
   * Updates the local tableState with the new sort information
   * @param changedColumn
   * @param direction
   */
  const handleColumnSortChange = (changedColumn, direction) => {
    const sortObject = {
      field: changedColumn,
      direction,
    }
    handleTableStateChange('sort', sortObject)
  }

  /**
   * Transforms and updates the filters in the tableState
   * @param filterList
   */
  const updateFilters = filterList => {
    const filterObject = {}
    filterList.forEach((filter, index) => {
      if (columns[index].options.filter && filter.length > 0) {
        filterObject[columns[index].name] = filter.map(item => {
          const column = TableColumns[source].find(
            col => col.label === columns[index].name,
          )
          if (column.type === 'enum') {
            return columns[index].options.filterOptions.names.indexOf(item)
          } else if (column.type === 'method') {
            return filterableFields[columns[index].name][
              columns[index].options.filterOptions.names.indexOf(item)
            ]
          } else {
            return item
          }
        })
      }
    })
    handleTableStateChange('filter', filterObject)
  }

  /**
   * Handle click on cell
   * @param {*} colData : data shown
   * @param {*} cellMeta : cell meta data
   */
  const onCellClick = (colData, cellMeta) => {
    if (
      rowLink &&
      columns[cellMeta.colIndex].name !== '' &&
      ((TableColumns[source][cellMeta.colIndex].type === 'date_button' &&
        typeof colData === 'string') ||
        TableColumns[source][cellMeta.colIndex].type !== 'date_button')
    ) {
      if (cellMeta.event.ctrlKey || cellMeta.event.metaKey) {
        window.open(rowLink(data[cellMeta.rowIndex]), '_blank')
      } else {
        history.push(rowLink(data[cellMeta.rowIndex]))
      }
    }
  }

  const options = {
    search: searchable,
    sort: sortable,
    print: printable,
    serverSide: true,
    confirmFilters: true,
    filter: hasFilters,
    filterType: 'checkbox',
    elevation: elevation,
    enableNestedDataAccess: '.',
    page: tableState ? tableState.page : 0,
    textLabels: Config.DATATABLE_TRANSLATIONS,
    count: total,
    onCellClick: onCellClick,
    setRowProps: value => {
      return {
        className: clsx(rowLink && classes.pointer),
      }
    },
    rowsPerPage: tableState?.rowsPerPage ? tableState.rowsPerPage : 15,
    rowsPerPageOptions: [1, 5, 15, 100, 1000],
    onChangeRowsPerPage: numRows => handleChangeRowsPerPage(numRows),
    selectableRowsHideCheckboxes: true,
    onChangePage: currentPage => handleTableStateChange('page', currentPage),
    jumpToPage: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <div className={classes.applyFiltersButton}>
        <Button
          variant="contained"
          onClick={() => updateFilters(applyNewFilters())}
        >
          {t('datatables.apply')}
        </Button>
      </div>
    ),
    onFilterChipClose: (index, removedFilter, filterList) => {
      updateFilters(filterList)
    },
    onColumnSortChange: (changedColumn, direction) => {
      handleColumnSortChange(changedColumn, direction)
    },
    customSearchRender: debounceSearchRender(500),
    onSearchChange: searchText =>
      handleTableStateChange('search', searchText, true),
  }

  return (
    <div className={classes.wrapper}>
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
        title={title}
      />
    </div>
  )
}

export default Datatable
