/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem } from '@mui/material'

/**
 * The internal imports
 */
import { Datatable } from '../index'
import { openModal } from '../../Utils/Modal'
import GetAllInvoiceLines from '../../Store/InvoiceLine/GetAll'
import { TableColumns } from '../../Config/TableColumns'

const InvoiceLines = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // Get values from the store
  const { data, total } = useSelector(state => state.invoiceLine.getAll.item)
  const updateInvoiceLineLoading = useSelector(
    state => state.invoiceLine.update.loading,
  )

  const destroyInvoiceLineLoading = useSelector(
    state => state.invoiceLine.destroy.loading,
  )

  const [excludedColumns] = useState([
    'id',
    'banking_transaction.product.client.id',
  ])
  const [tableState, setTableState] = useState({})
  const [allMenuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id', 'banking_transaction.product.client.id'],
      action: props => {
        const id = props.id
        const client_id = props['banking_transaction.product.client.id']

        openModal({
          type: 'formInvoiceLine',
          id,
          options: { clientId: client_id },
        })
      },
    },
    {
      label: t('datatables.delete'),
      columns: ['id'],
      type: 'delete',
      action: ({ id }) =>
        openModal({
          type: 'destroyInvoiceLine',
          id,
        }),
    },
  ])

  /**
   * Shows "delete" option if invoice_line status is not_attributed
   * @param {*} tableMeta object
   * @param {*} menuOptions array
   * @param {*} setAnchor function
   * @returns array of filtered menu options
   */
  const customMenuFilter = (tableMeta, menuOptions, setAnchor) => {
    const statusColIndex = TableColumns.invoice_lines.findIndex(
      column => column.label === 'status',
    )
    const rowDataId =
      tableMeta.rowData[
        TableColumns.invoice_lines.findIndex(column => column.label === 'id')
      ]

    const isDeletable = tableMeta.rowData[statusColIndex] === 'not_attributed'
    const filteredMenuItems = []
    menuOptions.forEach(option => {
      if (
        (option.type === 'delete' && isDeletable) ||
        option.type !== 'delete'
      ) {
        filteredMenuItems.push(
          <MenuItem
            key={rowDataId}
            onClick={() => {
              setAnchor({ element: null, id: null })
              option.action({ id: rowDataId })
            }}
          >
            {option.label}
          </MenuItem>,
        )
      }
    })
    return filteredMenuItems
  }

  useEffect(async () => {
    if (!updateInvoiceLineLoading && !destroyInvoiceLineLoading) {
      await dispatch(
        GetAllInvoiceLines.action({
          params: { tableState },
          url: 'invoice_lines',
        }),
      )
    }
  }, [tableState, updateInvoiceLineLoading, destroyInvoiceLineLoading])

  return (
    <Datatable
      source="invoice_lines"
      hasFilters
      data={data}
      total={total}
      tableState={tableState}
      setTableState={setTableState}
      menuOptions={allMenuOptions}
      customMenuFilter={customMenuFilter}
      excludedColumns={excludedColumns}
      elevation={1}
      searchable={false}
      sortable={false}
    />
  )
}

export default InvoiceLines
