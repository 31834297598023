/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import { Datatable } from '../index'
import GetAllRefundRequests from '../../Store/RefundRequest/GetAll'
import { Config } from '../../Config'

const AllRefundRequest = ({
  customMenuFilter,
  handleEditClick,
  defaultFilters = {},
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { data, total } = useSelector(state => state.refundRequest.getAll.item)
  const refundRequestsError = useSelector(
    state => state.refundRequest.getAll.error,
  )
  const [excludedColumns] = useState(['id'])

  const createRefundRequestLoading = useSelector(
    state => state.refundRequest.create.loading,
  )
  const updateRefundRequestLoading = useSelector(
    state => state.refundRequest.update.loading,
  )

  const [tableState, setTableState] = useState({
    page: 0,
    rowsPerPage: Config.ROWS_PER_PAGE,
    filter: { ...defaultFilters },
    sort: {},
    search: '',
  })

  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      type: 'edit',
      columns: ['id'],
      action: id => handleEditClick({ id }),
    },
  ])

  useEffect(async () => {
    if (!createRefundRequestLoading && !updateRefundRequestLoading) {
      await dispatch(
        GetAllRefundRequests.action({
          params: { tableState, isAdmin: true },
          url: 'refund_requests',
        }),
      )
    }
  }, [tableState, createRefundRequestLoading, updateRefundRequestLoading])

  return (
    <Datatable
      source="refund_requests"
      data={data}
      total={total}
      tableState={tableState}
      setTableState={setTableState}
      error={refundRequestsError}
      menuOptions={menuOptions}
      customMenuFilter={customMenuFilter}
      hasFilters={true}
      excludedColumns={excludedColumns}
      elevation={1}
    />
  )
}

export default AllRefundRequest
