/**
 * The external imports
 */
import React, { useState } from 'react'
import { Tabs as MuiTabs, Tab } from '@mui/material'
import { useRouteMatch, useHistory } from 'react-router-dom'

/**
 * The internal imports
 */
import { TabPanel } from '../Components'
import useStyles from '../Theme/Components/Tabs'

const Tabs = ({ tabs, baseUrl }) => {
  const [tabsToDisplay] = useState(
    tabs.filter(tab => tab.condition === undefined || tab.condition),
  )
  const classes = useStyles()
  const history = useHistory()

  /**
   * Change active tab
   */
  const updateTabs = (event, newValue) => {
    history.push(newValue)
  }

  const routeMatch = useRouteMatch(tabs.map(tab => baseUrl + tab.url))
  const currentTab = routeMatch?.path || baseUrl + tabs[0].url

  return (
    <div>
      <MuiTabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        classes={{ root: classes.tabsRoot }}
        onChange={updateTabs}
        variant="scrollable"
      >
        {tabsToDisplay.map(tab => (
          <Tab
            key={`tab-${tab.label}`}
            label={tab.label}
            value={baseUrl + tab.url}
          />
        ))}
      </MuiTabs>
      {tabsToDisplay.map((tab, i) => (
        <TabPanel
          key={`tab_panel-${tab.label}`}
          value={currentTab}
          index={baseUrl + tab.url}
        >
          {tab.content}
        </TabPanel>
      ))}
    </div>
  )
}

export default Tabs
