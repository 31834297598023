/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import clsx from 'clsx'
import { Add } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import GetAllContacts from '../../Store/Contact/GetAll'
import useLayout from '../../Theme/Layouts'
import { openModal } from '../../Utils/Modal'
import { Datatable } from '../index'

const ClientContacts = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const layout = useLayout()

  const client = useSelector(state => state.client.fetchOne.item)
  const { data, total } = useSelector(state => state.contact.getAll.item)
  const createContactLoading = useSelector(
    state => state.contact.create.loading,
  )
  const updateContactLoading = useSelector(
    state => state.contact.update.loading,
  )
  const destroyContactLoading = useSelector(
    state => state.contact.destroy.loading,
  )

  const [tableState, setTableState] = useState({})
  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id', 'product.id'],
      action: params =>
        openModal({
          type: 'formContact',
          id: params.id,
        }),
    },
    {
      label: t('datatables.delete'),
      columns: ['id'],
      action: ({ id }) =>
        openModal({
          type: 'destroyContact',
          id,
          options: { clientId: client.id },
        }),
    },
  ])
  const [excludedColumns] = useState(['id'])

  useEffect(async () => {
    if (
      !createContactLoading &&
      !updateContactLoading &&
      !destroyContactLoading
    ) {
      await dispatch(
        GetAllContacts.action({
          params: { tableState },
          url: `clients/${client.id}/contacts`,
        }),
      )
    }
  }, [
    tableState,
    createContactLoading,
    updateContactLoading,
    destroyContactLoading,
  ])

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={() => openModal({ type: 'formContact' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        data={data}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
        source="contacts"
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
        searchable={false}
      />
    </div>
  )
}

export default ClientContacts
