import api from '../index'

export default async ({ email }) => {
  const response = await api.post('auth/password', {
    email,
    redirect_url: 'https://crm.wavemind.ch/auth/new-password',
  })

  return response.data
}
