/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Button,
  MenuItem,
  Box,
  Grid,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from '@mui/lab/DatePicker'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'

/**
 * The internal imports
 */
import useStyles from '../../Theme/Components/Modal/RefundRequestForm.style'
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateRefundRequest from '../../Store/RefundRequest/Create'
import UpdateRefundRequest from '../../Store/RefundRequest/Update'
import { Loader, Select, TextField } from '../index'
import { FileUploader } from '../index'
import FetchOneRefundRequest from '../../Store/RefundRequest/FetchOne'
import useForms from '../../Theme/Components/Forms'
import FetchAllService from '../../Services/FetchAll'

const Form = ({ id }) => {
  const formClasses = useForms()
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const currentUser = useSelector(state => state.user.item)
  const createRefundRequestLoading = useSelector(
    state => state.refundRequest.create.loading,
  )
  const updateRefundRequestLoading = useSelector(
    state => state.refundRequest.update.loading,
  )

  const [justifications, setJustifications] = useState([])
  const [justificationsToRemove, setJustificationsToRemove] = useState([])
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(true)
  const [isAdmin] = useState(currentUser.role === 'admin')
  const [refundRequest, setRefundRequest] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(async () => {
    if (isAdmin) {
      const response = await FetchAllService({ url: 'users' })
      setUserList(response)
    }
    if (id) {
      const refundRequestResponse = await dispatch(
        FetchOneRefundRequest.action({ url: `refund_requests/${id}` }),
      )
      if (isFulfilled(refundRequestResponse)) {
        setRefundRequest(refundRequestResponse.payload)
        setJustifications(
          refundRequestResponse.payload.justifications_info.map(
            justification => ({
              id: justification.id,
              path: justification.filename,
            }),
          ),
        )
      }
    } else {
      setRefundRequest({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new refund_request form to the db
   */
  const onSubmit = async params => {
    const data = {
      ...params,
      justifications: justifications.filter(justification => !justification.id),
    }
    if (id) {
      data.justificationsToRemove = justificationsToRemove
      const updateRefundRequestResponse = await dispatch(
        UpdateRefundRequest.action({ refundRequestId: id, params: data }),
      )
      if (isFulfilled(updateRefundRequestResponse)) {
        if (updateRefundRequestResponse.payload.success) {
          enqueueSnackbar(t('notifications.refund_request_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateRefundRequestResponse.payload.errors)
        }
      }
    } else {
      const newRefundRequestResponse = await dispatch(
        CreateRefundRequest.action(data),
      )
      if (isFulfilled(newRefundRequestResponse)) {
        if (newRefundRequestResponse.payload.success) {
          enqueueSnackbar(t('notifications.refund_request_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newRefundRequestResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.refund_requests_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          {isAdmin && (
            <Controller
              name="user_id"
              control={control}
              defaultValue={refundRequest.user?.id || currentUser.id}
              render={({ field }) => (
                <Select
                  label={t('components.refund_requests_form.employee')}
                  name="user_id"
                  id="user_id"
                  error={errors.user_id?.length > 0}
                  helperText={errors.user_id && errors.user_id[0]}
                  {...field}
                >
                  {userList.map(user => (
                    <MenuItem key={`user-${user.id}`} value={user.id}>
                      {user.full_name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}
          <Controller
            name="refund_request_date"
            control={control}
            defaultValue={refundRequest.refund_request_date || new Date()}
            render={({ field }) => (
              <DatePicker
                mask="__.__.____"
                label={t('refund_request.created_at')}
                renderInput={params => <TextField {...params} />}
                {...field}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            defaultValue={refundRequest.description || ''}
            render={({ field }) => (
              <TextField
                required
                id="description"
                label={t('refund_request.description')}
                name="description"
                multiline
                rows={6}
                error={errors.description?.length > 0}
                helperText={errors.description && errors.description[0]}
                {...field}
              />
            )}
          />
          <Controller
            name="amount"
            control={control}
            defaultValue={refundRequest.amount || ''}
            render={({ field }) => (
              <TextField
                label={t('refund_request.amount')}
                name="amount"
                error={errors.amount?.length > 0}
                helperText={errors.amount && errors.amount[0]}
                {...field}
              />
            )}
          />
          <Typography
            component="h3"
            variant="h5"
            className={classes.documentTitle}
          >
            {t('pages.refund_requests.form.documents')}
          </Typography>

          <FileUploader
            files={justifications}
            setFiles={setJustifications}
            filesToRemove={justificationsToRemove}
            setFilesToRemove={setJustificationsToRemove}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
              disabled={
                createRefundRequestLoading || updateRefundRequestLoading
              }
            >
              {t('actions.cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                createRefundRequestLoading || updateRefundRequestLoading
              }
            >
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default Form
