import api from '../index'

export default async ({ password, passwordConfirmation, invitationToken }) => {
  const response = await api.put('auth/invitation', {
    api_v1_user: {
      password,
      password_confirmation: passwordConfirmation,
      invitation_token: invitationToken,
    },
  })

  return response.data
}
