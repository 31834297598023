/**
 * The external imports
 */
import {
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import { CreateOfferService } from '../../Services/Offer'

export default {
  initialState: {
    create: {
      item: null,
      loading: false,
      error: null,
    },
  },
  action: buildAsyncActions('offer/create', CreateOfferService),
  reducers: buildAsyncReducers({
    itemKey: null,
    errorKey: 'create.error',
    loadingKey: 'create.loading',
  }),
}
