/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import GetAll from './GetAll'
import Download from './Download'
import Create from './Create'
import Latest from './Latest'
import FetchOne from './FetchOne'
import Update from './Update'

export default buildSlice(
  'salary',
  [GetAll, Download, Create, Latest, FetchOne, Update],
  {
    getAll: { item: {} },
    latest: { item: {} },
    fetchOne: { item: {} },
    download: {},
    create: {},
    update: {},
  },
).reducer
