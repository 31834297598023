/**
 * The external imports
 */
import {
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import GetAllService from '../../Services/Datatable/GetAll'

export default {
  initialState: {
    getAll: {
      item: [],
      loading: false,
      error: null,
    },
  },
  action: buildAsyncActions('offDay/getAll', GetAllService),
  reducers: buildAsyncReducers({
    itemKey: 'getAll.item',
    errorKey: 'getAll.error',
    loadingKey: 'getAll.loading',
  }),
}
