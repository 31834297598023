/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import GetAll from './GetAll'
import GetAllByClient from './GetAllByClient'
import Update from './Update'
import Create from './Create'
import FetchOne from './FetchOne'
import Destroy from './Destroy'

export default buildSlice(
  'invoiceLine',
  [GetAll, Update, Create, GetAllByClient, FetchOne, Destroy],
  {
    getAll: { item: {} },
    getAllByClient: { item: {} },
    fetchOne: { item: {} },
    update: {},
    destroy: {},
  },
).reducer
