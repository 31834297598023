/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import DatePicker from '@mui/lab/DatePicker'
import { isFulfilled } from '@reduxjs/toolkit'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import LatestSettings from '../../Store/Settings/Latest'
import LatestSalary from '../../Store/Salary/Latest'
import CreateSalary from '../../Store/Salary/Create'
import FetchOneSalary from '../../Store/Salary/FetchOne'
import UpdateSalary from '../../Store/Salary/Update'
import { Loader, TextField } from '../../Components/index'
import useForms from '../../Theme/Components/Forms'

const SalaryForm = ({
  id,
  employeeId,
  multiple = false,
  newSendingDate = null,
}) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit, setValue } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const settings = useSelector(state => state.settings.latest.item)
  const latestSalary = useSelector(state => state.salary.latest.item)
  const [loading, setLoading] = useState(true)
  const [salary, setSalary] = useState({})
  const [errors, setErrors] = useState([])

  useEffect(async () => {
    if (id) {
      const salaryResponse = await dispatch(
        FetchOneSalary.action({
          url: `employees/${employeeId}/salaries/${id}`,
        }),
      )

      if (isFulfilled(salaryResponse)) {
        setSalary(salaryResponse.payload)
      }
    } else {
      await dispatch(LatestSettings.action({ url: 'settings/latest' }))
      await dispatch(
        LatestSalary.action({ url: `employees/${employeeId}/salaries/latest` }),
      )
    }

    setLoading(false)
  }, [])

  useEffect(() => {
    if (newSendingDate) {
      setValue(
        'invoice_line_attributes.banking_transaction_attributes.sending_date',
        newSendingDate,
      )
    }
  }, [newSendingDate])

  /**
   * Handles validation and sends the new salary payment form to the db
   */
  const onSubmit = async data => {
    const params = {
      ...data,
      avs_percent:
        parseFloat(salary.avs_percent) || parseFloat(settings.avs_percent),
      ac_percent:
        parseFloat(salary.ac_percent) || parseFloat(settings.ac_percent),
      pc_percent:
        parseFloat(salary.pc_percent) || parseFloat(settings.pc_percent),
      aanp_percent:
        parseFloat(salary.aanp_percent) || parseFloat(settings.aanp_percent),
      assmal_percent:
        parseFloat(salary.assmal_percent) ||
        parseFloat(settings.assmal_percent),
      bonus_amount: parseFloat(data.bonus_amount),
      lpp_amount: parseFloat(data.lpp_amount),
      gross: parseFloat(data.gross),
      invoice_line_attributes: {
        ...data.invoice_line_attributes,
        dev_activity_rate: parseFloat(
          data.invoice_line_attributes.dev_activity_rate,
        ),
        web_activity_rate: parseFloat(
          data.invoice_line_attributes.web_activity_rate,
        ),
      },
    }

    if (id) {
      const updateSalaryResponse = await dispatch(
        UpdateSalary.action({
          url: `employees/${employeeId}/salaries/${id}`,
          params,
        }),
      )
      if (isFulfilled(updateSalaryResponse)) {
        if (updateSalaryResponse.payload.success) {
          enqueueSnackbar(t('notifications.salary_update'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateSalaryResponse.payload.errors)
        }
      }
    } else {
      const newSalaryResponse = await dispatch(
        CreateSalary.action({
          url: `employees/${employeeId}/salaries`,
          params,
        }),
      )

      if (isFulfilled(newSalaryResponse)) {
        if (!newSalaryResponse.payload.errors) {
          enqueueSnackbar(t('notifications.salary_created'), {
            variant: 'success',
          })
          if (!multiple) {
            dispatch(ToggleVisibility.action())
          }
        } else {
          setErrors(newSalaryResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      {!multiple && <CardHeader title={t('components.salary_form.title')} />}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Controller
          name="invoice_line_attributes.id"
          control={control}
          defaultValue={salary?.invoice_line?.id || null}
          render={({ field }) => <input type="hidden" {...field} />}
        />
        <Controller
          name="invoice_line_attributes.banking_transaction_attributes.id"
          control={control}
          defaultValue={salary?.invoice_line?.banking_transaction?.id || null}
          render={({ field }) => <input type="hidden" {...field} />}
        />
        <Controller
          name="type"
          control={control}
          defaultValue="Employee"
          render={({ field }) => <input type="hidden" {...field} />}
        />
        <Divider />
        <CardContent>
          <Box mb={3}>
            <Grid container spacing={3}>
              <Grid container item xs={6}>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    {t('settings.avs_percent')}
                  </Grid>
                  <Grid item xs={6}>
                    <b>{settings?.avs_percent || salary.avs_percent}%</b>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    {t('settings.ac_percent')}
                  </Grid>
                  <Grid item xs={6}>
                    <b>{settings?.ac_percent || salary.ac_percent}%</b>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    {t('settings.pc_percent')}
                  </Grid>
                  <Grid item xs={6}>
                    <b>{settings?.pc_percent || salary.pc_percent}%</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    {t('settings.aanp_percent')}
                  </Grid>
                  <Grid item xs={6}>
                    <b>{settings?.aanp_percent || salary.aanp_percent}%</b>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    {t('settings.assmal_percent')}
                  </Grid>
                  <Grid item xs={6}>
                    <b>{settings?.assmal_percent || salary.assmal_percent}%</b>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Controller
            name="invoice_line_attributes.banking_transaction_attributes.sending_date"
            control={control}
            defaultValue={
              salary.invoice_line?.banking_transaction?.sending_date ||
              new Date()
            }
            render={({ field }) => (
              <DatePicker
                mask="__.__.____"
                label={t('receivable_bill.sending_date')}
                renderInput={params => <TextField {...params} />}
                {...field}
              />
            )}
          />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Controller
                name="gross"
                control={control}
                defaultValue={salary.gross || latestSalary?.gross || ''}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label={t('salary.gross')}
                    name="invoice_line_attributes.amount"
                    error={errors?.amount?.length > 0}
                    helperText={errors?.amount && errors?.amount[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="bonus_amount"
                control={control}
                defaultValue={
                  salary.bonus_amount || latestSalary?.bonus_amount || '0'
                }
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label={t('salary.bonus')}
                    name="bonus_amount"
                    error={errors?.bonus_amount?.length > 0}
                    helperText={errors?.bonus_amount && errors?.bonus_amount[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="lpp_amount"
                control={control}
                defaultValue={
                  salary.lpp_amount || latestSalary?.lpp_amount || '0'
                }
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label={t('salary.lpp_amount')}
                    name="lpp_amount"
                    error={errors?.lpp_amount?.length > 0}
                    helperText={errors?.lpp_amount && errors?.lpp_amount[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="invoice_line_attributes.dev_activity_rate"
                control={control}
                defaultValue={salary?.invoice_line?.dev_activity_rate || ''}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label={t('invoice_line.dev_activity_rate')}
                    name="invoice_line_attributes.dev_activity_rate"
                    error={
                      errors?.['invoice_line.dev_activity_rate']?.length > 0
                    }
                    helperText={
                      errors?.['invoice_line.dev_activity_rate'] &&
                      errors?.['invoice_line.dev_activity_rate'][0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="invoice_line_attributes.web_activity_rate"
                control={control}
                defaultValue={salary?.invoice_line?.web_activity_rate || ''}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label={t('invoice_line.web_activity_rate')}
                    name="invoice_line_attributes.web_activity_rate"
                    error={
                      errors?.['invoice_line.web_activity_rate']?.length > 0
                    }
                    helperText={
                      errors?.['invoice_line.web_activity_rate'] &&
                      errors?.['invoice_line.web_activity_rate'][0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            {!multiple && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => dispatch(ToggleVisibility.action())}
              >
                {t('actions.close')}
              </Button>
            )}

            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default SalaryForm
