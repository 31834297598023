/**
 * The external imports
 */
import {
  buildAsyncState,
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import { GetStatsService } from '../../Services/Dashboard'

export default {
  initialState: buildAsyncState('getStats'),
  action: buildAsyncActions('dashboard/getStats', GetStatsService),
  reducers: buildAsyncReducers({
    errorKey: 'getStats.error',
    loadingKey: 'getStats.loading',
  }),
}
