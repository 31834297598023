/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import DatePicker from '@mui/lab/DatePicker'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateMaintenance from '../../Store/Maintenance/Create'
import UpdateMaintenance from '../../Store/Maintenance/Update'
import FetchOneMaintenance from '../../Store/Maintenance/FetchOne'
import { Loader, Select, TextField } from '../../Components/index'
import useForms from '../../Theme/Components/Forms'
import { Config } from '../../Config'
import { capitalize } from '../../Utils/String'
import FetchAllService from '../../Services/FetchAll'

const MaintenanceForm = ({ id, productId }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [checkedTechnologies, setCheckedTechnologies] = useState([])
  const [maintenance, setMaintenance] = useState({})
  const [errors, setErrors] = useState({})

  const client = useSelector(state => state.client.fetchOne.item)

  useEffect(async () => {
    const technologiesResponse = await FetchAllService({ url: 'technologies' })
    const newCheckedTechnologies = technologiesResponse.map(technology => ({
      id: null,
      name: technology.name,
      technology_id: technology.id,
      _destroy: true,
    }))

    setCheckedTechnologies(newCheckedTechnologies)
    const productsResponse = await FetchAllService({
      url: `clients/${client.id}/products`,
    })

    setProducts(productsResponse)

    if (id) {
      const maintenanceResponse = await dispatch(
        FetchOneMaintenance.action({
          url: `clients/${client.id}/products/${productId}/maintenances/${id}`,
        }),
      )
      if (isFulfilled(maintenanceResponse)) {
        const data = maintenanceResponse.payload
        setMaintenance(data)
        setCheckedTechnologies(
          newCheckedTechnologies.map(technology => {
            const maintenanceTechnology = data.maintenance_technologies.find(
              tech => tech.technology_id === technology.technology_id,
            )
            return {
              ...technology,
              id: maintenanceTechnology?.id || null,
              _destroy: !maintenanceTechnology?.hasOwnProperty('id'),
            }
          }),
        )
      }
    } else {
      setMaintenance({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new maintenance form to the db
   */
  const onSubmit = async data => {
    const params = {
      maintenance: {
        ...data,
        maintenance_technologies_attributes: checkedTechnologies,
      },
    }
    delete params.checkbox
    if (id) {
      const updateMaintenanceResponse = await dispatch(
        UpdateMaintenance.action({
          url: `clients/${client.id}/products/${data.product_id}/maintenances/${id}`,
          params,
        }),
      )
      if (isFulfilled(updateMaintenanceResponse)) {
        if (updateMaintenanceResponse.payload.success) {
          enqueueSnackbar(t('notifications.maintenance_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateMaintenanceResponse.payload.errors)
        }
      }
    } else {
      const newMaintenanceResponse = await dispatch(
        CreateMaintenance.action({
          url: `clients/${client.id}/products/${data.product_id}/maintenances`,
          params,
        }),
      )
      if (isFulfilled(newMaintenanceResponse)) {
        if (newMaintenanceResponse.payload.success) {
          enqueueSnackbar(t('notifications.maintenance_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newMaintenanceResponse.payload.errors)
        }
      }
    }
  }

  /**
   * Toggles the technology checkboxes
   * @param technologyId
   */
  const toggleCheck = technologyId => {
    const newCheckedTechnologies = [...checkedTechnologies]
    const index = newCheckedTechnologies.findIndex(
      technology => technology.technology_id === technologyId,
    )
    if (index > -1) {
      newCheckedTechnologies[index]._destroy =
        !newCheckedTechnologies[index]._destroy
      setCheckedTechnologies(newCheckedTechnologies)
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.maintenance_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="name"
            control={control}
            defaultValue={maintenance.name || ''}
            render={({ field }) => (
              <TextField
                required
                id="name"
                label={t('maintenance.name')}
                name="name"
                error={errors.name?.length > 0}
                helperText={errors.name && errors.name[0]}
                {...field}
              />
            )}
          />
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Controller
                name="start_date"
                control={control}
                defaultValue={maintenance.start_date || new Date()}
                render={({ field }) => (
                  <DatePicker
                    mask="__.__.____"
                    label={t('maintenance.start_date')}
                    renderInput={params => <TextField {...params} />}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="state"
                control={control}
                defaultValue={Math.max(
                  Config.MAINTENANCE_STATUSES.findIndex(
                    state => state === maintenance.state,
                  ),
                  0,
                )}
                render={({ field }) => (
                  <Select
                    label={t('maintenance.state')}
                    name="state"
                    id="state"
                    error={errors.state?.length > 0}
                    helperText={errors.state && errors.state[0]}
                    {...field}
                  >
                    {Config.MAINTENANCE_STATUSES.map((status, index) => (
                      <MenuItem key={`status-${status}`} value={index}>
                        {capitalize(status)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          </Grid>
          <Controller
            name="description"
            control={control}
            defaultValue={maintenance.description || ''}
            render={({ field }) => (
              <TextField
                required
                id="description"
                label={t('maintenance.description')}
                name="description"
                multiline
                rows={6}
                error={errors.description?.length > 0}
                helperText={errors.description && errors.description[0]}
                {...field}
              />
            )}
          />
          <Controller
            name="product_id"
            control={control}
            defaultValue={maintenance.product_id || productId || ''}
            render={({ field }) => (
              <Select
                label={t('maintenance.product_id')}
                name="product_id"
                id="product_id"
                error={errors.product_id?.length > 0}
                helperText={errors.product_id && errors.product_id[0]}
                {...field}
              >
                {products.map(prod => (
                  <MenuItem key={`product-${prod.id}`} value={prod.id}>
                    {prod.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Typography component="h4" variant="h6">
            {t('maintenance.technologies')}
          </Typography>
          <Controller
            name="checkbox"
            control={control}
            render={props => (
              <div>
                {checkedTechnologies.map(technology => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={!technology._destroy}
                        onChange={() => toggleCheck(technology.technology_id)}
                        {...props}
                      />
                    }
                    label={technology.name}
                  />
                ))}
              </div>
            )}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default MaintenanceForm
