/**
 * The external imports
 */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Container, MenuItem, Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

/**
 * The internal imports
 */
import {
  EditUsers,
  Tabs,
  AllOffDays,
  AllRefundRequest,
  UserDocuments,
  Salaries,
} from '../../Components'
import FetchOneUser from '../../Store/User/FetchOne'
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import SetParams from '../../Store/Modal/SetParams'
import { TableColumns } from '../../Config/TableColumns'
import { openModal } from '../../Utils/Modal'
import useLayout from '../../Theme/Layouts'

const ShowUser = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useDispatch()
  const layout = useLayout()

  const currentUser = useSelector(state => state.user.fetchOne.item)

  useEffect(async () => {
    await dispatch(FetchOneUser.action({ url: `users/${id}` }))
  }, [])

  /**
   * Opens the off day form modal
   * @param id
   * @returns {Promise<void>}
   */
  const handleOffDayEditClick = async ({ id: offDayId }) => {
    await dispatch(SetParams.action({ type: 'formOffDay', id: offDayId }))
    await dispatch(ToggleVisibility.action())
  }

  /**
   * Opens the refund_request form modal with an existing refund_request
   * @param id
   * @returns {Promise<void>}
   */
  const handleRefundRequestEditClick = async ({ id: refund_requestId }) => {
    await dispatch(
      SetParams.action({ type: 'formRefundRequest', id: refund_requestId }),
    )
    await dispatch(ToggleVisibility.action())
  }

  /**
   * Custom filter for the menu options
   * @param tableMeta
   * @param menuOptions
   * @param setAnchor
   * @returns {[]}
   */
  const customMenuFilter = (tableMeta, menuOptions, setAnchor) => {
    const statusColIndex = TableColumns.off_days.findIndex(
      column => column.label === 'status',
    )
    const rowDataId =
      tableMeta.rowData[
        TableColumns.off_days.findIndex(column => column.label === 'id')
      ]

    const isEditable = tableMeta.rowData[statusColIndex] === 'pending'
    const filteredMenuItems = []
    menuOptions.forEach(option => {
      if ((option.type === 'edit' && isEditable) || option.type !== 'edit') {
        filteredMenuItems.push(
          <MenuItem
            key={rowDataId}
            onClick={() => {
              setAnchor({ element: null, id: null })
              option.action(rowDataId)
            }}
          >
            {option.label}
          </MenuItem>,
        )
      }
    })
    return filteredMenuItems
  }

  const tabs = [
    {
      label: t('pages.profile.personal_info'),
      url: '/personal_info',
      content: <EditUsers id={id} />,
    },
    {
      label: t('pages.profile.off_days'),
      url: '/off_days',
      content: (
        <AllOffDays
          defaultFilters={{ user_id: [id] }}
          customMenuFilter={customMenuFilter}
          handleEditClick={handleOffDayEditClick}
        />
      ),
    },
    {
      label: t('pages.profile.refund_requests'),
      url: '/refund_requests',
      content: (
        <AllRefundRequest
          defaultFilters={{ user_id: [id] }}
          customMenuFilter={customMenuFilter}
          handleEditClick={handleRefundRequestEditClick}
        />
      ),
    },
    {
      label: t('pages.profile.salary_slip'),
      url: '/salary_slip',
      content: (
        <div>
          <Button
            onClick={() =>
              openModal({
                type: 'formSalary',
                options: { employeeId: currentUser.id },
              })
            }
            variant="contained"
            color="primary"
            startIcon={<Add />}
            className={layout.mb}
          >
            {t('actions.add')}
          </Button>
          <Salaries employeeId={id} />
        </div>
      ),
    },
    {
      label: t('pages.profile.documents'),
      url: '/documents',
      content: <UserDocuments />,
    },
  ]

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" color="inherit" gutterBottom>
        {currentUser.first_name}{' '}
        {currentUser.middle_name && `(${currentUser.middle_name})`}{' '}
        {currentUser.last_name}
      </Typography>

      <Typography variant="subtitle1" gutterBottom color="primary">
        {t(`user.role_items.${currentUser.role}`)}
      </Typography>

      <Tabs tabs={tabs} baseUrl={`/users/${id}`} />
    </Container>
  )
}

export default ShowUser
