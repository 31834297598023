import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: '1px solid black',
    '& .ql-editor': {
      cursor: 'text',
      overflowY: 'auto',
      height: '15em',
    },
  },
  editor: {
    '& .ql-picker-label::before': {
      color: theme.palette.primary.contrastText,
    },
    '& .ql-snow .ql-stroke': {
      stroke: theme.palette.primary.contrastText,
    },
  },
}))
export default useStyles
