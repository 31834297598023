/**
 * The external imports
 */
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

/**
 * The internal imports
 */
import auth from './Auth'
import client from './Client'
import product from './Product'
import supplier from './Supplier'
import payableBill from './PayableBill'
import offDay from './OffDay'
import offer from './Offer'
import theme from './Theme'
import user from './User'
import timeSheet from './TimeSheet'
import refundRequest from './RefundRequest'
import modal from './Modal'
import menu from './Menu'
import notifications from './Notifications'
import technology from './Technology'
import maintenance from './Maintenance'
import contact from './Contact'
import intervention from './Intervention'
import prepaidHour from './PrepaidHour'
import spentHour from './SpentHour'
import work from './Work'
import settings from './Settings'
import kindOfCost from './KindOfCost'
import kindOfDocument from './KindOfDocument'
import kindOfService from './KindOfService'
import publicHoliday from './PublicHoliday'
import receivableBill from './ReceivableBill'
import invoiceLine from './InvoiceLine'
import salary from './Salary'
import statistics from './Statistics'
import dashboard from './Dashboard'
import cashflow from './Cashflow'
import service from './Service'
import expense from './Expense'
import maintenancePeriod from './MaintenancePeriod'

const reducers = combineReducers({
  auth,
  client,
  product,
  supplier,
  payableBill,
  offDay,
  offer,
  theme,
  user,
  timeSheet,
  refundRequest,
  modal,
  menu,
  notifications,
  technology,
  maintenance,
  contact,
  intervention,
  prepaidHour,
  spentHour,
  work,
  settings,
  kindOfCost,
  kindOfDocument,
  kindOfService,
  publicHoliday,
  receivableBill,
  invoiceLine,
  salary,
  dashboard,
  statistics,
  cashflow,
  service,
  maintenancePeriod,
  expense,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['theme', 'user'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

const persistor = persistStore(store)

export { store, persistor }
