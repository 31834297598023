/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import clsx from 'clsx'
import { Add } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

/**
 * The internal imports
 */
import GetAllClientMaintenances from '../../Store/Maintenance/GetAllClient'
import useLayout from '../../Theme/Layouts'
import { openModal } from '../../Utils/Modal'
import { Datatable } from '../index'

const ClientMaintenances = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const layout = useLayout()

  const client = useSelector(state => state.client.fetchOne.item)
  const { data, total } = useSelector(
    state => state.maintenance.getAllClient.item,
  )
  const createMaintenanceLoading = useSelector(
    state => state.maintenance.create.loading,
  )

  const updateMaintenanceLoading = useSelector(
    state => state.maintenance.update.loading,
  )

  const [tableState, setTableState] = useState({})
  const [menuOptions] = useState([
    {
      label: t('datatables.show'),
      columns: ['id'],
      action: ({ id }) =>
        history.push(`/clients/${client.id}/maintenances/${id}`),
    },
    {
      label: t('datatables.edit'),
      columns: ['id', 'product.id'],
      action: params =>
        openModal({
          type: 'formMaintenance',
          id: params.id,
          options: { productId: params['product.id'] },
        }),
    },
  ])
  const [excludedColumns] = useState(['id', 'product_id'])

  useEffect(async () => {
    if (!createMaintenanceLoading && !updateMaintenanceLoading) {
      await dispatch(
        GetAllClientMaintenances.action({
          clientId: client.id,
          params: { tableState },
        }),
      )
    }
  }, [tableState, createMaintenanceLoading, updateMaintenanceLoading])

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={() => openModal({ type: 'formMaintenance' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        data={data}
        total={total}
        rowLink={({ id }) => `/clients/${client.id}/maintenances/${id}`}
        tableState={tableState}
        setTableState={setTableState}
        source="maintenances"
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
        hasFilters
        filterSource={`clients/${client.id}/maintenances`}
      />
    </div>
  )
}

export default ClientMaintenances
