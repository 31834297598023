/**
 * The external imports
 */
import { Button, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

/**
 * The internal imports
 */
import useStyles from '../../Theme/Pages/NotFoundPage/index.style'
import UnauthorizedIcon from './unauthorized.svg'
import DestroySession from '../../Store/Auth/DestroySession'

const NotAuthorizedPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  /**
   * Destroys the user session and redirects to the home page
   */
  const handleClick = async () => {
    await dispatch(DestroySession.action({}))
    history.push('/')
  }

  return (
    <>
      <header>
        <title>{t('pages.not_authorized.title')}</title>
        <meta name="description" content="Not authorized" />
      </header>
      <div className={classes.wrapper}>
        <Typography className={classes.title}>401</Typography>
        <Typography className={classes.subtitle}>
          {t('pages.not_authorized.header')}
        </Typography>
        <img src={UnauthorizedIcon} alt="logo" className={classes.icon} />

        <Button onClick={handleClick} variant="contained" color="secondary">
          {t('pages.not_authorized.return')}
        </Button>
      </div>
    </>
  )
}

export default NotAuthorizedPage
