/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { Button, Container, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

/**
 * The internal imports
 */
import GetAllSuppliers from '../../Store/Supplier/GetAll'
import { Datatable } from '../../Components'
import useLayout from '../../Theme/Layouts'
import { openModal } from '../../Utils/Modal'

const Suppliers = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const layout = useLayout()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { data, total } = useSelector(state => state.supplier.getAll.item)
  const getAllSuppliersError = useSelector(state => state.supplier.getAll.error)
  const createSupplierLoading = useSelector(
    state => state.supplier.create.loading,
  )
  const createSupplierError = useSelector(state => state.supplier.create.error)
  const updateSupplierLoading = useSelector(
    state => state.supplier.update.loading,
  )
  const updatePayableBillLoading = useSelector(
    state => state.payableBill.update.loading,
  )
  const updateSupplierError = useSelector(state => state.supplier.update.error)

  const [tableState, setTableState] = useState({})
  const [menuOptions] = useState([
    {
      label: t('datatables.show'),
      columns: ['id'],
      action: ({ id }) => history.push(`/suppliers/${id}`),
    },
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: params => openModal({ type: 'formSupplier', id: params.id }),
    },
  ])

  const [excludedColumns] = useState(['id'])

  useEffect(async () => {
    if (!createSupplierLoading && !updateSupplierLoading) {
      await dispatch(
        GetAllSuppliers.action({ params: { tableState }, url: 'suppliers' }),
      )
    }
  }, [
    tableState,
    createSupplierLoading,
    updateSupplierLoading,
    updatePayableBillLoading,
  ])

  useEffect(() => {
    createSupplierError &&
      enqueueSnackbar(createSupplierError.message, { variant: 'error' })
  }, [createSupplierError])

  useEffect(() => {
    updateSupplierError &&
      enqueueSnackbar(updateSupplierError.message, { variant: 'error' })
  }, [updateSupplierError])

  return (
    <Container maxWidth="xl">
      <div className={clsx(layout.row, layout.flex)}>
        <Typography variant="h4" color="inherit" gutterBottom>
          {t('pages.suppliers.title')}
        </Typography>

        <Button
          onClick={() => openModal({ type: 'formSupplier' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        data={data}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
        source="suppliers"
        error={getAllSuppliersError}
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        rowLink={({ id }) => `/suppliers/${id}`}
        elevation={1}
      />
    </Container>
  )
}

export default Suppliers
