/**
 * The external imports
 */
import {
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import LatestService from '../../Services/Datatable/FetchOne'

export default {
  initialState: {
    latest: {
      item: [],
      loading: false,
      error: null,
    },
  },
  action: buildAsyncActions('settings/latest', LatestService),
  reducers: buildAsyncReducers({
    itemKey: 'latest.item',
    errorKey: 'latest.error',
    loadingKey: 'latest.loading',
  }),
}
