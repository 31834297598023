/**
 * The external imports
 */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import clsx from 'clsx'
import { Container, Button, MenuItem, Typography } from '@mui/material'

/**
 * The internal imports
 */
import { MyOffDays, PendingOffDays, AllOffDays, Tabs } from '../../Components'
import { TableColumns } from '../../Config/TableColumns'
import useLayout from '../../Theme/Layouts/index'
import { openModal } from '../../Utils/Modal'

const OffDays = () => {
  const { t } = useTranslation()
  const layout = useLayout()

  const currentUser = useSelector(state => state.user.item)
  const [isAdmin] = useState(currentUser.role === 'admin')

  /**
   * Custom filter for the menu options
   * @param tableMeta
   * @param menuOptions
   * @param setAnchor
   * @returns {[]}
   */
  const customMenuFilter = (tableMeta, menuOptions, setAnchor) => {
    const statusColIndex = TableColumns.off_days.findIndex(
      column => column.label === 'status',
    )
    const rowDataId =
      tableMeta.rowData[
        TableColumns.off_days.findIndex(column => column.label === 'id')
      ]

    const isEditable = tableMeta.rowData[statusColIndex] === 'pending'
    const filteredMenuItems = []
    menuOptions.forEach(option => {
      if ((option.type === 'edit' && isEditable) || option.type !== 'edit') {
        filteredMenuItems.push(
          <MenuItem
            key={rowDataId}
            onClick={() => {
              setAnchor({ element: null, id: null })
              option.action(rowDataId)
            }}
          >
            {option.label}
          </MenuItem>,
        )
      }
    })
    return filteredMenuItems
  }

  const tabs = [
    {
      label: t('pages.off_days.mine'),
      url: '/mine',
      content: (
        <MyOffDays
          customMenuFilter={customMenuFilter}
          handleEditClick={({ id }) => openModal({ type: 'formOffDay', id })}
        />
      ),
    },
    {
      label: t('pages.off_days.to_review'),
      url: '/to_review',
      content: (
        <PendingOffDays
          handleEditClick={({ id }) => openModal({ type: 'formOffDay', id })}
        />
      ),
      condition: isAdmin,
    },
    {
      label: t('pages.off_days.all'),
      url: '/all',
      content: (
        <AllOffDays
          customMenuFilter={customMenuFilter}
          handleEditClick={({ id }) => openModal({ type: 'formOffDay', id })}
        />
      ),
      condition: isAdmin,
    },
  ]

  return (
    <Container maxWidth="xl">
      <div className={clsx(layout.row, layout.flex)}>
        <Typography variant="h4" color="inherit">
          {t('pages.off_days.title')}
        </Typography>

        <Button
          onClick={() => openModal({ type: 'formOffDay' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Tabs tabs={tabs} baseUrl="/off-days" />
    </Container>
  )
}

export default OffDays
