/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const drawerWidth = 300

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      '&$selected': {
        backgroundColor: theme.palette.action.selected,
      },
    },
    mainWrapper: {
      display: 'flex',
      flexGrow: 1,
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
      paddingLeft: 0,
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary.contrastText,
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    notificationsTitle: {
      marginLeft: theme.spacing(2),
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
    },
    notificationsSubtitle: {
      color: theme.palette.grey[600],
    },
    separator: {
      flex: 1,
      height: 2,
      backgroundColor: theme.palette.grey[500],
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginTop: 65,
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    content: {
      flexGrow: 1,
      flexDirection: 'column',
      overflow: 'auto',
      display: 'flex',
      marginTop: 65,
    },
    maintenances: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: 30,
    },
    maintenancesBadge: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      paddingRight: 10,
      paddingLeft: 10,
      paddingTop: 5,
      paddingBottom: 5,
    },
    container: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    auth: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fixedHeight: {
      height: 240,
    },
    footer: {
      marginTop: 'auto',
    },
    logo: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      maxHeight: 150,
    },
  }
})
export default useStyles
