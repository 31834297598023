/**
 * The external imports
 */
import { Button, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

/**
 * The internal imports
 */
import useStyles from '../../Theme/Pages/NotFoundPage/index.style'
// TODO change this icon when we have one
import UnauthorizedIcon from './unauthorized.svg'

const NotAuthorizedPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <header>
        <title>{t('pages.not_found.title')}</title>
        <meta name="description" content="Not found" />
      </header>
      <div className={classes.wrapper}>
        <Typography className={classes.title}>404</Typography>
        <Typography className={classes.subtitle}>
          {t('pages.not_found.header')}
        </Typography>
        <img src={UnauthorizedIcon} alt="logo" className={classes.icon} />

        <Button
          component={NavLink}
          to="/"
          variant="contained"
          color="secondary"
        >
          {t('pages.not_found.return')}
        </Button>
      </div>
    </>
  )
}

export default NotAuthorizedPage
