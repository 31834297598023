export default config => {
  const accessToken = localStorage.getItem('access_token')
  const client = localStorage.getItem('client')
  const expiry = localStorage.getItem('expiry')
  const uid = localStorage.getItem('uid')

  config.baseURL = process.env.REACT_APP_API_URL
  config.headers.common['access-token'] = accessToken
  config.headers.common.client = client
  config.headers.common.expiry = expiry
  config.headers.common.uid = uid

  return config
}
