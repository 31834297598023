/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    marginBottom: theme.spacing(4),
  },
  fullWidth: {
    width: '100%',
  },
}))

export default useStyles
