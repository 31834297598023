/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { Container, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

/**
 * The internal imports
 */
import { TimeSheet, Stats } from '../Components'
import GetStats from '../Store/Dashboard/GetStats'
import { Loader } from '../Components'

const Home = () => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const userRole = useSelector(state => state.user.item.role)

  useEffect(async () => {
    if (userRole === 'admin') {
      await dispatch(GetStats.action())
    }
    setLoading(false)
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <Container maxWidth="xl">
      {userRole === 'admin' && (
        <Box mb={3}>
          <Stats />
        </Box>
      )}
      <TimeSheet />
    </Container>
  )
}

export default Home
