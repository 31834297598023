/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateClient from '../../Store/Client/Create'
import UpdateClient from '../../Store/Client/Update'
import { AddressForm, Loader, TextField, FileUploader } from '../index'
import FetchOneClient from '../../Store/Client/FetchOne'
import useForms from '../../Theme/Components/Forms'

const ClientForm = ({ id }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [client, setClient] = useState({})
  const [documents, setDocuments] = useState([])
  const [documentsToRemove, setDocumentsToRemove] = useState([])
  const [sameAsMailing, setSameAsMailing] = useState(true)
  const [errors, setErrors] = useState({})

  useEffect(async () => {
    if (id) {
      const clientResponse = await dispatch(
        FetchOneClient.action({ url: `clients/${id}` }),
      )
      if (isFulfilled(clientResponse)) {
        setClient(clientResponse.payload)
        setDocuments(
          clientResponse.payload.documents_info.map(document => ({
            id: document.id,
            path: document.filename,
          })),
        )
        if (clientResponse.payload.billing_address) {
          setSameAsMailing(false)
        }
      }
    } else {
      setClient({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new client form to the db
   */
  const onSubmit = async params => {
    const data = {
      ...params,
      documents: documents.filter(document => !document.id),
    }
    if (id) {
      data.documentsToRemove = documentsToRemove
      const updateClientResponse = await dispatch(
        UpdateClient.action({ clientId: id, data }),
      )
      if (isFulfilled(updateClientResponse)) {
        if (updateClientResponse.payload.success) {
          enqueueSnackbar(t('notifications.client_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
          dispatch(FetchOneClient.action({ url: `clients/${id}` }))
        } else {
          setErrors(updateClientResponse.payload.errors)
        }
      }
    } else {
      const newClientResponse = await dispatch(CreateClient.action(data))
      if (isFulfilled(newClientResponse)) {
        if (newClientResponse.payload.success) {
          enqueueSnackbar(t('notifications.client_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newClientResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.client_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="name"
            control={control}
            defaultValue={client.name || ''}
            render={({ field }) => (
              <TextField
                required
                id="name"
                label={t('client.name')}
                name="name"
                error={errors.name?.length > 0}
                helperText={errors.name && errors.name[0]}
                {...field}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            defaultValue={client.description || ''}
            render={({ field }) => (
              <TextField
                required
                id="description"
                label={t('client.description')}
                name="description"
                multiline
                rows={4}
                error={errors.description?.length > 0}
                helperText={errors.description && errors.description[0]}
                {...field}
              />
            )}
          />
          <div className={formClasses.subHeader}>
            <Typography component="h3" variant="h5">
              {t('client.mailing_address')}
            </Typography>
            <FormControlLabel
              label={t('components.client_form.same_as_mailing')}
              control={
                <Checkbox
                  checked={sameAsMailing}
                  onChange={() => setSameAsMailing(!sameAsMailing)}
                  color="primary"
                />
              }
            />
          </div>
          <AddressForm
            control={control}
            addressType="mailing_address"
            errors={errors}
            data={client}
          />
          {!sameAsMailing && (
            <div>
              <Typography
                component="h3"
                variant="h5"
                className={formClasses.mt}
              >
                {t('client.billing_address')}
              </Typography>
              <AddressForm
                control={control}
                addressType="billing_address"
                errors={errors}
                data={client}
              />
            </div>
          )}

          <FileUploader
            files={documents}
            setFiles={setDocuments}
            filesToRemove={documentsToRemove}
            setFilesToRemove={setDocumentsToRemove}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default ClientForm
