/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  tabsRoot: {
    margin: theme.spacing(4, 0),
    '& .MuiTab-root': {
      color: `${theme.palette.primary.contrastText}`,
      padding: theme.spacing(2, 3, 2, 2),
      fontSize: 18,
    },
  },
}))

export default useStyles
