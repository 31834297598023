/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MenuItem } from '@mui/material'

/**
 * The internal imports
 */
import { Datatable } from '../index'
import { openModal } from '../../Utils/Modal'
import GetAllReceivableBills from '../../Store/ReceivableBill/GetAll'
import { TableColumns } from '../../Config/TableColumns'

const LateReceivableBills = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  // Get values from the store
  const { data, total } = useSelector(state => state.receivableBill.getAll.item)

  const updateReceivableBillLoading = useSelector(
    state => state.receivableBill.update.loading,
  )
  const destroyReceivableBillLoading = useSelector(
    state => state.receivableBill.destroy.loading,
  )

  const [excludedColumns] = useState(['id', 'client_id'])
  const [tableState, setTableState] = useState({
    filter: { late: [true] },
  })
  const [allMenuOptions] = useState([
    {
      label: t('datatables.show'),
      columns: ['id'],
      action: ({ id }) => history.push(`/invoices/${id}`),
    },
    {
      label: t('datatables.edit'),
      columns: ['id', 'client_id'],
      action: ({ id, client_id }) =>
        openModal({
          type: 'formReceivableBill',
          id,
          options: { clientId: client_id },
        }),
    },
    {
      label: t('datatables.delete'),
      columns: ['id'],
      type: 'delete',
      action: ({ id }) =>
        openModal({
          type: 'destroyReceivableBill',
          id,
        }),
    },
  ])

  /**
   * Shows "delete" option if invoice_line status is not_attributed
   * @param {*} tableMeta object
   * @param {*} menuOptions array
   * @param {*} setAnchor function
   * @returns array of filtered menu options
   */
  const customMenuFilter = (tableMeta, menuOptions, setAnchor) => {
    const statusColIndex = TableColumns.receivable_bill.findIndex(
      column => column.label === 'sending_date',
    )
    const rowDataId =
      tableMeta.rowData[
        TableColumns.receivable_bill.findIndex(column => column.label === 'id')
      ]

    const isDeletable = tableMeta.rowData[statusColIndex] === null
    const filteredMenuItems = []
    menuOptions.forEach(option => {
      if (
        (option.type === 'delete' && isDeletable) ||
        option.type !== 'delete'
      ) {
        filteredMenuItems.push(
          <MenuItem
            key={rowDataId}
            onClick={() => {
              setAnchor({ element: null, id: null })
              option.action({ id: rowDataId })
            }}
          >
            {option.label}
          </MenuItem>,
        )
      }
    })
    return filteredMenuItems
  }

  useEffect(async () => {
    if (!updateReceivableBillLoading && !destroyReceivableBillLoading) {
      await dispatch(
        GetAllReceivableBills.action({
          params: { tableState },
          url: 'receivable_bills',
        }),
      )
    }
  }, [tableState, updateReceivableBillLoading, destroyReceivableBillLoading])

  return (
    <Datatable
      source="receivable_bill"
      data={data}
      total={total}
      tableState={tableState}
      setTableState={setTableState}
      menuOptions={allMenuOptions}
      customMenuFilter={customMenuFilter}
      excludedColumns={excludedColumns}
      elevation={1}
      sortable
      rowLink={({ id }) => `/invoices/${id}`}
      searchable
    />
  )
}

export default LateReceivableBills
