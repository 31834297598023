/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import clsx from 'clsx'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import useLayout from '../../Theme/Layouts'
import GetAllWorks from '../../Store/Work/GetAll'
import { Datatable } from '../../Components'
import { openModal } from '../../Utils/Modal'

const ProductProject = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const layout = useLayout()

  // Get values from the store
  const client = useSelector(state => state.client.fetchOne.item)
  const { data, total } = useSelector(state => state.work.getAll.item)
  const getWorksLoading = useSelector(state => state.work.getAll.loading)

  const createWorksLoading = useSelector(state => state.work.create.loading)
  const updateWorksLoading = useSelector(state => state.work.update.loading)

  const [tableState, setTableState] = useState({})
  const [excludedColumns] = useState(['id'])

  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      type: 'edit',
      columns: ['id'],
      action: params =>
        openModal({
          type: 'formWork',
          id: params.id,
          options: { productId: params['product.id'] },
        }),
    },
  ])

  useEffect(async () => {
    if (!getWorksLoading && !getWorksLoading) {
      await dispatch(
        GetAllWorks.action({
          params: { tableState },
          url: `clients/${client.id}/works`,
        }),
      )
    }
  }, [tableState, createWorksLoading, updateWorksLoading])

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={() => openModal({ type: 'formWork' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        source="works"
        data={data}
        total={total}
        hasFilters
        tableState={tableState}
        setTableState={setTableState}
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
        filterSource={`clients/${client.id}/works`}
      />
    </div>
  )
}

export default ProductProject
