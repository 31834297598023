/**
 * The external imports
 */
import {
  buildAsyncState,
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import { GetWeekTimeSheetService } from '../../Services/TimeSheet'

export default {
  initialState: buildAsyncState('getWeek'),
  action: buildAsyncActions('timeSheet/getWeek', GetWeekTimeSheetService),
  reducers: buildAsyncReducers({
    errorKey: 'getWeek.error',
    loadingKey: 'getWeek.loading',
  }),
}
