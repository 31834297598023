import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  wrapperTotal: {
    margin: theme.spacing(2, 2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapper: {
    margin: theme.spacing(0, 0, 0, 5),
    display: 'flex',
    justifyContent: 'space-between',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '20%',
  },
}))

export default useStyles
