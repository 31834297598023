/**
 * The external imports
 */
import {
  buildAsyncState,
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import { GetCashflowService } from '../../Services/Stats'

export default {
  initialState: buildAsyncState('getCashflow'),
  action: buildAsyncActions('stats/getCashflow', GetCashflowService),
  reducers: buildAsyncReducers({
    errorKey: 'getCashflow.error',
    loadingKey: 'getCashflow.loading',
  }),
}
