/**
 * The internal imports
 */
import api from '../index'

export default async params => {
  const dataPost = new FormData()

  dataPost.append('client[name]', params.name)
  dataPost.append('client[description]', params.description)

  params.documents.forEach(document => {
    dataPost.append('client[documents][]', document)
  })

  Object.keys(params.mailing_address).forEach(key =>
    dataPost.append(
      `client[mailing_address_attributes][${key}]`,
      params.mailing_address[key],
    ),
  )

  if (params.billing_address) {
    Object.keys(params.mailing_address).forEach(key =>
      dataPost.append(
        `client[billing_address_attributes][${key}]`,
        params.billing_address[key],
      ),
    )
  }

  const response = await api.post('clients', dataPost)
  return response.data
}
