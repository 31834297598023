/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import GetWeek from './GetWeek'

const sliceInitialState = {
  item: {
    days: [],
    balance: 0,
  },
}

export default buildSlice('timeSheet', [GetWeek], sliceInitialState).reducer
