/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from '@mui/lab/DatePicker'
import {
  Button,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  Paper,
  Typography,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import GetAllUsers from '../../Store/User/GetAll'
import { Loader, FormSalary, TextField } from '../../Components/index'

const SalariesForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = React.useState(0)
  const [loading, setLoading] = useState(true)
  const [sendingDate, setSendingDate] = useState(null)

  const { data } = useSelector(state => state.user.getAll.item)

  useEffect(async () => {
    await dispatch(GetAllUsers.action({ url: 'users' }))
    setLoading(false)
  }, [])

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <div>
      <Box m={2}>
        <DatePicker
          mask="__.__.____"
          value={sendingDate}
          onChange={newDate => setSendingDate(newDate)}
          label={t('receivable_bill.sending_date')}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }}
            />
          )}
        />
      </Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {data.map(user => {
          if (user.approved) {
            return (
              <Step key={user.full_name}>
                <StepLabel>
                  <Typography variant="h5">
                    {user.first_name} {user.last_name}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <FormSalary
                    employeeId={user.id}
                    multiple
                    newSendingDate={sendingDate}
                  />
                  <Box mt={3}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={() =>
                          setActiveStep(prevActiveStep => prevActiveStep - 1)
                        }
                      >
                        {t('actions.previous')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          setActiveStep(prevActiveStep => prevActiveStep + 1)
                        }
                      >
                        {activeStep === data.length - 1
                          ? t('actions.close')
                          : t('actions.next')}
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            )
          }
        })}
      </Stepper>
      {activeStep === data.filter(user => user.approved).length && (
        <Box m={3}>
          <Paper square elevation={0}>
            <Button onClick={() => dispatch(ToggleVisibility.action())}>
              {t('actions.close')}
            </Button>
          </Paper>
        </Box>
      )}
    </div>
  )
}

export default SalariesForm
