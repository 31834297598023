/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  Container,
  Grid,
  MenuItem,
  Menu,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import Snowfall from 'react-snowfall'
import { useSnackbar } from 'notistack'

/**
 * The internal imports
 */
import DestroySessionAuth from '../Store/Auth/DestroySession'
import GetAllNotifications from '../Store/Notifications/GetAll'
import useStyles from '../Theme/Layouts/Admin'
import Sidebar from './Sidebar'
import { Copyright, LordIcon, Search } from '../Components'
import { fullDate, isDecember, shortDayAndMonth } from '../Utils/Date'
import { truncateText } from '../Utils/String'
import ChangeTheme from '../Store/Theme/ChangeTheme'

const Admin = ({ children }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(true)
  const [date, setDate] = useState(new Date())
  const [profileAnchor, setProfileAnchor] = React.useState(null)
  const [notificationsAnchor, setNotificationsAnchor] = React.useState(null)

  const notifications = useSelector(state => state.notifications.getAll.item)
  const currentUserRole = useSelector(state => state.user.item.role)
  const darkMode = useSelector(state => state.theme.darkMode)
  const notificationsError = useSelector(
    state => state.notifications.getAll.error,
  )

  useEffect(async () => {
    if (currentUserRole === 'admin') {
      await dispatch(GetAllNotifications.action({ url: 'notifications' }))
    }

    const timerId = setInterval(refreshClock, 1000)
    return function cleanup() {
      clearInterval(timerId)
    }
  }, [])

  useEffect(() => {
    notificationsError &&
      enqueueSnackbar(notificationsError.message, { variant: 'error' })
  }, [notificationsError])

  const handleClose = url => {
    setProfileAnchor(null)
    setNotificationsAnchor(null)
    history.push(url)
  }

  const refreshClock = () => {
    setDate(new Date())
  }

  const logout = () => {
    dispatch(DestroySessionAuth.action({}))
  }

  const updateTheme = event => {
    dispatch(ChangeTheme.action({ darkMode: event.target.checked }))
  }

  return (
    <div className={classes.root}>
      {isDecember() && <Snowfall color="white" snowflakeCount={90} />}
      <CssBaseline />
      <div className={classes.mainWrapper}>
        <AppBar
          className={clsx(classes.appBar, open && classes.appBarShift)}
          enableColorOnDark
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.toolbarIcon}>
              {open ? (
                <IconButton onClick={() => setOpen(false)} size="large">
                  <LordIcon icon="left" scale="40" selected />
                </IconButton>
              ) : (
                <IconButton onClick={() => setOpen(true)} size="large">
                  <LordIcon icon="right" scale="40" selected />
                </IconButton>
              )}
            </div>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {fullDate(date)}
            </Typography>
            <Search />
            <div>
              <IconButton
                color="inherit"
                aria-controls="notifications-menu"
                aria-haspopup="true"
                aria-owns="notifications-menu"
                onClick={e => setProfileAnchor(e.currentTarget)}
                size="large"
              >
                <Badge
                  badgeContent={
                    notifications.refund_requests.length +
                    notifications.off_days.length
                  }
                  color="primary"
                >
                  <LordIcon icon="notifications" />
                </Badge>
              </IconButton>
              <Menu
                id="notifications-menu"
                anchorEl={profileAnchor}
                keepMounted
                open={Boolean(profileAnchor)}
                onClose={handleClose}
              >
                {notifications.refund_requests.length > 0 && (
                  <div>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.notificationsTitle}
                    >
                      {t('side_bar.refund_requests')}
                    </Typography>
                    {notifications.refund_requests.map(request => (
                      <MenuItem
                        key={request.id}
                        onClick={() => handleClose('/refund_requests')}
                      >
                        <Grid container>
                          <Grid item md={12}>
                            CHF {request.amount} -{' '}
                            {`${truncateText(request.description, 10)}`}
                          </Grid>
                          <Grid item md={12}>
                            <Typography
                              variant="caption"
                              className={classes.notificationsSubtitle}
                            >
                              {request.user.full_name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </div>
                )}
                {notifications.refund_requests.length > 0 &&
                  notifications.off_days.length > 0 && (
                    <div className={classes.separator} />
                  )}
                {notifications.off_days.length > 0 && (
                  <div>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.notificationsTitle}
                    >
                      {t('side_bar.off_days')}
                    </Typography>
                    {notifications.off_days.map(offDay => (
                      <MenuItem
                        key={offDay.id}
                        onClick={() => handleClose('/off-days')}
                      >
                        <Grid container>
                          <Grid item md={12}>
                            {offDay.user.full_name} -{' '}
                            {shortDayAndMonth(new Date(offDay.start_date))} au{' '}
                            {shortDayAndMonth(new Date(offDay.end_date))}
                          </Grid>
                          <Grid item md={12}>
                            <Typography
                              variant="caption"
                              className={classes.notificationsSubtitle}
                            >
                              {truncateText(offDay.reason, 50)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </div>
                )}
                {notifications.off_days.length > 0 &&
                  notifications.late_maintenances > 0 && (
                    <div className={classes.separator} />
                  )}
                {notifications.late_maintenances > 0 && (
                  <div className={classes.maintenances}>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.notificationsTitle}
                    >
                      {t('side_bar.late_maintenances')}
                    </Typography>
                    <div className={classes.maintenancesBadge}>
                      {notifications.late_maintenances}
                    </div>
                  </div>
                )}
              </Menu>
            </div>
            <div>
              <IconButton
                color="inherit"
                aria-controls="profil-menu"
                aria-haspopup="true"
                aria-owns="profil-menu"
                onClick={e => setNotificationsAnchor(e.currentTarget)}
                size="large"
              >
                <LordIcon icon="profile" />
              </IconButton>
              <Menu
                id="profil-menu"
                anchorEl={notificationsAnchor}
                keepMounted
                open={Boolean(notificationsAnchor)}
                onClose={handleClose}
              >
                <MenuItem>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={darkMode}
                          onChange={updateTheme}
                          name="theme"
                        />
                      }
                      label={t('dark_mode')}
                    />
                  </FormGroup>
                </MenuItem>
                <MenuItem onClick={() => handleClose('/profile')}>
                  {t('side_bar.profile')}
                </MenuItem>
                <MenuItem onClick={logout}>{t('side_bar.logout')}</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          {open && (
            <img
              className={classes.logo}
              src={
                process.env.PUBLIC_URL +
                `/logo/logo-${darkMode ? 'white' : 'black'}-sentence.svg`
              }
            />
          )}
          <Sidebar />
        </Drawer>
        <main className={classes.content}>
          <Container maxWidth="xl" className={classes.container}>
            <Grid container>{children}</Grid>
          </Container>

          <footer className={classes.footer}>
            <Container maxWidth="sm">
              <Copyright />
            </Container>
          </footer>
        </main>
      </div>
    </div>
  )
}

export default Admin
