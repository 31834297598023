/**
 * The external imports
 */
import React from 'react'
import TextField from '@mui/material/TextField'

export default props => {
  const {
    id,
    required,
    label,
    name,
    error,
    helperText,
    type,
    fullWidth = true,
    disabled = false,
  } = props

  return (
    <TextField
      margin="normal"
      variant="filled"
      required={required}
      fullWidth={fullWidth}
      id={id}
      label={label}
      name={name}
      error={error}
      disabled={disabled}
      helperText={helperText}
      type={type || 'string'}
      {...props}
    />
  )
}
