/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import DatePicker from '@mui/lab/DatePicker'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateSpentHour from '../../Store/SpentHour/Create'
import UpdateSpentHour from '../../Store/SpentHour/Update'
import FetchOneSpentHour from '../../Store/SpentHour/FetchOne'
import { Loader, TextEditor, TextField } from '../../Components/index'
import useForms from '../../Theme/Components/Forms'

const SpentHourForm = ({ id, clientId, prepaidHourId }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [spentHour, setSpentHour] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(async () => {
    if (id) {
      const spentHourResponse = await dispatch(
        FetchOneSpentHour.action({
          url: `clients/${clientId}/prepaid_hours/${prepaidHourId}/spent_hours/${id}`,
        }),
      )
      if (isFulfilled(spentHourResponse)) {
        const data = spentHourResponse.payload
        setSpentHour(data)
      }
    } else {
      setSpentHour({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new spentHour form to the db
   */
  const onSubmit = async data => {
    if (id) {
      const updateSpentHourResponse = await dispatch(
        UpdateSpentHour.action({
          url: `clients/${clientId}/prepaid_hours/${prepaidHourId}/spent_hours/${id}`,
          params: { spent_hour: data },
        }),
      )
      if (isFulfilled(updateSpentHourResponse)) {
        if (updateSpentHourResponse.payload.success) {
          enqueueSnackbar(t('notifications.spent_hour_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateSpentHourResponse.payload.errors)
        }
      }
    } else {
      const newSpentHourResponse = await dispatch(
        CreateSpentHour.action({
          url: `clients/${clientId}/prepaid_hours/${prepaidHourId}/spent_hours`,
          params: { spent_hour: data },
        }),
      )
      if (isFulfilled(newSpentHourResponse)) {
        if (newSpentHourResponse.payload.success) {
          enqueueSnackbar(t('notifications.spent_hour_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newSpentHourResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.spent_hour_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="name"
            control={control}
            defaultValue={spentHour.name || ''}
            render={({ field }) => (
              <TextField
                required
                id="name"
                label={t('spent_hour.name')}
                name="name"
                error={errors.name?.length > 0}
                helperText={errors.name && errors.name[0]}
                {...field}
              />
            )}
          />
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Controller
                name="request_date"
                control={control}
                defaultValue={spentHour.request_date || new Date()}
                render={({ field }) => (
                  <DatePicker
                    mask="__.__.____"
                    label={t('spent_hour.request_date')}
                    renderInput={params => <TextField {...params} />}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="hours"
                control={control}
                defaultValue={spentHour.hours || 0}
                render={({ field }) => (
                  <TextField
                    required
                    id="hours"
                    label={t('spent_hour.hours')}
                    name="hours"
                    error={errors.hours?.length > 0}
                    helperText={errors.hours && errors.hours[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Controller
            name="description"
            control={control}
            defaultValue={spentHour.description?.body || ''}
            render={({ field }) => (
              <TextEditor value={field.value} onChange={field.onChange} />
            )}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default SpentHourForm
