/**
 * The external imports
 */
import {
  buildAsyncState,
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import { AcceptInvitationAuthService } from '../../Services/Auth'

export default {
  initialState: buildAsyncState('acceptInvitation'),
  action: buildAsyncActions(
    'auth/acceptInvitation',
    AcceptInvitationAuthService,
  ),
  reducers: buildAsyncReducers({
    errorKey: 'acceptInvitation.error',
    loadingKey: 'acceptInvitation.loading',
  }),
}
