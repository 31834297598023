/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  tooltip: { minWidth: 250, maxWidth: 350, padding: 10 },
  tooltipInfo: { marginTop: 5, marginBottom: 5 },
  headerWrapper: { display: 'flex', alignItems: 'center' },
  header: { marginRight: 10 },
  pending: { fill: theme.palette.primary.main },
  accepted: { fill: theme.palette.success.main },
  refused: { fill: theme.palette.error.main },
  cancelled: { fill: theme.palette.secondary.main },
}))

export default useStyles
