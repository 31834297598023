/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

/**
 * The internal imports
 */
import useLayout from '../../Theme/Layouts'
import { Datatable } from '../index'
import { openModal } from '../../Utils/Modal'
import GetAllKindOfDocument from '../../Store/KindOfDocument/GetAll'

const SettingsKindOfDocument = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const layout = useLayout()

  // Get values from the store
  const { data, total } = useSelector(state => state.kindOfDocument.getAll.item)
  const getAllKindOfDocumentLoading = useSelector(
    state => state.kindOfDocument.getAll.loading,
  )

  const createKindOfDocumentLoading = useSelector(
    state => state.kindOfDocument.create.loading,
  )
  const updateKindOfDocumentLoading = useSelector(
    state => state.kindOfDocument.update.loading,
  )

  const [excludedColumns] = useState(['id'])
  const [tableState, setTableState] = useState({})
  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: ({ id }) => openModal({ type: 'formKindOfDocument', id }),
    },
  ])

  useEffect(async () => {
    if (!createKindOfDocumentLoading && !updateKindOfDocumentLoading) {
      await dispatch(
        GetAllKindOfDocument.action({
          params: { tableState },
          url: 'kind_of_documents',
        }),
      )
    }
  }, [tableState, createKindOfDocumentLoading, updateKindOfDocumentLoading])

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={() => openModal({ type: 'formKindOfDocument' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        source="kind_of_document"
        data={data}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
        loading={getAllKindOfDocumentLoading}
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
        searchable={false}
        sortable={false}
      />
    </div>
  )
}

export default SettingsKindOfDocument
