/**
 * The internal imports
 */
import api from '../index'
import { Config } from '../../Config'

export default async ({ clientId, params }) => {
  const {
    tableState: {
      page = 0,
      rowsPerPage = Config.ROWS_PER_PAGE,
      filter = {},
      sort = {},
      search = '',
    },
  } = params

  const response = await api.post(
    `clients/${clientId}/maintenances/client_search`,
    { page, rowsPerPage, filter, sort, search },
  )
  return response.data
}
