/**
 * The external imports
 */
import {
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import FetchOneService from '../../Services/Datatable/FetchOne'

export default {
  initialState: {
    fetchOne: {
      item: {},
      loading: false,
      error: null,
    },
  },
  action: buildAsyncActions('maintenancePeriod/fetchOne', FetchOneService),
  reducers: buildAsyncReducers({
    itemKey: 'fetchOne.item',
    errorKey: 'fetchOne.error',
    loadingKey: 'fetchOne.loading',
  }),
}
