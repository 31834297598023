/**
 * The external imports
 */
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Snake from 'react-simple-snake'

/**
 * The internal imports
 */
import HomePage from '../Pages'
import SignInPage from '../Pages/Auth/SignIn'
import ForgotPassword from '../Pages/Auth/ForgotPassword'
import NewPassword from '../Pages/Auth/NewPassword'
import NotFound from '../Pages/NotFound'
import NotAuthorized from '../Pages/NotAuthorized'
import UsersPage from '../Pages/Users'
import ShowUserPage from '../Pages/Users/Show'
import ClientsPage from '../Pages/Clients'
import ClientPage from '../Pages/Clients/Show'
import PrepaidHourPage from '../Pages/PrepaidHours/Show'
import MaintenancePage from '../Pages/Maintenances/Show'
import ProfilePage from '../Pages/Profile'
import RefundRequestsPage from '../Pages/RefundRequests'
import OffDaysPage from '../Pages/OffDays'
import AbsencesPage from '../Pages/Absences'
import SuppliersPage from '../Pages/Suppliers'
import SupplierPage from '../Pages/Suppliers/Show'
import Menu from '../Pages/Menu'
import MaintenancesPage from '../Pages/Maintenances'
import SettingsPage from '../Pages/Settings'
import InvitationAccepted from '../Pages/Auth/InvitationAccepted'
import ReceivableBillsPage from '../Pages/ReceivableBills'
import ReceivableBillPage from '../Pages/ReceivableBills/Show'
import CashflowPage from '../Pages/Cashflow'
import StatisticsPage from '../Pages/Statistics'
import ImportCharges from '../Pages/Charges'
import { withAdminLayout, withAuthLayout } from '../Layouts/index'

function Navigation() {
  return (
    <Switch>
      <Route exact path="/" component={withAdminLayout(HomePage, false)} />
      <Route exact path="/clients" component={withAdminLayout(ClientsPage)} />
      <Route
        exact
        path={[
          '/clients/:id',
          '/clients/:id/projects',
          '/clients/:id/maintenances',
          '/clients/:id/prepaid_hours',
          '/clients/:id/invoices',
          '/clients/:id/invoice_lines',
          '/clients/:id/documents',
          '/clients/:id/offers',
          '/clients/:id/products',
          '/clients/:id/contacts',
        ]}
        component={withAdminLayout(ClientPage)}
      />
      <Route
        exact
        path="/clients/:clientId/prepaid_hours/:prepaidHourId"
        component={withAdminLayout(PrepaidHourPage)}
      />
      <Route
        exact
        path="/clients/:clientId/maintenances/:maintenanceId"
        component={withAdminLayout(MaintenancePage)}
      />
      <Route
        exact
        path="/suppliers"
        component={withAdminLayout(SuppliersPage)}
      />
      <Route
        exact
        path="/suppliers/:id"
        component={withAdminLayout(SupplierPage)}
      />
      <Route exact path="/users" component={withAdminLayout(UsersPage)} />
      <Route
        exact
        path={[
          '/profile',
          '/profile/personal_info',
          '/profile/connection_info',
          '/profile/salary_slip',
          '/profile/documents',
        ]}
        component={withAdminLayout(ProfilePage, false)}
      />
      <Route
        exact
        path={[
          '/users/:id',
          '/users/:id/personal_info',
          '/users/:id/off_days',
          '/users/:id/refund_requests',
          '/users/:id/salary_slip',
          '/users/:id/documents',
        ]}
        component={withAdminLayout(ShowUserPage)}
      />
      <Route
        exact
        path={[
          '/refund_requests',
          '/refund_requests/all',
          '/refund_requests/mine',
          '/refund_requests/to_review',
        ]}
        component={withAdminLayout(RefundRequestsPage, false)}
      />
      <Route
        exact
        path={[
          '/off-days',
          '/off-days/all',
          '/off-days/mine',
          '/off-days/to_review',
        ]}
        component={withAdminLayout(OffDaysPage, false)}
      />
      <Route exact path="/absences" component={withAdminLayout(AbsencesPage)} />
      <Route
        exact
        path={[
          '/invoices',
          '/invoices/invoices',
          '/invoices/invoice_lines',
          '/invoices/late',
        ]}
        component={withAdminLayout(ReceivableBillsPage)}
      />
      <Route
        exact
        path="/invoices/:id"
        component={withAdminLayout(ReceivableBillPage)}
      />
      <Route exact path="/menu" component={withAdminLayout(Menu, false)} />
      <Route exact path="/leave-requests" component={withAdminLayout(Snake)} />
      <Route
        exact
        path={['/maintenances', '/maintenances/late', '/maintenances/calendar']}
        component={withAdminLayout(MaintenancesPage)}
      />
      <Route
        exact
        path={[
          '/settings',
          '/settings/general',
          '/settings/public_holidays',
          '/settings/services',
          '/settings/costs',
          '/settings/documents',
          '/settings/technologies',
        ]}
        component={withAdminLayout(SettingsPage)}
      />
      <Route exact path="/cashflow" component={withAdminLayout(CashflowPage)} />
      <Route
        exact
        path="/statistics"
        component={withAdminLayout(StatisticsPage)}
      />
      <Route exact path="/charges" component={withAdminLayout(ImportCharges)} />
      <Route
        exact
        path="/auth/sign-in"
        component={withAuthLayout(SignInPage)}
      />
      <Route
        exact
        path="/auth/forgot-password"
        component={withAuthLayout(ForgotPassword)}
      />
      <Route
        exact
        path="/auth/new-password"
        component={withAuthLayout(NewPassword)}
      />
      <Route
        exact
        path="/auth/invitation"
        component={withAuthLayout(InvitationAccepted)}
      />
      <Route exact path="/not-authorized" component={NotAuthorized} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Navigation
