/**
 * The internal imports
 */
import api from '../index'

export default async ({ offDayId, params }) => {
  if (params.status) {
    const response = await api.put(`off_days/${offDayId}`, { off_day: params })
    return response.data
  } else {
    const dataPost = new FormData()

    // Don't ask why. Just don't
    // https://gph.is/g/aj7vKVn
    params.justifications.forEach(justification => {
      dataPost.append('off_day[justifications][]', justification)
    })

    params.justificationsToRemove.forEach(justification => {
      dataPost.append('off_day[justifications_to_remove][]', justification)
    })

    dataPost.append('off_day[imputable]', params.imputable)
    dataPost.append('off_day[reason]', params.reason)
    dataPost.append('off_day[start_date]', params.start_date)
    dataPost.append('off_day[end_date]', params.end_date)

    const response = await api.put(`off_days/${offDayId}`, dataPost)
    return response.data
  }
}
