/**
 * The external imports
 */
import {
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import { GetAllPlanningMaintenancesService } from '../../Services/Maintenance'

export default {
  initialState: {
    getAll: {
      item: [],
      loading: false,
      error: null,
    },
  },
  action: buildAsyncActions(
    'maintenance/getAllPlanning',
    GetAllPlanningMaintenancesService,
  ),
  reducers: buildAsyncReducers({
    itemKey: 'getAllPlanning.item',
    errorKey: 'getAllPlanning.error',
    loadingKey: 'getAllPlanning.loading',
  }),
}
