/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Button,
} from '@mui/material'
import parse from 'html-react-parser'
import clsx from 'clsx'
import { Edit } from '@mui/icons-material'

/**
 * The internal imports
 */
import {
  Datatable,
  Loader,
  Link,
  InterventionHeaderPrepaidHour,
} from '../../Components'
import FetchOnePrepaidHour from '../../Store/PrepaidHour/FetchOne'
import FetchOneClient from '../../Store/Client/FetchOne'
import useLayout from '../../Theme/Layouts'
import GetAllSpentHours from '../../Store/SpentHour/GetAll'
import { openModal } from '../../Utils/Modal'
import useStyles from '../../Theme/Pages/PrepaidHours/Show.style'

const PrepaidHour = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { clientId, prepaidHourId } = useParams()
  const layout = useLayout()
  const classes = useStyles()

  const { data, total } = useSelector(state => state.spentHour.getAll.item)
  const client = useSelector(state => state.client.fetchOne.item)
  const prepaidHour = useSelector(state => state.prepaidHour.fetchOne.item)
  const prepaidHourLoading = useSelector(
    state => state.prepaidHour.fetchOne.loading,
  )
  const updatePrepaidHourLoading = useSelector(
    state => state.prepaidHour.update.loading,
  )
  const createSpentHourLoading = useSelector(
    state => state.spentHour.create.loading,
  )
  const updateSpentHourLoading = useSelector(
    state => state.spentHour.update.loading,
  )

  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({})
  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: ({ id }) =>
        openModal({
          type: 'formSpentHour',
          id,
          options: { clientId, prepaidHourId },
        }),
    },
  ])
  const [excludedColumns] = useState(['id', 'product.id'])

  useEffect(async () => {
    if (clientId && prepaidHourId) {
      await dispatch(
        FetchOnePrepaidHour.action({
          url: `clients/${clientId}/prepaid_hours/${prepaidHourId}`,
        }),
      )
      await dispatch(FetchOneClient.action({ url: `clients/${clientId}` }))
      setLoading(false)
    }
  }, [
    clientId,
    prepaidHourId,
    createSpentHourLoading,
    updateSpentHourLoading,
    updatePrepaidHourLoading,
  ])

  useEffect(async () => {
    if (!createSpentHourLoading && !updateSpentHourLoading) {
      await dispatch(
        GetAllSpentHours.action({
          params: { tableState },
          url: `clients/${clientId}/prepaid_hours/${prepaidHourId}/spent_hours`,
        }),
      )
    }
  }, [tableState, createSpentHourLoading, updateSpentHourLoading])

  if (prepaidHourLoading || loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }
  return (
    <Container maxWidth="xl">
      <div className={clsx(classes.titleWrapper, layout.breadcrumb)}>
        <Link
          to={`/clients/${clientId}`}
          variant="body2"
          className={clsx(layout.breadcrumb, layout.breadcrumbLink)}
        >
          {client.name}
        </Link>
        <div className={layout.breadcrumbSeparator} />
        {t('pages.prepaid_hours.show.prepaid_hours')}
        <div className={layout.breadcrumbSeparator} />
        {prepaidHour.name}
      </div>

      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h6" component="span">
                  {prepaidHour.name}
                </Typography>
              }
            />
            <CardContent>
              <div>
                <Typography color="primary">
                  {t('pages.prepaid_hours.show.price')}
                </Typography>
                {prepaidHour.price} CHF
              </div>
              <div>
                <Typography color="primary">
                  {t('pages.prepaid_hours.show.total_hours')}
                </Typography>
                {prepaidHour.hours - prepaidHour.spent_hours_balance} /
                {prepaidHour.hours}
              </div>
              <div>
                <Typography color="primary">
                  {t('pages.prepaid_hours.show.description')}
                </Typography>
                {prepaidHour.description.body ? (
                  parse(prepaidHour.description.body)
                ) : (
                  <i>{t('pages.prepaid_hours.show.no_description')}</i>
                )}
              </div>
            </CardContent>
          </Card>
          <Button
            onClick={() =>
              openModal({
                type: 'formPrepaidHour',
                id: prepaidHourId,
              })
            }
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            sx={{ mt: 2, width: 1 }}
          >
            {t('actions.update')}
          </Button>
        </Grid>
        <Grid item xs={9}>
          <Datatable
            data={data}
            total={total}
            tableState={tableState}
            setTableState={setTableState}
            source="spent_hours"
            title={
              <InterventionHeaderPrepaidHour
                clientId={clientId}
                prepaidHourId={prepaidHourId}
              />
            }
            menuOptions={menuOptions}
            excludedColumns={excludedColumns}
            elevation={1}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default PrepaidHour
