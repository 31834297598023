import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))
export default useStyles
