/**
 * The internal imports
 */
import { TableColumns } from '../Config/TableColumns'

export default (option, tableMeta, source) => {
  const params = {}
  option.columns.forEach(item => {
    params[item] =
      tableMeta.rowData[
        TableColumns[source].findIndex(column => column.label === item)
      ]
  })
  return params
}
