/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import FetchOne from './FetchOne'
import Create from './Create'
import Update from './Update'

export default buildSlice('service', [Create, Update, FetchOne], {
  fetchOne: { item: {} },
  create: {},
  update: {},
}).reducer
