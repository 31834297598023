/**
 * The external imports
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Typography, Container } from '@mui/material'

/**
 * The internal imports
 */
import {
  EditUsers,
  ConnectionInfo,
  Tabs,
  UserDocuments,
  Salaries,
} from '../../Components'

const Profile = () => {
  const { t } = useTranslation()
  const currentUser = useSelector(state => state.user.item)

  const tabs = [
    {
      label: t('pages.profile.personal_info'),
      url: '/personal_info',
      content: <EditUsers id={currentUser.id} />,
    },
    {
      label: t('pages.profile.connection_info'),
      url: '/connection_info',
      content: <ConnectionInfo />,
    },
    {
      label: t('pages.profile.salary_slip'),
      url: '/salary_slip',

      content: <Salaries employeeId={currentUser.id} />,
    },
    {
      label: t('pages.profile.documents'),
      url: '/documents',
      content: <UserDocuments />,
    },
  ]

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" color="inherit" gutterBottom>
        {currentUser.first_name}{' '}
        {currentUser.middle_name && `(${currentUser.middle_name})`}{' '}
        {currentUser.last_name}
      </Typography>

      <Typography variant="subtitle1" gutterBottom color="primary">
        {t(`user.role_items.${currentUser.role}`)}
      </Typography>

      <Tabs tabs={tabs} baseUrl="/profile" />
    </Container>
  )
}

export default Profile
