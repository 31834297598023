/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import NewSession from './NewSession'
import DestroySession from './DestroySession'
import ForgotPassword from './ForgotPassword'
import UpdatePassword from './UpdatePassword'
import NewPassword from './NewPassword'
import AcceptInvitation from './AcceptInvitation'
import Clear from './Clear'

const sliceInitialState = {
  item: {},
}

export default buildSlice(
  'auth',
  [
    AcceptInvitation,
    NewSession,
    DestroySession,
    ForgotPassword,
    UpdatePassword,
    NewPassword,
    Clear,
  ],
  sliceInitialState,
).reducer
