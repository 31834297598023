/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { Button, Container, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

/**
 * The internal imports
 */
import GetAllClients from '../../Store/Client/GetAll'
import { Datatable } from '../../Components'
import useLayout from '../../Theme/Layouts'
import { openModal } from '../../Utils/Modal'

const Clients = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const layout = useLayout()

  const { data, total } = useSelector(state => state.client.getAll.item)
  const createClientLoading = useSelector(state => state.client.create.loading)
  const updateClientLoading = useSelector(state => state.client.update.loading)
  const destroyClientLoading = useSelector(
    state => state.client.destroy.loading,
  )

  const [tableState, setTableState] = useState({})

  const [menuOptions] = useState([
    {
      label: t('datatables.show'),
      columns: ['id'],
      action: ({ id }) => history.push(`/clients/${id}`),
    },
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: ({ id }) => openModal({ type: 'formClient', id }),
    },
    {
      label: t('datatables.delete'),
      columns: ['id'],
      action: ({ id }) => openModal({ type: 'destroyClient', id }),
    },
  ])

  const [excludedColumns] = useState(['id'])

  useEffect(async () => {
    if (!createClientLoading && !updateClientLoading && !destroyClientLoading) {
      await dispatch(
        GetAllClients.action({ params: { tableState }, url: 'clients' }),
      )
    }
  }, [
    tableState,
    createClientLoading,
    updateClientLoading,
    destroyClientLoading,
  ])

  return (
    <Container maxWidth="xl">
      <div className={clsx(layout.row, layout.flex)}>
        <Typography variant="h4" color="inherit" gutterBottom>
          {t('pages.clients.title')}
        </Typography>

        <Button
          onClick={() => openModal({ type: 'formClient' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        data={data}
        total={total}
        rowLink={({ id }) => `/clients/${id}`}
        tableState={tableState}
        setTableState={setTableState}
        source="clients"
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
      />
    </Container>
  )
}

export default Clients
