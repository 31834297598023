import format from 'date-fns/format'
import api from '../index'

export default async ({ date }) => {
  const response = await api.get('working_days/get_week', {
    params: {
      date: format(date, 'yyyy-MM-dd'),
    },
  })

  return response.data
}
