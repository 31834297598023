/**
 * The external imports
 */
import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { Add } from '@mui/icons-material'

/**
 * The internal imports
 */
import useLayout from '../../Theme/Layouts'
import { openModal } from '../../Utils/Modal'

const InterventionHeader = ({ clientId, prepaidHourId }) => {
  const { t } = useTranslation()
  const layout = useLayout()

  return (
    <div>
      <Typography variant="h6" component="span">
        {t('components.intervention_header.interventions')}
      </Typography>
      <Button
        onClick={() =>
          openModal({
            type: 'formSpentHour',
            options: { clientId, prepaidHourId },
          })
        }
        variant="contained"
        color="primary"
        startIcon={<Add />}
        className={layout.ml}
      >
        {t('actions.add')}
      </Button>
    </div>
  )
}

export default InterventionHeader
