/**
 * The external imports
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import {
  CardContent,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material'

/**
 * The internal imports
 */
import { TextField } from '../../Components/index'
import useStyles from '../../Theme/Components/Charges/Form.style'

const FormPayableBill = ({
  reference_text,
  index,
  kindOfCosts,
  suppliers,
  errors,
  register,
  control,
  watch,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const watchIgnoreLine = watch(`items[${index}].ignore_line`)

  return (
    <div>
      <CardContent className={classes.referenceText}>
        {reference_text}
      </CardContent>
      <CardContent className={classes.lineWrapper}>
        <Controller
          name={`items[${index}].sending_date`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              inputRef={ref}
              className={classes.width10}
              required
              label={t('invoice_line.sending_date')}
              fullWidth={false}
              disabled={true}
              error={errors && errors.sending_date}
              helperText={errors && errors.sending_date}
              {...rest}
            />
          )}
        />

        <FormControl
          variant="filled"
          className={classes.width15}
          error={errors && errors.supplier}
        >
          <InputLabel
            required={!watchIgnoreLine}
            id={`supplier_id_label_${index}`}
          >
            {t('invoice_line.supplier_id')}
          </InputLabel>
          <Controller
            name={`items[${index}].supplier_id`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                labelId={`supplier_id_label_${index}`}
                label={t('invoice_line.supplier_id')}
                required={!watchIgnoreLine}
                {...field}
              >
                {suppliers.map(supplier => (
                  <MenuItem key={`supplier-${supplier.id}`} value={supplier.id}>
                    {supplier.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors && errors.supplier && (
            <FormHelperText>{errors.supplier}</FormHelperText>
          )}
        </FormControl>

        <Controller
          name={`items[${index}].wording`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              inputRef={ref}
              className={classes.width20}
              label={t('invoice_line.wording')}
              fullWidth={false}
              error={errors && errors['invoice_lines.wording']}
              helperText={errors && errors['invoice_lines.wording']}
              required={!watchIgnoreLine}
              {...rest}
            />
          )}
        />

        <FormControl
          variant="filled"
          className={classes.width15}
          error={errors && errors['invoice_lines.kind_of_cost']}
        >
          <InputLabel
            required={!watchIgnoreLine}
            id={`kind_of_cost_id_label_${index}`}
          >
            {t('invoice_line.kind_of_cost_id')}
          </InputLabel>
          <Controller
            name={`items[${index}].kind_of_cost_id`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                labelId={`kind_of_cost_id_label_${index}`}
                label={t('invoice_line.kind_of_cost_id')}
                required={!watchIgnoreLine}
                {...field}
              >
                {kindOfCosts.map(kindOfCost => (
                  <MenuItem
                    key={`kind-of-cost-${kindOfCost.id}`}
                    value={kindOfCost.id}
                  >
                    {kindOfCost.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors && errors['invoice_lines.kind_of_cost'] && (
            <FormHelperText>
              {errors['invoice_lines.kind_of_cost']}
            </FormHelperText>
          )}
        </FormControl>

        <Controller
          name={`items[${index}].amount`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              inputRef={ref}
              className={classes.width10}
              label={t('invoice_line.amount')}
              fullWidth={false}
              error={errors && errors['invoice_lines.amount']}
              helperText={
                errors &&
                errors['invoice_lines.amount'] &&
                errors['invoice_lines.amount'][0]
              }
              required={!watchIgnoreLine}
              {...rest}
            />
          )}
        />

        <Controller
          name={`items[${index}].web_activity_rate`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              inputRef={ref}
              className={classes.width10}
              label={t('invoice_line.web_activity_rate')}
              fullWidth={false}
              error={errors && errors['invoice_lines.web_activity_rate']}
              helperText={
                errors &&
                errors['invoice_lines.web_activity_rate'] &&
                errors['invoice_lines.web_activity_rate'][0]
              }
              required={!watchIgnoreLine}
              {...rest}
            />
          )}
        />

        <Controller
          name={`items[${index}].dev_activity_rate`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              inputRef={ref}
              className={classes.width10}
              label={t('invoice_line.dev_activity_rate')}
              fullWidth={false}
              error={errors && errors['invoice_lines.dev_activity_rate']}
              helperText={
                errors &&
                errors['invoice_lines.dev_activity_rate'] &&
                errors['invoice_lines.dev_activity_rate'][0]
              }
              required={!watchIgnoreLine}
              {...rest}
            />
          )}
        />

        <FormControlLabel
          control={
            <Controller
              name={`items[${index}].ignore_line`}
              control={control}
              render={({ field: { value, ...rest } }) => (
                <Checkbox checked={value || false} {...rest} />
              )}
            />
          }
          label={t('actions.ignore')}
          labelPlacement="top"
        />
      </CardContent>
    </div>
  )
}

export default FormPayableBill
