/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { Button, Container, IconButton, Typography } from '@mui/material'
import MUIDataTable, { debounceSearchRender } from 'mui-datatables'
import { AddCircleOutline, Build, FlightTakeoff } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import addMonths from 'date-fns/addMonths'
import getYear from 'date-fns/getYear'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import endOfMonth from 'date-fns/endOfMonth'
import clsx from 'clsx'

/**
 * The internal imports
 */
import { Loader, LordIcon } from '../../Components'
import { Config } from '../../Config'
import GetAllPlanningMaintenances from '../../Store/Maintenance/GetAllPlanning'
import useStyles from '../../Theme/Components/Datatable'
import { capitalize } from '../../Utils/String'
import { TableColumns } from '../../Config/TableColumns'
import { customFormat, isSameMonthAndYear } from '../../Utils/Date'
import GetFilterableFieldsService from '../../Services/GetFilterableFields'
import i18n from '../../Translations'
import { openModal } from '../../Utils/Modal'

const MaintenanceCalendar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const { data, total } = useSelector(
    state => state.maintenance.getAllPlanning.item,
  )
  const maintenancesLoading = useSelector(
    state => state.maintenance.getAllPlanning.loading,
  )
  const createInterventionLoading = useSelector(
    state => state.intervention.create.loading,
  )
  const updateInterventionLoading = useSelector(
    state => state.intervention.update.loading,
  )

  const [filterableFields, setFilterableFields] = useState([])
  const [year, setYear] = useState(getYear(new Date()))
  const [columns, setColumns] = useState([])
  const [tableState, setTableState] = useState({
    page: 0,
    rowsPerPage: Config.ROWS_PER_PAGE,
    filter: {},
    search: '',
  })
  const [excludedColumns] = useState([
    'id',
    'start_date',
    'is_late?',
    'product.client.name',
  ])

  useEffect(async () => {
    setFilterableFields(await GetFilterableFieldsService('maintenances'))
  }, [])

  useEffect(async () => {
    if (!createInterventionLoading && !updateInterventionLoading) {
      await dispatch(GetAllPlanningMaintenances.action({ params: tableState }))
    }
  }, [tableState, createInterventionLoading, updateInterventionLoading])

  useEffect(async () => {
    if (data && !maintenancesLoading) {
      const baseColumns = TableColumns.maintenance_planning.map(column => {
        const isFilterable = Object.keys(filterableFields).includes(
          column.label,
        )
        return {
          name: column.label,
          label: i18n.t(
            `datatables.tables.maintenance_planning.${column.label}`,
          ),
          options: {
            filter: isFilterable,
            sort: false,
            display: excludedColumns.includes(column.label) ? 'excluded' : true,
            filterList: tableState.filter[column.label]?.map(
              id =>
                filterableFields[column.label].find(field => field.id === id)
                  .name,
            ),
            filterOptions: {
              names: isFilterable
                ? filterableFields[column.label].map(tech => tech.name)
                : null,
            },
            customBodyRender: (value, tableMeta) => {
              const cellValue = tableMeta.rowData[tableMeta.columnIndex]
              switch (column.type) {
                case 'list':
                  return cellValue.map(val => val.name).join(', ')
                default:
                  const isLate = tableMeta.rowData[4]
                  const clientName = tableMeta.rowData[2]
                  return (
                    <div>
                      <div className={classes.client_name}>{clientName}</div>
                      <span
                        className={clsx([
                          classes.project_name,
                          isLate && classes.late_maintenance,
                        ])}
                      >
                        {cellValue}
                      </span>
                    </div>
                  )
              }
            },
          },
        }
      })

      Array.from(Array(12).keys()).forEach(month => {
        const date = new Date(year, month, 1)
        baseColumns.push({
          name: format(date, 'yyyy-MMM-dd'),
          label: capitalize(customFormat(date, 'MMM')),
          options: {
            sort: false,
            filter: false,
            customHeadLabelRender: ({ name, label }) => {
              const isThisMonth = isSameMonthAndYear(new Date(), new Date(name))
              return (
                <div className={isThisMonth ? classes.today : null}>
                  {label}
                </div>
              )
            },
            customBodyRender: (value, { rowData }) => {
              const maintenanceId = rowData[0]
              const maintenance = data.find(m => m.id === maintenanceId)
              const start_date =
                rowData[
                  TableColumns.maintenance_planning.findIndex(
                    col => col.label === 'start_date',
                  )
                ]

              if (maintenance) {
                const interventions = maintenance.interventions
                const lastIntervention =
                  interventions.length > 0
                    ? new Date(interventions[interventions.length - 1].date)
                    : new Date(start_date)
                const nextIntervention = addMonths(lastIntervention, 4)

                const foundIntervention = interventions.find(intervention =>
                  isSameMonthAndYear(new Date(intervention.date), date),
                )

                if (isSameMonthAndYear(new Date(start_date), date)) {
                  return <FlightTakeoff />
                } else if (foundIntervention) {
                  const { id } = foundIntervention
                  return (
                    <IconButton
                      onClick={() =>
                        openModal({
                          id,
                          type: 'formIntervention',
                          options: { maintenanceId },
                        })
                      }
                      size="large"
                    >
                      <Build className={classes.planned} />
                    </IconButton>
                  )
                } else if (isAfter(date, new Date(start_date))) {
                  const { technologies } = maintenance
                  const { next, missed, available } = classes
                  const isNext = isSameMonthAndYear(nextIntervention, date)
                  const isMissed = isAfter(
                    new Date(),
                    endOfMonth(nextIntervention),
                  )

                  return (
                    <IconButton
                      onClick={() =>
                        openModal({
                          type: 'formIntervention',
                          options: { maintenanceId, date, technologies },
                        })
                      }
                      className={
                        isNext ? (isMissed ? missed : next) : available
                      }
                      size="large"
                    >
                      <AddCircleOutline />
                    </IconButton>
                  )
                }
              }
            },
          },
        })
      })
      setColumns(baseColumns)
    }
  }, [data, year, filterableFields, tableState])

  /**
   * Renders the correct icon for the table key
   * @param keyItem
   * @returns {JSX.Element|null}
   */
  const renderKeyIcon = keyItem => {
    switch (keyItem) {
      case 'start_date':
        return <FlightTakeoff />
      case 'missed_maintenances':
        return (
          <IconButton
            className={`${classes.missed} ${classes.disabled}`}
            size="large"
          >
            <AddCircleOutline />
          </IconButton>
        )
      case 'next_maintenance':
        return (
          <IconButton
            className={`${classes.next} ${classes.disabled}`}
            size="large"
          >
            <AddCircleOutline />
          </IconButton>
        )
      case 'planned_intervention':
        return (
          <IconButton disabled size="large">
            <Build className={classes.planned} />
          </IconButton>
        )
      default:
        return null
    }
  }

  /**
   * Changes the local tableState values
   * @param field
   * @param value
   */
  const handleTableStateChange = (field, value) => {
    setTableState(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  /**
   * Updates the local tableState with new rowsPerPage
   * @param numRows
   */
  const handleChangeRowsPerPage = numRows => {
    handleTableStateChange('rowsPerPage', numRows)
    if (numRows >= data.length) {
      handleTableStateChange('page', 0)
    }
  }

  /**
   * Transforms and updates the filters in the tableState
   * @param filterList
   */
  const updateFilters = filterList => {
    const filterObject = {}
    filterList.forEach((filter, index) => {
      if (columns[index].options.filter && filter.length > 0) {
        const columnName = columns[index].name
        filterObject[columnName] = filter.map(
          item =>
            filterableFields[columnName].find(field => field.name === item).id,
        )
      }
    })
    handleTableStateChange('filter', filterObject)
  }

  const options = {
    serverSide: true,
    confirmFilters: true,
    filter: true,
    filterType: 'checkbox',
    elevation: 1,
    enableNestedDataAccess: '.',
    page: tableState.page,
    textLabels: Config.DATATABLE_TRANSLATIONS,
    count: total,
    rowsPerPage: tableState.rowsPerPage,
    rowsPerPageOptions: [1, 5, 15, 100, 1000],
    onChangeRowsPerPage: numRows => handleChangeRowsPerPage(numRows),
    selectableRowsHideCheckboxes: true,
    onChangePage: currentPage => handleTableStateChange('page', currentPage),
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <div className={classes.applyFiltersButton}>
        <Button
          variant="contained"
          onClick={() => updateFilters(applyNewFilters())}
        >
          {t('datatables.apply')}
        </Button>
      </div>
    ),
    onFilterChipClose: (index, removedFilter, filterList) => {
      updateFilters(filterList)
    },
    customSearchRender: debounceSearchRender(500),
    onSearchChange: searchText => handleTableStateChange('search', searchText),
  }

  return !data ? (
    <Loader />
  ) : (
    <Container maxWidth="xl">
      <div className={classes.dateBox}>
        <IconButton onClick={() => setYear(year - 1)} size="large">
          <LordIcon icon="left" scale="40" stroke="160" />
        </IconButton>
        <Typography variant="h5">{year}</Typography>
        <IconButton onClick={() => setYear(year + 1)} size="large">
          <LordIcon icon="right" scale="40" stroke="160" />
        </IconButton>
      </div>

      <div className={classes.maintenancesWrapper}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </div>

      <div className={classes.keyWrapper}>
        {Config.MAINTENANCE_KEYS.map(keyItem => (
          <div key={`keyItem-${keyItem}`} className={classes.key}>
            <div className={classes.box}>{renderKeyIcon(keyItem)}</div>
            <div>{t(`pages.maintenance_planning.${keyItem}`)}</div>
          </div>
        ))}
      </div>
    </Container>
  )
}

export default MaintenanceCalendar
