/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => {
  return {
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    inlineFlex: { display: 'inline-flex' },
    spaceBetween: { display: 'flex', justifyContent: 'space-between' },
    grow: { display: 'flex', justifyContent: 'flex-end', flexGrow: 1 },
    flexBaseline: {
      display: 'flex',
      alignItems: 'baseline',
    },
    flex: {
      flex: 1,
    },
    mb: {
      marginBottom: theme.spacing(4),
    },
    ml: {
      marginLeft: theme.spacing(4),
    },
    breadcrumb: {
      ...theme.typography.h4,
      display: 'flex',
      alignItems: 'center',
    },
    breadcrumbLink: {
      color: theme.palette.primary.main,
    },
    breadcrumbSeparator: {
      background: theme.palette.primary.main,
      height: 8,
      width: 8,
      borderRadius: '50%',
      display: 'inline-flex',
      margin: theme.spacing(0, 2),
    },
    card: {
      padding: '30px 15px 30px 15px',
      marginBottom: 10,
      boxShadow: theme.shadows[5],
      backgroundColor: theme.palette.background.paper,
      width: '30%',
    },
  }
})
export default useStyles
