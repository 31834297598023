/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import getYear from 'date-fns/getYear'
import endOfYear from 'date-fns/endOfYear'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Select,
  MenuItem,
  Input,
  Button,
  TextField,
} from '@mui/material'
import DatePicker from '@mui/lab/DatePicker'

/**
 * The internal imports
 */
import GetCashflow from '../../Store/Cashflow/GetCashflow'
import { Loader, CashflowRow } from '../../Components'
import { shortMonthAndYear } from '../../Utils/Date'
import useStyles from '../../Theme/Components/Cashflow/Row.style'

const Cashflow = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [sector, setSector] = useState('all')
  const [year, setYear] = useState(new Date())
  const [interval, setInterval] = useState(3)

  const userRole = useSelector(state => state.user.item.role)
  const cashflowData = useSelector(state => state.cashflow.item)

  useEffect(() => {
    getCashflow()
  }, [year])

  /**
   * Gets the cashflow data from the server
   */
  const getCashflow = async () => {
    if (!loading) {
      setLoading(true)
    }
    if (userRole === 'admin') {
      await dispatch(GetCashflow.action({ sector, interval, startDate: year }))
    }
    setLoading(false)
  }

  /**
   * Updates the interval state depending on value
   * @param {*} value string
   */
  const updateInterval = value => {
    if (value !== '') {
      setInterval(Number(value))
    } else {
      setInterval('')
    }
  }

  /**
   * Validates the interval field on blur
   * @param {*} value string
   */
  const handleBlur = value => {
    if (value === '' || Number(value) < 0) {
      setInterval(1)
    } else {
      setInterval(value)
    }
  }

  return (
    <Container maxWidth="xl">
      <div className={classes.optionsWrapper}>
        <Typography>{t('pages.cashflow.options_start')}</Typography>
        <Input
          type="number"
          variant="filled"
          value={interval}
          onChange={event => updateInterval(event.target.value)}
          onBlur={event => handleBlur(event.target.value)}
          classes={{ root: classes.intervalField }}
          inputProps={{ min: '1', step: '1' }}
          disabled={loading}
        />
        <Typography>{t('pages.cashflow.options_end')}</Typography>
        <Select
          value={sector}
          onChange={event => setSector(event.target.value)}
          className={classes.sectorSelect}
          disabled={loading}
        >
          <MenuItem value="all">{t('pages.cashflow.all')}</MenuItem>
          <MenuItem value="web">{t('pages.cashflow.web')}</MenuItem>
          <MenuItem value="dev">{t('pages.cashflow.dev')}</MenuItem>
        </Select>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={getCashflow}
          className={classes.submitButton}
        >
          {t('actions.validate')}
        </Button>
        <div className={classes.flexSeparator} />
        <DatePicker
          views={['year']}
          label={t('pages.cashflow.choose_year')}
          value={year}
          onChange={newValue => {
            setInterval(11)
            setYear(endOfYear(newValue))
          }}
          renderInput={params => (
            <TextField
              className={classes.yearPicker}
              helperText={null}
              {...params}
            />
          )}
        />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell align="right" className={classes.tableHeader}>
                  {getYear(new Date())}
                </TableCell>
                <TableCell align="right" className={classes.tableHeader}>
                  {t('pages.cashflow.interval')}
                </TableCell>
                {Object.keys(cashflowData.totals.monthly).map(month => (
                  <TableCell
                    key={`cashflow-${month}`}
                    align="right"
                    className={classes.tableHeader}
                  >
                    {shortMonthAndYear(new Date(month))}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <CashflowRow name="incomes" row={cashflowData.incomes} />
              <CashflowRow name="charges" row={cashflowData.charges} />
              <CashflowRow name="totals" row={cashflowData.totals} />
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  )
}

export default Cashflow
