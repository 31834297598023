/**
 * The external imports
 */
import { createAction } from '@reduxjs/toolkit'

export default {
  initialState: {},
  action: createAction('theme/changeTheme'),
  reducers(state, { payload }) {
    state.darkMode = payload.darkMode
  },
}
