/**
 * The internal imports
 */
import api from '../index'

export default async ({ clientId, params }) => {
  const dataPost = new FormData()

  // Don't ask why. Just don't
  // https://gph.is/g/aj7vKVn
  params.documents.forEach(document => {
    dataPost.append('offer[documents][]', document)
  })

  dataPost.append('offer[status]', params.status)
  dataPost.append('offer[description]', params.description)
  dataPost.append('offer[dev_amount]', params.dev_amount)
  dataPost.append('offer[maintenance_amount]', params.maintenance_amount)

  const response = await api.post(`clients/${clientId}/offers`, dataPost)
  return response.data
}
