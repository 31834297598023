/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    height: '98vh',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontSize: 140,
    lineHeight: 1,
  },
  subtitle: {
    color: theme.palette.primary.contrastText,
    fontSize: 30,
  },
  icon: {
    height: '50%',
    margin: theme.spacing(5),
    fill: 'white',
  },
  link: {
    fontSize: 20,
    margin: theme.spacing(3),
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
}))

export default useStyles
