/**
 * The internal imports
 */
import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'

/**
 * The external imports
 */
import useStyles from '../Theme/Layouts/Auth'
import { Copyright } from '../Components'

const SignIn = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.rootLogin}>
      <CssBaseline />

      <div className={classes.contentBackground} />
      <main className={classes.contentAuth}>
        <div className={classes.headerLogin}>
          <img
            className={classes.logo}
            src={process.env.PUBLIC_URL + '/logo/logo-black-sentence.svg'}
          />
        </div>
        <div className={classes.auth}>{children}</div>
        <footer className={classes.footer}>
          <Container maxWidth="sm">
            <Copyright />
          </Container>
        </footer>
      </main>
    </div>
  )
}

export default SignIn
