/**
 * The external imports
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import parse from 'html-react-parser'

/**
 * The internal imports
 */
import { capitalize } from '../../Utils/String'
import GetAllMenu from '../../Store/Menu/GetAll'
import Loader from '../../Components/Loader'
import useStyles from '../../Theme/Pages/Menu/index.style'

const Menu = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const menuLoading = useSelector(state => state.menu.getAll.loading)
  const menus = useSelector(state => state.menu.getAll.item)
  const menuError = useSelector(state => state.menu.getAll.error)

  useEffect(async () => {
    await dispatch(GetAllMenu.action({ url: 'menus' }))
  }, [])

  useEffect(() => {
    menuError && enqueueSnackbar(menuError.message, { variant: 'error' })
  }, [menuError])

  if (menuLoading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  const currentDay = new Date().getDay()

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" color="inherit" guttersBottom paragraph>
        {t('pages.menu.title')}
      </Typography>
      {Object.keys(menus).map((day, index) => (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          spacing={2}
          md={10}
        >
          <Grid item xs={4}>
            <Typography
              className={index + 1 === currentDay ? classes.today : ''}
              variant="h5"
              color="inherit"
              gutterTop
            >
              {capitalize(day)}
            </Typography>
          </Grid>
          {menus[day].map(menu => (
            <Grid alignContent="center" item xs={4}>
              <Card>
                <CardMedia
                  className={classes.media}
                  image={
                    process.env.PUBLIC_URL +
                    `/foods/${Math.floor(Math.random() * 21)}.jpg`
                  }
                />
                <CardContent className={classes.content}>
                  <Typography variant="body1" component="p">
                    {menu.map((meal, mealIndex) => (
                      <div>
                        {(mealIndex === 0 || mealIndex === 1) && parse('<p>')}
                        {parse(meal)}
                        {(mealIndex === 0 || mealIndex === 1) && parse('</p>')}
                      </div>
                    ))}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ))}
    </Container>
  )
}

export default Menu
