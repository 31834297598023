/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import clsx from 'clsx'

/**
 * The internal imports
 */
import GetAllPublicHolidays from '../../Store/PublicHoliday/GetAll'
import { Datatable } from '../index'
import useLayout from '../../Theme/Layouts'
import { openModal } from '../../Utils/Modal'

const SettingsPublicHolidays = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const layout = useLayout()

  const { data, total } = useSelector(state => state.publicHoliday.getAll.item)
  const publicHolidaysLoading = useSelector(
    state => state.publicHoliday.getAll.loading,
  )
  const createPublicHolidayLoading = useSelector(
    state => state.publicHoliday.create.loading,
  )
  const updatePublicHolidayLoading = useSelector(
    state => state.publicHoliday.update.loading,
  )

  const [tableState, setTableState] = useState({})
  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: params => openModal({ type: 'formPublicHoliday', id: params.id }),
    },
  ])
  const [excludedColumns] = useState(['id'])

  useEffect(async () => {
    if (!createPublicHolidayLoading && !updatePublicHolidayLoading) {
      await dispatch(
        GetAllPublicHolidays.action({
          params: { tableState },
          url: 'public_holidays',
        }),
      )
    }
  }, [tableState, createPublicHolidayLoading, updatePublicHolidayLoading])

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={() => openModal({ type: 'formPublicHoliday' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        data={data}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
        source="public_holiday"
        loading={publicHolidaysLoading}
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
      />
    </div>
  )
}

export default SettingsPublicHolidays
