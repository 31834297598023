/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  edit: {
    color: theme.palette.secondary.light,
    margin: theme.spacing(0, 0, 0, 3),
    cursor: 'pointer',
  },
  infoWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 0),
    margin: theme.spacing(1, 0),
  },
  infoItem: {
    marginRight: theme.spacing(10),
  },
  infoTitle: {
    marginBottom: 10,
    fontSize: 24,
  },
  description: {
    whiteSpace: 'pre-line',
  },
}))

export default useStyles
