/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import getDay from 'date-fns/getDay'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import isWeekend from 'date-fns/isWeekend'
import isEqual from 'date-fns/isEqual'
import startOfDay from 'date-fns/startOfDay'
import {
  IconButton,
  Typography,
  Container,
  Table,
  TableContainer,
  Paper,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import { dateIsBetween } from '../../Utils/Date'
import useStyles from '../../Theme/Pages/Absences/index.style'
import GetMonthlyService from '../../Services/GetMonthly'
import { Config } from '../../Config'
import FetchAllService from '../../Services/FetchAll'
import {
  YearMonthPicker,
  TableHead,
  TableBody,
  Loader,
  LordIcon,
} from '../../Components'

const Absences = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [employeeList, setEmployeeList] = useState([])
  const [columns, setColumns] = useState([])
  const [currentDate, setCurrentDate] = useState({
    day: getDay(new Date()),
    month: getMonth(new Date()),
    year: getYear(new Date()),
  })
  const [publicHolidays, setPublicHolidays] = useState([])
  const [offDays, setOffDays] = useState([])

  useEffect(async () => {
    const response = await FetchAllService({ url: 'users?approved=true' })
    setEmployeeList(response)
  }, [])

  useEffect(async () => {
    if (!loading) {
      setLoading(true)
    }
    const { month, year } = currentDate
    const newDate = new Date(year, month, 1)
    const newColumns = eachDayOfInterval({
      start: newDate,
      end: lastDayOfMonth(newDate),
    })
    setColumns(newColumns)

    const holidaysResponse = await GetMonthlyService({
      url: 'public_holidays',
      date: newDate,
    })
    setPublicHolidays(holidaysResponse)
    const offDaysResponse = await GetMonthlyService({
      url: 'off_days',
      date: newDate,
    })
    setOffDays(offDaysResponse)
    setLoading(false)
  }, [currentDate])

  /**
   * Checks if date is in the public holiday list
   * @param date
   * @returns {boolean}
   */
  const isPublicHoliday = date => {
    return publicHolidays.some(publicHoliday =>
      isEqual(startOfDay(new Date(publicHoliday.date)), date),
    )
  }

  /**
   * Updates the current month and year in the local state
   * @param direction
   */
  const updateMonth = direction => {
    const { year, month, day } = currentDate
    const newMonth = month + direction
    if (newMonth > 11) {
      setCurrentDate({ day, month: 0, year: year + 1 })
    } else if (newMonth < 0) {
      setCurrentDate({ day, month: 11, year: year - 1 })
    } else {
      setCurrentDate({ day, month: newMonth, year: year })
    }
  }

  /**
   * Finds the offDay object
   * @param date
   * @param employeeId
   * @returns {*}
   */
  const findOffDay = (date, employeeId) => {
    return offDays.find(od => {
      const start_date = new Date(od.start_date)
      const end_date = new Date(od.end_date)
      return (
        od.user_id === employeeId && dateIsBetween(date, start_date, end_date)
      )
    })
  }

  /**
   * Defines the status of the table cell
   * @param date
   * @param employeeId
   * @returns {string}
   */
  const status = (date, employeeId = null) => {
    if (isWeekend(date)) {
      return 'weekend'
    } else if (isPublicHoliday(date)) {
      return 'publicHoliday'
    } else {
      const offDay = findOffDay(date, employeeId)
      if (offDay) {
        return offDay.status === 'pending' ? 'pending' : offDay.imputable
      }
      return 'default'
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" color="inherit" gutterBottom>
        {t('pages.absences.title')}
      </Typography>

      <div className={classes.dateBox}>
        <IconButton onClick={() => updateMonth(-1)} size="large">
          <LordIcon icon="left" scale="40" stroke="160" />
        </IconButton>
        <YearMonthPicker
          selectedDate={currentDate}
          setSelectedDate={setCurrentDate}
        />
        <IconButton onClick={() => updateMonth(1)} size="large">
          <LordIcon icon="right" scale="40" stroke="160" />
        </IconButton>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead columns={columns} status={status} />
          <TableBody
            columns={columns}
            status={status}
            employeeList={employeeList}
            isPublicHoliday={isPublicHoliday}
            findOffDay={findOffDay}
            offDays={offDays}
          />
        </Table>
      </TableContainer>

      <div className={classes.keyWrapper}>
        {Config.ABSENCE_TYPES.map(absence => (
          <div key={`absence-key-${absence}`} className={classes.key}>
            <div className={`${classes.box} ${classes[absence]}`} />
            <div>{t(`pages.absences.type.${absence}`)}</div>
          </div>
        ))}
        <div key="absence-key-today" className={classes.key}>
          <div className={classes.todayBox} />
          <div>{t('pages.absences.type.today')}</div>
        </div>
      </div>
    </Container>
  )
}

export default Absences
