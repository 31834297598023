/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import DatePicker from '@mui/lab/DatePicker'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import UpdatePayableBill from '../../Store/PayableBill/Update'
import FetchOnePayableBill from '../../Store/PayableBill/FetchOne'
import { Loader, Select, TextField } from '../../Components/index'
import InvoiceLineForm from '../ReceivableBill/InvoiceLineForm'
import useForms from '../../Theme/Components/Forms'
import FetchAllService from '../../Services/FetchAll'

const EditPayableBill = ({ id }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const settings = useSelector(state => state.settings.latest.item)

  const [loading, setLoading] = useState(true)
  const [suppliers, setSuppliers] = useState([])
  const [supplierId, setSupplierId] = useState(null)
  const [invoiceLines, setInvoiceLines] = useState([])
  const [selectedInvoiceLines, setSelectedInvoiceLines] = useState([])
  const [payableBill, setPayableBill] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(async () => {
    const suppliersResponse = await FetchAllService({
      url: 'suppliers',
    })

    setSuppliers(suppliersResponse)
    const payableBillResponse = await dispatch(
      FetchOnePayableBill.action({
        url: `payable_bills/${id}`,
      }),
    )
    if (isFulfilled(payableBillResponse)) {
      setPayableBill(payableBillResponse.payload)
      setSupplierId(payableBillResponse.payload.supplier_id)
      setSelectedInvoiceLines(payableBillResponse.payload.invoice_lines)
    }
    setLoading(false)
  }, [])

  useEffect(async () => {
    if (supplierId) {
      const invoiceLinesResponse = await FetchAllService({
        url: `suppliers/${supplierId}/invoice_lines/by_supplier`,
      })
      setInvoiceLines(invoiceLinesResponse)
    }
  }, [supplierId])

  /**
   * Handles validation and sends updated payable bill form to the db
   */
  const onSubmit = async data => {
    const params = {
      ...data,
      invoice_lines_ids: selectedInvoiceLines.map(
        invoiceLine => invoiceLine.id,
      ),
    }

    const updatePayableBillResponse = await dispatch(
      UpdatePayableBill.action({ url: `payable_bills/${id}`, params }),
    )
    if (isFulfilled(updatePayableBillResponse)) {
      enqueueSnackbar(t('notifications.receivable_bill_updated'), {
        variant: 'success',
      })
      dispatch(ToggleVisibility.action())
    } else {
      setErrors(updatePayableBillResponse.payload.errors)
    }
  }

  /**
   * Adds selected invoice line to SelectedInvoiceLines
   * @param {*} e : event
   */
  const handleInvoiceLine = async e => {
    setSelectedInvoiceLines([...selectedInvoiceLines, e.target.value])

    setInvoiceLines(
      invoiceLines
        .filter(invoiceLine => invoiceLine.id !== e.target.value.id)
        .sort((a, b) => a.id - b.id),
    )
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.payable_bill.edit.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="supplier_id"
            control={control}
            defaultValue={payableBill.supplier_id || ''}
            render={({ field }) => (
              <Select
                label={t('payable_bill.supplier_id')}
                name="supplier_id"
                id="supplier_id"
                error={errors.supplier_id?.length > 0}
                helperText={errors.supplier_id && errors.supplier_id[0]}
                onChange={e => {
                  setSupplierId(e.target.value)
                  field.onChange(e)
                }}
                {...field}
              >
                {suppliers.map(supplier => (
                  <MenuItem key={`supplier-${supplier.id}`} value={supplier.id}>
                    {supplier.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Controller
                name="sending_date"
                control={control}
                defaultValue={payableBill.sending_date || new Date()}
                render={({ field }) => (
                  <DatePicker
                    mask="__.__.____"
                    label={t('receivable_bill.sending_date')}
                    renderInput={params => <TextField {...params} />}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="vat_rate"
                control={control}
                defaultValue={payableBill.vat_rate || settings.vat}
                render={({ field }) => (
                  <TextField
                    required
                    id="vat_rate"
                    label={t('receivable_bill.vat_rate')}
                    name="vat_rate"
                    error={errors.vat_rate?.length > 0}
                    helperText={errors.vat_rate && errors.vat_rate[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="payment_deadline_days"
                control={control}
                defaultValue={
                  payableBill.payment_deadline_days || settings.payment_days
                }
                render={({ field }) => (
                  <TextField
                    required
                    id="payment_deadline_days"
                    label={t('receivable_bill.payment_deadline_days')}
                    name="payment_deadline_days"
                    error={errors.name?.length > 0}
                    helperText={
                      errors.payment_deadline_days &&
                      errors.payment_deadline_days[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Typography component="h4" variant="h6">
            {t('receivable_bill.invoice_lines')}
          </Typography>
          <InvoiceLineForm
            setInvoiceLines={setInvoiceLines}
            invoiceLines={invoiceLines}
            onChange={handleInvoiceLine}
            setSelectedInvoiceLines={setSelectedInvoiceLines}
            selectedInvoiceLines={selectedInvoiceLines}
            errors={errors}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default EditPayableBill
