/**
 * The external imports
 */
import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import frLocale from 'date-fns/locale/fr'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

/**
 * The internal imports
 */
import Navigation from '../Navigation'
import { Modal } from '../Components'
import CommonTheme from './Palettes/'
import { ScrollToTop } from '../Components'

const Theme = () => {
  const darkMode = useSelector(state => state.theme.darkMode)
  const selectedMode = darkMode ? 'dark' : 'light'
  const theme = CommonTheme(selectedMode)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
          <ScrollToTop />
          <Navigation />
          <Modal />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
export default Theme
