/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateKindOfDocument from '../../Store/KindOfDocument/Create'
import UpdateKindOfDocument from '../../Store/KindOfDocument/Update'
import { Loader, TextField } from '../../Components/index'
import FetchOneKindOfDocument from '../../Store/KindOfDocument/FetchOne'
import useForms from '../../Theme/Components/Forms'

const KindOfDocumentForm = ({ id }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [kindOfDocument, setKindOfDocument] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(async () => {
    if (id) {
      const kindOfDocumentResponse = await dispatch(
        FetchOneKindOfDocument.action({ url: `kind_of_documents/${id}` }),
      )
      if (isFulfilled(kindOfDocumentResponse)) {
        setKindOfDocument(kindOfDocumentResponse.payload)
      }
    } else {
      setKindOfDocument({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new kind of cost form to the db
   */
  const onSubmit = async data => {
    if (id) {
      const updateKindOfDocumentResponse = await dispatch(
        UpdateKindOfDocument.action({
          url: `kind_of_documents/${id}`,
          params: { kind_of_document: data },
        }),
      )
      if (isFulfilled(updateKindOfDocumentResponse)) {
        if (updateKindOfDocumentResponse.payload.success) {
          enqueueSnackbar(t('notifications.kind_of_document_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateKindOfDocumentResponse.payload.errors)
        }
      }
    } else {
      const newKindOfDocumentResponse = await dispatch(
        CreateKindOfDocument.action({
          url: 'kind_of_documents',
          params: { kind_of_document: data },
        }),
      )
      if (isFulfilled(newKindOfDocumentResponse)) {
        if (newKindOfDocumentResponse.payload.success) {
          enqueueSnackbar(t('notifications.kind_of_document_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newKindOfDocumentResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.kind_of_document_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="letter"
            control={control}
            defaultValue={kindOfDocument.letter || ''}
            render={({ field }) => (
              <TextField
                required
                id="name"
                label={t('kind_of_document.letter')}
                name="letter"
                error={errors.letter?.length > 0}
                helperText={errors.letter && errors.letter[0]}
                {...field}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            defaultValue={kindOfDocument.name || ''}
            render={({ field }) => (
              <TextField
                required
                id="name"
                label={t('kind_of_document.name')}
                name="name"
                error={errors.name?.length > 0}
                helperText={errors.name && errors.name[0]}
                {...field}
              />
            )}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default KindOfDocumentForm
