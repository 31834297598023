/**
 * The internal imports
 */
import api from '../index'

export default async ({ sector, interval, startDate }) => {
  const response = await api.get(
    `statistics/cash_flow.json?sector=${sector}&interval=${interval}&start_date=${startDate}`,
  )

  return response.data
}
