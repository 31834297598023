/**
 * The external imports
 */
import format from 'date-fns/format'
import { fr } from 'date-fns/locale'
import { capitalize } from './String'
import differenceInHours from 'date-fns/differenceInHours'
import isEqual from 'date-fns/isEqual'
import startOfDay from 'date-fns/startOfDay'
import isSameMonth from 'date-fns/isSameMonth'
import isSameYear from 'date-fns/isSameYear'

/**
 * Formats a date to the format used in the app
 * @param {Date} date : Date to format
 * @returns a string with the date
 */
export const formatDate = date => {
  return format(date, 'dd.MM.yyyy')
}

/**
 * Allows custom formatting of the date and default application of the locale
 * @param date
 * @param formatString
 * @returns {string | *}
 */
export const customFormat = (date, formatString) => {
  return format(date, formatString, { locale: fr })
}

/**
 * Formats the date with full date and time formatting
 * @param date
 * @returns {*}
 */
export const fullDate = date => {
  return capitalize(
    format(date, 'cccc dd LLLL yyyy - HH:mm', {
      locale: fr,
    }),
  )
}
/**
 * Formats a date to the format used in the app (ex: Lundi)
 * @param {Date} date : Date to format
 * @returns a string with the date
 */
export const dayOfWeek = date => {
  return capitalize(format(date, 'EEEE', { locale: fr }))
}

/**
 * Formats the date to the format used in app (ex: Lu)
 * @param date
 * @returns {*}
 */
export const shortDayOfWeek = date => {
  return capitalize(format(date, 'EEEEEE', { locale: fr }))
}

/**
 * Formats the date to the format used in app (ex: Lun. 16 août)
 * @param date
 * @returns {*}
 */
export const dayAndMonth = date => {
  return capitalize(format(date, 'EE dd LLL', { locale: fr }))
}

/**
 * Formats the date to the format used in app (ex: 16 août)
 * @param date
 * @returns {*}
 */
export const shortDayAndMonth = date => {
  return capitalize(format(date, 'd LLLL', { locale: fr }))
}

/**
 * Formats the date to the format used in app (ex:  août 2021)
 * @param date
 * @returns {*}
 */
export const monthAndYear = date => {
  return capitalize(format(date, 'LLLL yyyy', { locale: fr }))
}

/**
 * Formats the date to the short format used in the cashflow table
 * @param {*} date
 * @returns {*}
 */
export const shortMonthAndYear = date => {
  return capitalize(format(date, 'LLL yy', { locale: fr }))
}

/**
 * Convert a time to minutes
 * @param {String} value : value to convert to minutes
 * @returns
 */
export const stringToMin = value => {
  const values = value.split(':')
  return Number(values[0]) * 60 + Number(values[1])
}

/**
 * Formats minutes to a readable time
 * @param {Integer} value : minutes
 * @returns
 */
export const minToString = value => {
  if (!value) {
    return ''
  }
  const hours = parseInt(value / 60, 10)
  const minutes = value % 60
  return `${formatTime(hours)}:${formatTime(minutes)}`
}

/**
 * Checks if date is between two other dates
 * @param date
 * @param minDate
 * @param maxDate
 * @returns {boolean|boolean}
 */
export const dateIsBetween = (date, minDate, maxDate) => {
  return (
    differenceInHours(date, minDate) + 24 >= 0 &&
    differenceInHours(maxDate, date) >= 0
  )
}

/**
 * Checks if the date is today's date
 * @param date
 * @returns {boolean | *}
 */
export const isToday = date => {
  return isEqual(date, startOfDay(new Date()))
}

/**
 * Checks if we're in december
 * @returns {boolean | *}
 */
export const isDecember = () => {
  const currentMonth = new Date().getMonth() + 1
  return currentMonth === 12
}

/**
 * Format overtime minutes into hours:minutes
 * @returns date
 */
export const formattedOvertime = overtimeInMinutes => {
  const hours = Math.floor(Math.abs(overtimeInMinutes) / 60)
  const minutes = Math.abs(overtimeInMinutes) % 60
  return `${hours < 10 ? '0' + hours : hours}:${('0' + minutes).slice(-2)}`
}

/**
 * Checks whether two dates are in the same month of the same year
 * @param dateLeft
 * @param dateRight
 * @returns {boolean | *}
 */
export const isSameMonthAndYear = (dateLeft, dateRight) => {
  return isSameYear(dateLeft, dateRight) && isSameMonth(dateLeft, dateRight)
}

/**
 * Returns the long month name
 * @returns string
 */
export const longMonth = date => {
  return format(date, 'MMMM', {
    locale: fr,
  })
}

/**
 * Add 0 to a digit to format 00:00
 * @param {*} num : digit to format
 * @returns
 */
const formatTime = num => {
  let string = num + ''
  while (string.length < 2) {
    string = '0' + string
  }
  return string
}
