/**
 * The external imports
 */
import {
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import CreateService from '../../Services/Datatable/Create'

export default {
  initialState: {
    invite: {
      item: null,
      loading: false,
      error: null,
    },
  },
  action: buildAsyncActions('user/invite', CreateService),
  reducers: buildAsyncReducers({
    itemKey: null,
    errorKey: 'invite.error',
    loadingKey: 'invite.loading',
  }),
}
