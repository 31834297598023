/**
 * The external imports
 */
import Papa from 'papaparse'

/**
 * Reads the CSV string and converts it to a workable array
 * @param {*} str string
 * @param {#} setCsvArray function
 */
export default (str, setCsvArray, suppliers, kindOfCosts) => {
  const firstLine = str.slice(0, str.indexOf('\n')).split(',')
  if (
    firstLine[0].includes('Genre de comptabilisation:') ||
    firstLine[0].includes('Date de début:') ||
    firstLine[0].includes('Date du:')
  ) {
    parseCsv(
      cleanCsv(str, 6, 3),
      'Date',
      'Débit en CHF',
      setCsvArray,
      suppliers,
      kindOfCosts,
    )
  } else if (firstLine[0].includes('Compte de carte:')) {
    parseCsv(
      cleanCsv(str, 3, 3),
      'Date',
      'Débit en CHF',
      setCsvArray,
      suppliers,
      kindOfCosts,
      false,
    )
  } else {
    parseCsv(
      str,
      'Date completed (UTC)',
      'Amount',
      setCsvArray,
      suppliers,
      kindOfCosts,
    )
  }
}

/**
 * Parse the csv content and builds the local state array
 * @param {*} csvString string
 * @param {*} date string
 * @param {*} amount string
 * @param {*} filterRequired boolean
 */
const parseCsv = (
  csvString,
  date,
  amount,
  setCsvArray,
  suppliers,
  kindOfCosts,
  filterRequired = true,
) => {
  Papa.parse(csvString, {
    header: true,
    skipEmptyLines: true,
    complete: results => {
      let filteredResults = results.data

      if (filterRequired) {
        filteredResults = results.data.filter(row => {
          return parseFloat(row[amount]) < 0
        })
      }

      const newCsvResults = filteredResults.map(row => {
        let predefinedSupplier = ''
        let predefinedWording = null
        let predefinedKindOfCost = ''
        let predefinedPercentWeb = null
        let predefinedPercentDev = null
        if (row.Description) {
          if (row.Description.toLowerCase().includes('infomaniak')) {
            predefinedSupplier = suppliers.find(
              supplier => supplier.name === 'Infomaniak',
            )?.id
            predefinedKindOfCost = kindOfCosts.find(
              kindOfCost => kindOfCost.name === 'Hosting',
            )?.id
            predefinedWording = 'Hébergement Infomaniak'
            predefinedPercentWeb = 100
            predefinedPercentDev = 0
          }
        }
        if (row['Détails de comptabilisation']) {
          if (
            row['Détails de comptabilisation']
              .toLowerCase()
              .includes('appsignal')
          ) {
            predefinedSupplier = suppliers.find(
              supplier => supplier.name === 'AppSignal',
            )?.id
            predefinedWording = 'Service Appsignal'
            predefinedKindOfCost = kindOfCosts.find(
              kindOfCost => kindOfCost.name === 'Licences',
            )?.id
            predefinedPercentWeb = 0
            predefinedPercentDev = 100
          } else if (
            row['Détails de comptabilisation']
              .toLowerCase()
              .includes('infomaniak')
          ) {
            predefinedSupplier = suppliers.find(
              supplier => supplier.name === 'Infomaniak',
            )?.id
            predefinedKindOfCost = kindOfCosts.find(
              kindOfCost => kindOfCost.name === 'Hosting',
            )?.id
            predefinedWording = 'Hébergement Infomaniak'
            predefinedPercentWeb = 100
            predefinedPercentDev = 0
          } else if (
            row['Détails de comptabilisation'].toLowerCase().includes('komunik')
          ) {
            predefinedSupplier = suppliers.find(
              supplier => supplier.name === 'Komunik',
            )?.id
            predefinedKindOfCost = kindOfCosts.find(
              kindOfCost => kindOfCost.name === 'Licences',
            )?.id
            predefinedWording = 'Winbizz Vignoble cousin'
            predefinedPercentWeb = 100
            predefinedPercentDev = 0
          } else if (
            row['Détails de comptabilisation'].toLowerCase().includes('slack')
          ) {
            predefinedSupplier = suppliers.find(
              supplier => supplier.name === 'Slack',
            )?.id
            predefinedKindOfCost = kindOfCosts.find(
              kindOfCost => kindOfCost.name === 'Licences',
            )?.id
            predefinedWording = 'Slack'
            predefinedPercentWeb = 34
            predefinedPercentDev = 66
          } else if (
            row['Détails de comptabilisation'].toLowerCase().includes('akismet')
          ) {
            predefinedSupplier = suppliers.find(
              supplier => supplier.name === 'Akismet',
            )?.id
            predefinedKindOfCost = kindOfCosts.find(
              kindOfCost => kindOfCost.name === 'Licences',
            )?.id
            predefinedWording = 'Akismet'
            predefinedPercentWeb = 100
            predefinedPercentDev = 0
          } else if (
            row['Détails de comptabilisation'].toLowerCase().includes('uxpin')
          ) {
            predefinedSupplier = suppliers.find(
              supplier => supplier.name === 'UxPin',
            )?.id
            predefinedKindOfCost = kindOfCosts.find(
              kindOfCost => kindOfCost.name === 'Licences',
            )?.id
            predefinedWording = 'UxPin'
            predefinedPercentWeb = 100
            predefinedPercentDev = 0
          } else if (
            row['Détails de comptabilisation']
              .toLowerCase()
              .includes('postmarkapp')
          ) {
            predefinedSupplier = suppliers.find(
              supplier => supplier.name === 'PostMark',
            )?.id
            predefinedKindOfCost = kindOfCosts.find(
              kindOfCost => kindOfCost.name === 'Licences',
            )?.id
            predefinedWording = 'Postmark'
            predefinedPercentWeb = 0
            predefinedPercentDev = 100
          } else if (
            row['Détails de comptabilisation']
              .toLowerCase()
              .includes('digitalocean')
          ) {
            predefinedSupplier = suppliers.find(
              supplier => supplier.name === 'DigitalOcean',
            )?.id
            predefinedKindOfCost = kindOfCosts.find(
              kindOfCost => kindOfCost.name === 'Hosting',
            )?.id
            predefinedWording = 'DigitalOcean'
            predefinedPercentWeb = 0
            predefinedPercentDev = 100
          } else if (
            row['Détails de comptabilisation']
              .toLowerCase()
              .includes('aws emea')
          ) {
            predefinedSupplier = suppliers.find(
              supplier => supplier.name === 'AWS',
            )?.id
            predefinedKindOfCost = kindOfCosts.find(
              kindOfCost => kindOfCost.name === 'Hosting',
            )?.id
            predefinedWording = 'Hosting AWS'
            predefinedPercentWeb = 0
            predefinedPercentDev = 100
          } else if (
            row['Détails de comptabilisation'].toLowerCase().includes('wpml')
          ) {
            predefinedSupplier = suppliers.find(
              supplier => supplier.name === 'Autre',
            )?.id
            predefinedKindOfCost = kindOfCosts.find(
              kindOfCost => kindOfCost.name === 'Licences',
            )?.id
            predefinedWording = 'Licences WPML'
            predefinedPercentWeb = 100
            predefinedPercentDev = 0
          }
        }
        return {
          sending_date: row[date],
          supplier_id: predefinedSupplier,
          wording: predefinedWording,
          kind_of_cost_id: predefinedKindOfCost,
          amount: Math.abs(parseFloat(row[amount])).toFixed(2),
          web_activity_rate: predefinedPercentWeb,
          dev_activity_rate: predefinedPercentDev,
          ignore_line: false,
          reference_text:
            row['Détails de comptabilisation'] ||
            row.Description ||
            row['Texte de notification'],
        }
      })
      setCsvArray(prevState => [...prevState, ...newCsvResults])
    },
  })
}

/**
 * Cleans the CSV string by removing unwanted lines from beginning and end
 * @param {*} text string
 * @param {*} removeFromStart integer
 * @param {*} removeFromEnd integer
 * @returns
 */
const cleanCsv = (text, removeFromStart, removeFromEnd) => {
  const cleanedArray = text.split('\n')
  cleanedArray.splice(0, removeFromStart)
  cleanedArray.splice(cleanedArray.length - removeFromEnd, removeFromEnd)
  return cleanedArray.join('\n')
}
