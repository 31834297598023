import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  documentTitle: { marginTop: 20 },
  dropzoneWrapper: { marginTop: 10 },
  dropzoneFile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
}))
export default useStyles
