/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import clsx from 'clsx'

/**
 * The internal imports
 */
import GetAllTechnologies from '../../Store/Technology/GetAll'
import { Datatable } from '../index'
import useLayout from '../../Theme/Layouts'
import { openModal } from '../../Utils/Modal'

const SettingsTechnologies = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const layout = useLayout()

  const { data, total } = useSelector(state => state.technology.getAll.item)
  const createTechnologyLoading = useSelector(
    state => state.technology.create.loading,
  )
  const updateTechnologyLoading = useSelector(
    state => state.technology.update.loading,
  )

  const [tableState, setTableState] = useState({})
  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: params => openModal({ type: 'formTechnology', id: params.id }),
    },
  ])
  const [excludedColumns] = useState(['id'])

  useEffect(async () => {
    if (!createTechnologyLoading && !updateTechnologyLoading) {
      await dispatch(
        GetAllTechnologies.action({
          params: { tableState },
          url: 'technologies',
        }),
      )
    }
  }, [tableState, createTechnologyLoading, updateTechnologyLoading])

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={() => openModal({ type: 'formTechnology' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        data={data}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
        source="technologies"
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
      />
    </div>
  )
}

export default SettingsTechnologies
