/**
 * The external imports
 */
import {
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import { UpdateUsersService } from '../../Services/User'

export default {
  initialState: {
    update: {
      item: null,
      loading: false,
      error: null,
    },
  },
  action: buildAsyncActions('user/update', UpdateUsersService),
  reducers: buildAsyncReducers({
    itemKey: null,
    errorKey: 'update.error',
    loadingKey: 'update.loading',
  }),
}
