/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import startOfMonth from 'date-fns/startOfMonth'
import DatePicker from '@mui/lab/DatePicker'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
  TextField,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateIntervention from '../../Store/Intervention/Create'
import UpdateIntervention from '../../Store/Intervention/Update'
import { Loader, TextEditor } from '../../Components/index'
import FetchOneIntervention from '../../Store/Intervention/FetchOne'
import useForms from '../../Theme/Components/Forms'

const InterventionForm = ({ id, maintenanceId, date, technologies }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [intervention, setIntervention] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(async () => {
    if (id) {
      const interventionResponse = await dispatch(
        FetchOneIntervention.action({
          url: `maintenances/${maintenanceId}/interventions/${id}`,
        }),
      )
      if (isFulfilled(interventionResponse)) {
        setIntervention(interventionResponse.payload)
      }
    } else {
      setIntervention({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new intervention form to the db
   */
  const onSubmit = async data => {
    if (id) {
      const updateInterventionResponse = await dispatch(
        UpdateIntervention.action({
          url: `maintenances/${maintenanceId}/interventions/${id}`,
          params: { intervention: data },
        }),
      )
      if (isFulfilled(updateInterventionResponse)) {
        if (updateInterventionResponse.payload.success) {
          enqueueSnackbar(t('notifications.intervention_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateInterventionResponse.payload.errors)
        }
      }
    } else {
      const newInterventionResponse = await dispatch(
        CreateIntervention.action({
          url: `maintenances/${maintenanceId}/interventions`,
          params: { intervention: data },
        }),
      )
      if (isFulfilled(newInterventionResponse)) {
        if (newInterventionResponse.payload.success) {
          enqueueSnackbar(t('notifications.intervention_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          if (newInterventionResponse.payload.errors) {
            setErrors(newInterventionResponse.payload.errors)
          } else {
            enqueueSnackbar(newInterventionResponse.payload.message, {
              variant: 'error',
            })
          }
        }
      }
    }
  }

  /**
   * Concatenates the descriptions of from the technologies to build the intervention description
   * @returns {*}
   */
  const renderDescriptionBody = () => {
    return technologies
      .map(technology => {
        return technology.description.body
      })
      .join('')
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.intervention_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="date"
            control={control}
            defaultValue={intervention.date || startOfMonth(date) || new Date()}
            render={({ field }) => (
              <DatePicker
                mask="__.__.____"
                label={t('intervention.date')}
                className={formClasses.fieldMargin}
                renderInput={params => (
                  <TextField {...params} helperText={errors.date?.message} />
                )}
                {...field}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            defaultValue={
              intervention.description?.body || renderDescriptionBody() || ''
            }
            render={({ field }) => (
              <TextEditor value={field.value} onChange={field.onChange} />
            )}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default InterventionForm
