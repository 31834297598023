/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import Add from './Add'
import AddUnmasked from './AddUnmasked'
import Remove from './Remove'
import GetAll from './GetAll'
import FetchOne from './FetchOne'
import Update from './Update'
import Invite from './Invite'

export default buildSlice(
  'user',
  [GetAll, FetchOne, Add, Remove, Update, Invite, AddUnmasked],
  {
    item: {},
    unmasked: {},
    getAll: { item: {} },
    fetchOne: { item: {} },
  },
).reducer
