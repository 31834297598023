/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateKindOfService from '../../Store/KindOfService/Create'
import UpdateKindOfService from '../../Store/KindOfService/Update'
import { Loader, TextField } from '../../Components/index'
import FetchOneKindOfService from '../../Store/KindOfService/FetchOne'
import useForms from '../../Theme/Components/Forms'

const KindOfServiceForm = ({ id }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [kindOfService, setKindOfService] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(async () => {
    if (id) {
      const kindOfServiceResponse = await dispatch(
        FetchOneKindOfService.action({ url: `kind_of_services/${id}` }),
      )
      if (isFulfilled(kindOfServiceResponse)) {
        setKindOfService(kindOfServiceResponse.payload)
      }
    } else {
      setKindOfService({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new kind of service form to the db
   */
  const onSubmit = async data => {
    if (id) {
      const updateKindOfServiceResponse = await dispatch(
        UpdateKindOfService.action({
          url: `kind_of_services/${id}`,
          params: { kind_of_service: data },
        }),
      )
      if (isFulfilled(updateKindOfServiceResponse)) {
        if (updateKindOfServiceResponse.payload.success) {
          enqueueSnackbar(t('notifications.kind_of_service_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateKindOfServiceResponse.payload.errors)
        }
      }
    } else {
      const newKindOfServiceResponse = await dispatch(
        CreateKindOfService.action({
          url: 'kind_of_services',
          params: { kind_of_service: data },
        }),
      )
      if (isFulfilled(newKindOfServiceResponse)) {
        if (newKindOfServiceResponse.payload.success) {
          enqueueSnackbar(t('notifications.kind_of_service_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newKindOfServiceResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.kind_of_service_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="name"
            control={control}
            defaultValue={kindOfService.name || ''}
            render={({ field }) => (
              <TextField
                required
                id="name"
                label={t('kind_of_service.name')}
                name="name"
                error={errors.name?.length > 0}
                helperText={errors.name && errors.name[0]}
                {...field}
              />
            )}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default KindOfServiceForm
