/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isFulfilled } from '@reduxjs/toolkit'
import { saveAs } from 'file-saver'

/**
 * The internal imports
 */
import { Datatable } from '../index'
import GetAllSalaries from '../../Store/Salary/GetAll'
import DownloadSalary from '../../Store/Salary/Download'
import { openModal } from '../../Utils/Modal'

const ListSalaries = ({ employeeId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // Get values from the store
  const { data, total } = useSelector(state => state.salary.getAll.item)
  const loadingCreateSalary = useSelector(state => state.salary.create.loading)
  const loadingUpdateSalary = useSelector(state => state.salary.update.loading)
  const currentUser = useSelector(state => state.user.item)
  const [excludedColumns] = useState(['id'])
  const [tableState, setTableState] = useState({})
  const [menuOptions, setMenuOptions] = useState([
    {
      label: t('datatables.download'),
      columns: ['id'],
      action: async ({ id }) => {
        const salaryResponse = await dispatch(
          DownloadSalary.action({
            params: {},
            url: `employees/${employeeId}/salaries/${id}/download`,
          }),
        )

        if (isFulfilled(salaryResponse)) {
          const blob = new Blob([salaryResponse.payload], {
            type: 'application/octet-stream',
          })
          saveAs(blob, 'fiche_salaire.pdf')
        }
      },
    },
  ])

  // Add edit if user is admin
  useEffect(() => {
    if (currentUser.role === 'admin') {
      menuOptions.push({
        label: t('datatables.edit'),
        columns: ['id'],
        action: params =>
          openModal({
            type: 'formSalary',
            id: params.id,
            options: { employeeId: employeeId },
          }),
      })

      setMenuOptions(menuOptions)
    }
  }, [])

  useEffect(async () => {
    await dispatch(
      GetAllSalaries.action({
        params: { tableState },
        url: `employees/${employeeId}/salaries`,
      }),
    )
  }, [loadingCreateSalary, loadingUpdateSalary])

  return (
    <Datatable
      source="salaries"
      data={data}
      total={total}
      tableState={tableState}
      setTableState={setTableState}
      menuOptions={menuOptions}
      excludedColumns={excludedColumns}
      elevation={1}
      searchable={false}
      sortable={false}
    />
  )
}

export default ListSalaries
