/**
 * The external imports
 */
import React, { useMemo, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Container,
  Typography,
  Card,
  CardHeader,
  CardActions,
  Divider,
  Button,
  Box,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import useLayout from '../../Theme/Layouts'
import dropzoneStyles from '../../Theme/Components/FileUploader.style'
import useDropzoneStyles from '../../Theme/Components/Modal/RefundRequestForm.style'
import { Loader, FormPayableBill } from '../../Components/index'
import useForms from '../../Theme/Components/Forms'
import FetchAllService from '../../Services/FetchAll'
import processCsv from '../../Utils/Csv'
import CreatePayableBill from '../../Store/PayableBill/Create'
import useStyles from '../../Theme/Pages/Charges/index.style'

const ImportCharges = () => {
  const { t } = useTranslation()
  const layout = useLayout()
  const dropZoneClasses = useDropzoneStyles()
  const formClasses = useForms()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [kindOfCosts, setKindOfCosts] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [csvArray, setCsvArray] = useState([])
  const [errors, setErrors] = useState([])

  const { watch, reset, register, handleSubmit, control } = useForm({
    defaultValues: { items: [] },
  })

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: '.csv, text/csv',
  })

  const style = useMemo(
    () => ({
      ...dropzoneStyles.base,
      ...(isDragActive ? dropzoneStyles.active : {}),
      ...(isDragAccept ? dropzoneStyles.accept : {}),
      ...(isDragReject ? dropzoneStyles.reject : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  useEffect(async () => {
    const kindOfCostResponse = await FetchAllService({ url: 'kind_of_costs' })
    setKindOfCosts(kindOfCostResponse)

    const suppliersResponse = await FetchAllService({ url: 'suppliers' })
    setSuppliers(suppliersResponse)
    setLoading(false)
  }, [])

  /**
   * Updates the attached files list when acceptedFiles changes
   */
  useEffect(() => {
    if (!loading) {
      setLoading(true)
    }
    for (const file of acceptedFiles) {
      const reader = new window.FileReader()
      reader.onload = e => {
        const text = e.target.result
        processCsv(text, setCsvArray, suppliers, kindOfCosts)
      }
      reader.readAsText(file, 'UTF-8')
    }
    setLoading(false)
  }, [acceptedFiles])

  useEffect(() => {
    if (!loading) {
      setLoading(true)
    }
    reset({ items: csvArray })
    setLoading(false)
  }, [csvArray])

  /**
   * Submits the form to the backend
   */
  const onSubmit = async data => {
    if (!loading) {
      setLoading(true)
    }
    const filteredArray = data.items.filter(row => !row.ignore_line)
    if (filteredArray.length > 0) {
      const formattedArray = filteredArray.map(row => ({
        sending_date: row.sending_date,
        supplier_id: row.supplier_id,
        invoice_lines_attributes: [
          {
            wording: row.wording,
            amount: row.amount,
            kind_of_cost_id: row.kind_of_cost_id,
            dev_activity_rate: parseInt(row.dev_activity_rate, 10),
            web_activity_rate: parseInt(row.web_activity_rate, 10),
          },
        ],
      }))
      const newPayableBillResponse = await dispatch(
        CreatePayableBill.action({ params: { payable_bills: formattedArray } }),
      )
      if (isFulfilled(newPayableBillResponse)) {
        if (newPayableBillResponse.payload.success) {
          enqueueSnackbar(
            t('notifications.charges_imported', {
              number: formattedArray.length,
            }),
            {
              variant: 'success',
            },
          )
          setCsvArray([])
          history.push('/suppliers')
        } else {
          const responseErrors = newPayableBillResponse.payload.errors
          const newErrors = []
          let errorIndex = 0
          data.items.forEach(item => {
            if (item.ignore_line) {
              newErrors.push({})
            } else {
              newErrors.push(responseErrors[errorIndex])
              errorIndex += 1
            }
          })
          setErrors(newErrors)
          setLoading(false)
        }
      }
    } else {
      enqueueSnackbar(t('notifications.charges_ignored'), {
        variant: 'error',
      })
      history.push('/suppliers')
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Container maxWidth="xl">
      <div className={clsx(layout.row, layout.flex)}>
        <Typography variant="h4" color="inherit" gutterBottom>
          {t('pages.charges.title')}
        </Typography>
      </div>

      <section className={dropZoneClasses.dropzoneWrapper}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>{t('components.file_uploader.drop_text')}</p>
        </div>
      </section>

      <section className={classes.chargesSection}>
        <Card>
          <CardHeader title={t('pages.charges.card_title')} />
          {csvArray.length > 0 && <Divider />}
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            {csvArray.map((csvRow, index) => (
              <FormPayableBill
                key={`charge_${index}`}
                reference_text={csvRow.reference_text}
                index={index}
                kindOfCosts={kindOfCosts}
                suppliers={suppliers}
                errors={errors[index]}
                register={register}
                control={control}
                watch={watch}
              />
            ))}
            <Divider />
            <CardActions>
              <Box className={formClasses.submitAction}>
                <Button type="submit" variant="contained" color="primary">
                  {t('actions.validate')}
                </Button>
              </Box>
            </CardActions>
          </form>
        </Card>
      </section>
    </Container>
  )
}

export default ImportCharges
