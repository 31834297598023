/**
 * The external imports
 */

/**
 * Capitalize a string
 * @param {String} word : string to capitalize
 * @returns String
 */
export const capitalize = word => {
  return word[0].toUpperCase() + word.substring(1).toLowerCase()
}

/**
 * Formats a string as a currency
 * @param {string | float} number :  number to format
 * @returns String
 */
export const formatCurrency = number => {
  return parseFloat(number)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, "'")
}

/**
 * Truncates a text after length carater
 * @param {*} text : text to truncate
 * @param {*} length : length of string before truncate
 * @returns String
 */
export const truncateText = (text, length) => {
  if (text.length <= length) {
    return text
  }

  return text.substr(0, length) + '\u2026'
}
