export default {
  actions: {
    add: 'Ajouter',
    add_service: 'Ajouter un service',
    add_period: 'Ajouter une période',
    send: 'Envoyer',
    login: 'Connexion',
    save: 'Enregistrer',
    update: 'Modifier',
    close: 'Fermer',
    validate: 'Valider',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    pay: 'Payer les salaires',
    next: 'Suivant',
    previous: 'Retour',
    download: 'Télécharger',
    ignore: 'Ignore',
  },
  search: {
    placeholder: 'Recherche',
  },
  navigation: {
    dashboard: 'Dashboard',
  },
  components: {
    payable_bill: {
      edit: {
        title: 'Modifier la facture',
      },
    },
    maintenance_period_card: {
      title_start: 'Période du',
      title_middle: 'au',
      add_invoice_line: 'Créer ligne de facturation',
    },
    service_form: {
      title: 'Créer un service',
    },
    salary_form: {
      title: 'Payer un salaire',
    },
    offer: {
      form: 'Offre',
    },
    file_uploader: {
      drop_text:
        'Déposer des fichiers, ou cliquez pour selectionner des documents',
      files: 'Fichiers',
    },
    refund_requests_form: {
      title: 'Notes de frais',
      employee: 'Employé',
    },
    client_form: {
      title: 'Créer un client',
      same_as_mailing: "Même que l'adresse de facturation",
    },
    work_form: {
      title: 'Créer un projet',
    },
    supplier_form: {
      title: 'Créer un fournisseur',
    },
    client_delete: {
      title: 'Attention !',
    },
    justifications: {
      title: 'Justifications',
    },
    technology_form: {
      title: 'Créer une technologie',
    },
    technology_delete: {
      title: 'Attention !',
    },
    product_form: {
      title: 'Créer un produit',
    },
    maintenance_form: {
      title: 'Créer une maintenance',
    },
    contact_form: {
      title: 'Créer un contact',
    },
    contact_delete: {
      title: 'Attention !',
    },
    prepaid_hour_form: {
      title: "Créer un pack d'heures",
    },
    spent_hour_form: {
      title: 'Loguer des heures',
    },
    intervention_form: {
      title: 'Créer une intervention',
    },
    invoice_lines_form: {
      add_invoice_lines: 'Ajouter une ligne de facturation',
      no_invoice_line: 'Pas de ligne de facturation disponible',
      total: 'Total',
    },
    invoice_line_form: {
      title: 'Ligne de facturation',
    },
    settings_form: {
      title: 'Créer des réglages',
      sub_title: 'Valeurs',
    },
    off_days: {
      from: 'Du',
      to: 'Au',
      validations: {
        in_future:
          'La date de début ne peut pas être plus grande que la date de fin',
      },
    },
    receivable_bill_form: {
      title: 'Créer une facture',
    },
    public_holiday_form: {
      title: 'Créer un jour ferié',
    },
    kind_of_cost_form: {
      title: 'Créer un type de coût',
    },
    intervention_header: {
      interventions: 'Interventions',
    },
    kind_of_service_form: {
      title: 'Créer un type de service',
    },
    kind_of_document_form: {
      title: 'Créer un type de document',
    },
    receivable_bill: {
      show: {
        invoice: 'Facture',
        not_payed: 'Pas payé',
      },
    },
    maintenance_period_form: {
      title: 'Période de facturation',
    },
  },
  pages: {
    not_found: {
      title: '404 Page Not Found',
      header: 'Page introuvable',
      return: "J'ai dû me tromper quelque part",
    },
    not_authorized: {
      title: '403 Not authorized',
      header: 'Accès interdit',
      return: 'Ok je me casse !!',
    },
    auth: {
      forgot_password: {
        title: 'Réinitialisation de mot de passe',
      },
      sign_in: {
        title: 'Bienvenue',
      },
      new_password: {
        title: 'Changer de mot de passe',
      },
      invitation_accepted: {
        title: 'Terminer votre inscription',
      },
      redirection: "Vous allez être redirigé d'ici quelques secondes",
      sign_in_url: 'Connexion',
      forgot_password_url: 'Mot de passe oublié ?',
    },
    dashboard: {
      monthly_turnover: 'Facturé HT en {{ month }} {{ year }}',
      web_monthly_turnover: 'Web',
      dev_monthly_turnover: 'Dev',
      monthly_income: 'Encaissé en {{ month }} {{ year }}',
      turnover_current_year: 'Facturé en {{ year }}',
      income_current_year: 'Encaissé en {{ year }}',
      unpaid_bills: 'Créance client',
      unpaid_late_bills: 'Créance client en retard',
    },
    cashflow: {
      options_start: 'Cashflow des',
      options_end: 'derniers mois pour',
      title: 'Cashflow',
      interval: 'Periode courante',
      incomes: 'Revenus',
      charges: 'Charges',
      totals: 'Résultats',
      all: 'Tout',
      web: 'Web',
      dev: 'Dev',
      choose_year: 'Choisir une année',
    },
    statistics: {
      web_turnover: 'Web Turnover',
      dev_turnover: 'Dev Turnover',
      web_income: 'Web Income',
      dev_income: 'Dev Income',
      monthly_turnover_split: 'Montant facturé par mois TTC',
      monthly_turnover_year: 'Montant facturé par mois TTC',
      monthly_income_year: 'Montant encaissé par mois TTC',
      date: 'Mois / Année',
      sector: 'Secteur',
      export: 'Export',
      sector_items: {
        web: 'Web',
        dev: 'Dev',
        all: 'Tous',
      },
    },
    users: {
      title: 'Employé(e)s',
    },
    clients: {
      title: 'Clients',
      delete_confirm: 'Etes vous sur de vouloir supprimer ?',
      show: {
        average_payment_days: 'Délai de paiement moyen',
        average_payment_days_value: '{{ value }} jours',
        description: 'Description',
        products: 'Products',
        projects: 'Projets',
        maintenance: 'Maintenance',
        prepaid_hours: "Pack d'heure",
        invoice_lines: 'Lignes de facturations',
        invoices: 'Factures',
        offers: 'Offres',
        documents: 'Documents',
        contacts: 'Contacts',
      },
    },
    products: {
      show: {
        projects: 'projects',
        maintenances: 'Maintenance',
        hour_pack: "Packs d'heure",
      },
    },
    suppliers: {
      title: 'Fournisseurs',
    },
    menu: {
      title: 'Menu de la semaine',
    },
    profile: {
      connection_info: 'Information de connexion',
      salary_slip: 'Fiche de salaires',
      refund_requests: 'Notes de frais',
      off_days: 'Congés / Absences',
      documents: 'Documents',
      personal_info: 'Données personnelles',
      general_info: 'Information générale',
      address: 'Adresse',
    },
    time_sheets: {
      week: 'Semaine',
      overtime: 'heures supp.',
    },
    refund_requests: {
      title: 'Notes de frais',
      all: 'Tout',
      to_review: 'A valider',
      mine: 'A moi',
      form: {
        title: 'Créer une note de frais',
        subtitle: 'Information Générale',
        documents: 'Documents',
      },
    },
    off_days: {
      title: 'Congés / Absences',
      all: 'Tout',
      to_review: 'A valider',
      mine: 'A moi',
    },
    absences: {
      title: 'Absences',
      total: 'Total',
      type: {
        weekend: 'Weekend',
        publicHoliday: 'Ferié',
        holiday: 'Vacances',
        army: 'Armée',
        additional_hour: 'Heures supp.',
        wagon: 'Wagon',
        sickness: 'Maladie',
        other: 'Autre',
        pending: 'En attente',
        today: "Aujourd'hui",
      },
      status: {
        pending: 'En attente',
        accepted: 'Accepté',
        refused: 'Refusé',
        cancelled: 'Annulé',
      },
    },
    invoices: {
      title: 'Facturation',
      tabs: {
        invoices: 'Factures',
        invoice_lines: 'Lignes de facturation',
        late: 'Factures en retard',
      },
    },
    maintenance_planning: {
      calendar: 'Calendrier',
      late: 'Echéances',
      title: 'Maintenances',
      start_date: 'Date de début',
      missed_maintenances: 'En retard',
      next_maintenance: 'Prochaine',
      planned_intervention: 'Planifié',
    },
    settings: {
      title: 'Réglages',
      tabs: {
        general: 'Générales',
        public_holidays: 'Jours feriés',
        services: 'Services',
        costs: 'Coûts',
        documents: 'Documents',
        technologies: 'Technologies',
      },
    },
    prepaid_hours: {
      show: {
        prepaid_hours: "Pack d'heures",
        price: 'Tarif horraire',
        total_hours: 'Heures disponibles',
        no_description: 'Pas de description',
        description: 'Description',
      },
    },
    charges: {
      title: 'Importer les charges',
      card_title: 'Charges',
    },
  },
  side_bar: {
    quick_actions: 'Action rapides',
    general: 'General',
    dashboard: 'Dashboard',
    clients: 'Clients',
    suppliers: 'Fournisseurs',
    profile: 'Profil',
    menu: 'Becquetance',
    refund_requests: 'Notes de frais',
    off_days: 'Congés / Absences',
    users: 'Employés',
    absences: 'Absences',
    invoices: 'Factures',
    maintenances: 'Maintenances',
    late_maintenances: 'Maintenances en retard',
    settings: 'Réglages',
    statistics: 'Statistiques',
    import_charges: 'Importer charges',
    logout: 'Déconnexion',
    unmask: 'Démasquer',
  },
  user: {
    first_name: 'Prénom',
    last_name: 'Nom',
    middle_name: '2ème prénom',
    birth_date: 'Date de naissance',
    marital_status: 'Etat civil',
    marital_status_items: {
      single: 'Célibataire',
      married: 'Marié',
      divorced: 'Divorcé',
      widower: 'Veuf/ve',
    },
    role: 'Role',
    approved: 'Accès CRM',
    role_items: {
      admin: 'Administrateur',
      employee: 'Employé',
    },
    avs: 'N° AVS',
    origin: "Lieu d'origine",
    iban: 'IBAN',
    email: 'Email',
    password: 'Mot de passe',
    password_confirmation: 'Confirmation du mot de passe',
    form: {
      title: 'Ajouter un employé',
    },
  },
  time_sheets: {
    start_am: 'Arrivée',
    end_am: 'Départ',
    start_pm: 'Arrivée',
    end_pm: 'Départ',
    comment: 'Commentaire',
  },
  address: {
    attention_of: "A l'attention de",
    street: 'Rue',
    additional: 'Additionnel',
    zip_code: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    countries: {
      CH: 'Suisse',
      FR: 'France',
    },
  },
  off_day: {
    imputable: 'Imputable',
    imputable_items: {
      holiday: 'Vacances',
      additional_hour: 'Heures supplémentaire',
      army: 'Armée',
      sickness: 'Maladie',
      other: 'Autre',
      wagon: 'Wagon',
    },
    date: 'Date',
    hour: 'Heure',
    reason: 'Raison',
    status_items: {
      pending: 'A valider',
      accepted: 'Accepté',
      cancelled: 'Annulé',
      refused: 'Refusé',
    },
  },
  salary: {
    gross: 'Montant brute',
    bonus: 'Bonus',
    lpp_amount: 'Montant LPP',
  },
  client: {
    name: 'Nom',
    description: 'Description',
    mailing_address: 'Adresse de correspondance',
    billing_address: 'Adresse de facturation',
  },
  supplier: {
    name: 'Nom',
  },
  refund_request: {
    id: 'ID',
    description: 'Description',
    created_at: 'Date de demande',
    refund_request_date: 'Date de la facture',
    amount: 'Montant',
    document_name: 'Nom du fichier',
    file: 'Fichier',
    status: 'Status',
    status_items: {
      pending: 'A valider',
      accepted: 'Accepté',
      cancelled: 'Annulé',
      refused: 'Refusé',
    },
  },
  technology: {
    name: 'Nom',
  },
  product: {
    name: 'Nom',
    description: 'Description',
  },
  work: {
    name: 'Nom',
    start_date: 'Date de début',
    description: 'Description',
    state: 'Status',
    product: 'Produit',
    owner: 'Responsable',
    state_items: {
      ongoing: 'En cours',
      cancel: 'Annulé',
      finish: 'Terminé',
    },
  },
  maintenance: {
    name: 'Nom',
    start_date: 'Date de début',
    status: 'Status',
    description: 'Description',
    product_id: 'Produit',
    technologies: 'Technologies',
  },
  contact: {
    first_name: 'Prénom',
    last_name: 'Nom',
    email: 'Email',
    phone: 'Téléphone',
  },
  offer: {
    description: 'Description',
    status: 'Status',
    status_items: {
      sent: 'Envoyé',
      postponed: 'Remis à plus tard',
      refused: 'Refusé',
      accepted: 'Accepté',
    },
    dev_amount: 'Montant développement',
    maintenance_amount: 'Montant maintenance',
  },
  intervention: {
    name: 'Nom',
    date: 'Date',
    description: 'Description',
  },
  prepaid_hour: {
    name: 'Nom',
    start_date: 'Date de début',
    price: 'Prix horaire',
    hours: "Nombre d'heures",
    description: 'Description',
    product_id: 'Produit',
  },
  spent_hour: {
    name: 'Nom',
    request_date: 'Date de la requete',
    hours: "Nombre d'heures",
    description: 'Description',
  },
  receivable_bill: {
    sending_date: "Date d'envoi",
    payment_deadline_days: 'Délai de paiement',
    payment_date: 'Date de paiement',
    amount: 'Montant HT',
    ttc_amount: 'Montant TTC',
    vat_rate: 'TVA',
    product_id: 'Produit',
    invoice_lines: 'Lignes de facturations',
  },
  payable_bill: {
    supplier_id: 'Fournisseur',
  },
  invoice_line: {
    sending_date: 'Envoi',
    supplier_id: 'Fournisseur',
    wording: 'Libellé',
    account_split: "Nombre d'accompte",
    amount: 'Prix',
    invoice_lineable_id: 'Project',
    kind_of_cost_id: 'Type de coût',
    dev_activity_rate: 'Dev. %',
    web_activity_rate: 'Web. %',
    status_items: {
      not_attributed: 'Pas attribué',
      sent: 'Envoyé',
      attributed: 'Attribué',
    },
  },
  client_invoice_line: {
    status_items: {
      not_attributed: 'Pas attribué',
      sent: 'Envoyé',
      attributed: 'Attribué',
    },
  },
  settings: {
    date: 'Début de validité',
    daily_hours: 'Heures journ.',
    vacation_days: 'Congés',
    payment_days: 'Jours de paiement',
    avs_percent: '% AVS',
    ac_percent: '% AC',
    pc_percent: '% PC fam',
    aanp_percent: '% Ass. Acc. AANP',
    assmal_percent: '% Ass. Mal. Ind. Journ.',
  },
  public_holiday: {
    name: 'Nom',
    date: 'Date',
  },
  kind_of_cost: {
    name: 'Nom',
  },
  kind_of_service: {
    name: 'Nom',
  },
  service: {
    description: 'Description',
    kind_of_service_id: 'Type de service',
    default_amount: 'Prix',
  },
  kind_of_document: {
    letter: 'Lettre',
    name: 'Nom',
  },
  maintenance_period: {
    description: 'Description',
    start_date: 'Date de début',
    end_date: 'Date de fin',
    project: 'Projet',
  },
  notifications: {
    password_updated: 'Votre mot de passe a été modifié',
    refund_request_created: 'Votre note de frais a été envoyé',
    refund_request_updated: 'Votre note de frais a été modifiée',
    updated: 'Modification enregistrée',
    send: 'Demande envoyée',
    user_invited: 'Invitation envoyée',
    client_created: 'Le client a été créé',
    client_updated: 'Le client a été modifié',
    client_deleted: 'Le client a été supprimé',
    invoice_line_deleted: 'La ligne de facutration a été supprimé',
    supplier_created: 'Le fournisseur a été créé',
    supplier_updated: 'Le fournisseur a été modifié',
    off_day_created: 'Votre demande a été envoyée',
    off_day_updated: 'Votre demande a été modifiée',
    technology_created: 'La technologie a été créée',
    technology_updated: 'La technologie a été modifiée',
    product_created: 'Le produit a été créé',
    product_updated: 'Le produit a été modifié',
    maintenance_created: 'La maintenance a été créée',
    maintenance_updated: 'La maintenance a été modifiée',
    intervention_created: "L'intervention a été créée",
    intervention_updated: "L'intervention a été modifiée",
    settings_created: 'Les réglages ont été créés',
    settings_updated: 'Les réglages on été modifiées',
    public_holiday_created: 'Le jour ferié a été créé',
    public_holiday_updated: 'Le jour ferié a été modifié',
    kind_of_cost_created: 'Le type de coût a été créé',
    kind_of_cost_updated: 'Le type de coût a été modifié',
    kind_of_service_created: 'Le type de service a été créé',
    kind_of_service_updated: 'Le type de service a été modifié',
    kind_of_document_created: 'Le type de document a été créé',
    kind_of_document_updated: 'Le type de document a été modifié',
    receivable_bill_created: 'La facture a été créée',
    receivable_bill_updated: 'La facture a été modifiée',
    invoice_line_created: 'La ligne de facturation a été créée',
    invoice_line_updated: 'La ligne de facturation a été modifiée',
    salary_created:
      "T'aurais pas oublié le bonus ? (btw, le salaire a été créé)",
    salary_update: 'Salaire a été modifiée',
    charges_imported: '{{ number }} charges ont été importés avec succès',
    charges_ignored: "T'en un peu bête",
    contact_created: 'Le contact a été créé',
    contact_updated: 'Le contact a été modifié',
    contact_deleted: 'Le contact a été supprimé',
    service_created: 'Le service a été créé',
    service_updated: 'Le service a été modifié',
    prepaid_hour_created: "Le pack d'heure a été créé",
    prepaid_hour_updated: "Le pack d'heure a été modifié",
    spent_hour_created: "L'heure travaillé a été créé",
    spent_hour_updated: "L'heure travaillé a été modifié",
    impersonation_successful:
      'Vous êtes maintenant authentifié comme {{ first_name }}',
    impersonation_unsuccessful:
      "Vous n'avez pas pu vous authentifier comme {{ first_name }}",
    unmask_successful: 'Le démasquage est réussi',
    unmask_unsuccessful: 'Le démasquage a échoué',
  },
  datatables: {
    apply: 'Appliquer',
    show: 'Consulter',
    edit: 'Modifier',
    delete: 'Supprimer',
    loginAs: 'Login As',
    accept: 'Accepter',
    refuse: 'Refuser',
    download: 'Télécharger',
    body: {
      no_match: 'Aucun enregistrement trouvé 🤷',
      tool_tip: 'Trier',
      column_header_tooltip: 'Trier par {{item}}',
    },
    pagination: {
      next: 'Page suivante',
      previous: 'Page précédente',
      rows_per_page: 'Lignes par page:',
      display_rows: 'de',
      jump_to_page: 'Aller à la page',
    },
    toolbar: {
      search: 'Chercher',
      download_csv: 'CSV',
      print: 'Imprimer',
      view_columns: 'Afficher les colonnes',
      filter_table: 'Tableau des filtres',
    },
    filter: {
      all: 'Tous',
      title: 'FILTRES',
      reset: 'RÉINITIALISATION',
    },
    view_columns: {
      title: 'Afficher les colonnes',
      title_aria: 'Afficher/Cacher les colonnes',
    },
    selected_rows: {
      text: 'ligne(s) sélectionnée(s)',
      delete: 'Supprimer',
      delete_aria: 'Supprimer les lignes sélectionnées',
    },
    maintenances: {
      add: 'Ajouter une maintenance',
    },
    prepaid_hour: {
      add_spent_hour: 'Ajouter des heures travaillés',
    },
    tables: {
      maintenance_planning: {
        id: 'ID',
        technologies: 'Technologies',
        next_maintenance: 'Prochaine maintenance',
        planned_intervention: 'Intervention planifiée',
        missed_maintenances: 'Maintenance échue',
        product: {
          name: 'Nom',
        },
      },
      technologies: {
        id: 'ID',
        name: 'Nom',
      },
      off_days: {
        id: 'ID',
        user: {
          full_name: 'Employé',
        },
        reason: 'Raison',
        imputable: 'Prit sur',
        created_at: 'Date de demande',
        start_date: 'Du',
        end_date: 'Au',
        days_taken: 'Jours pris',
        justifications_info: 'Fichiers',
        status: 'Status',
      },
      client_invoice: {
        number: 'Numéro',
        product_name: 'Produit',
        sending_date: "Date d'envoi",
        payment_date: 'Date de paiement',
        state: 'Etat',
        amount: 'Montant',
        amount_ttc: 'Montant TTC',
      },
      refund_requests: {
        id: 'ID',
        user: {
          full_name: 'Employé',
        },
        description: 'Description',
        created_at: 'Date de demande',
        refund_request_date: 'Date de la facture',
        amount: 'Montant (CHF)',
        document_name: 'Nom du fichier',
        justifications_info: 'Fichiers',
        status: 'Status',
      },
      suppliers: {
        id: 'ID',
        name: 'Nom',
        paid_amount: 'Total payé',
      },
      payable_bills: {
        subject: 'Sujet',
        concern: 'Concerne',
        payment_date: 'Date de paiement',
        documents_info: 'Fichier',
        amount_ttc: 'Montant TTC',
      },
      works: {
        id: 'ID',
        number: '#',
        name: 'Nom',
        product_name: 'Produit',
        start_date: 'Date de début',
        state: 'État',
      },
      offers: {
        description: 'Description',
        full_number: 'Numéro',
        status: 'Status',
        documents_info: 'Fichiers',
        amount: 'Montant',
        created_at: 'Date de création',
      },
      users: {
        id: 'ID',
        first_name: 'Prénom',
        last_name: 'Nom',
        activity_rate: 'Pourcentage',
        holidays_balance: 'Solde de vacances',
        overtime_balance_base: 'Heures supp.',
        status: 'Status',
        role: 'Role',
        approved: 'Accès CRM',
        marital_status: 'Etat civil',
      },
      clients: {
        id: 'ID',
        number: '#',
        name: 'Nom',
      },
      products: {
        name: 'Nom',
        number: '#',
        created_at: 'Date de création',
        owner: 'Responsable',
      },
      maintenances: {
        name: 'Nom',
        number: '#',
        product_name: 'Produit',
        start_date: 'Date de début',
      },
      maintenances_late: {
        number: '#',
        in_arrears_of: 'Echéance',
        product_name: 'Produit',
        product: { client: { name: 'Client' } },
        end_date: 'Date de fin',
      },
      contacts: {
        full_name: 'Nom',
        email: 'Email',
        phone: 'Téléphone',
      },
      prepaid_hours: {
        name: 'Nom',
        number: '#',
        product_name: 'Produit',
        start_date: 'Date de début',
        price: 'Prix horaire',
        hours: "Nombre d'heures",
      },
      spent_hours: {
        name: 'Nom',
        request_date: 'Date de la requete',
        hours: "Nombre d'heures",
      },
      settings: {
        date: 'Date',
        daily_hours: 'Heures journ.',
        vacation_days: 'Congés',
        avs_percent: '% AVS',
        ac_percent: '% AC',
        pc_percent: '% PC',
        aanp_percent: '% AANP',
        assmal_percent: '% ASSMAL',
        vat: '% VAT',
        payment_days: 'Jours de paiment',
      },
      salaries: {
        date: 'Date',
        gross: 'Brute',
        bonus_amount: 'Bonus',
        net_salary: 'Net',
      },
      public_holiday: {
        name: 'Nom',
        date: 'Date',
      },
      kind_of_cost: {
        name: 'Nom',
      },
      kind_of_service: {
        name: 'Nom',
      },
      kind_of_document: {
        letter: 'Lettre',
        name: 'Nom',
      },
      client_invoice_lines: {
        wording: 'Libellé',
        product_name: 'Produit',
        amount: 'Montant',
        status: 'Etat',
      },
      receivable_bill: {
        reference: '#',
        product_name: 'Produit',
        client_name: 'Client',
        sending_date: "Date d'envoi",
        payment_deadline_days: 'Délai de paiement',
        payment_date: 'Date de paiement',
        amount: 'Montant HT',
        amount_ttc: 'Montant TTC',
      },
      invoice_lines: {
        number: 'Numéro',
        product_name: 'Produit',
        client_name: 'Client',
        wording: 'Libellé',
        amount: 'Montant',
        status: 'Etat',
      },
    },
  },
  dark_mode: 'Dark mode',
  empty: "Désolé c'est vide...",
}
