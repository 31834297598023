/**
 * The internal imports
 */
import api from '../index'

export default async ({ clientId, data }) => {
  const dataPost = new FormData()

  dataPost.append('client[name]', data.name)
  dataPost.append('client[description]', data.description)

  data.documentsToRemove.forEach(documentToRemove => {
    dataPost.append('client[documents_to_remove][]', documentToRemove)
  })

  data.documents.forEach(document => {
    dataPost.append('client[documents][]', document)
  })

  if (data.mailing_address) {
    Object.keys(data.mailing_address).forEach(key =>
      dataPost.append(
        `client[mailing_address_attributes][${key}]`,
        data.mailing_address[key],
      ),
    )
  }

  if (data.billing_address) {
    Object.keys(data.mailing_address).forEach(key =>
      dataPost.append(
        `client[billing_address_attributes][${key}]`,
        data.billing_address[key],
      ),
    )
  }

  const response = await api.put(`clients/${clientId}`, dataPost)
  return response.data
}
