import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import App from './App'
import { loadAnimation } from 'lottie-web'
import { defineLordIconElement } from 'lord-icon-element'

// register lottie and define custom element
defineLordIconElement(loadAnimation)
// Initialize languages
import './Translations'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Lean more: https://bit.ly/CRA-vitals
reportWebVitals()
