/**
 * The external imports
 */
import React from 'react'
import { TableCell, TableHead, TableRow } from '@mui/material'
import clsx from 'clsx'

/**
 * The internal imports
 */
import { shortDayOfWeek, isToday } from '../../Utils/Date'
import useStyles from '../../Theme/Pages/Absences/index.style'

const AbsencesTableHead = ({ columns, status }) => {
  const classes = useStyles()

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {columns.map((column, index) => (
          <TableCell
            key={`col-${index}`}
            className={clsx([
              classes[status(column)],
              classes.column,
              isToday(column) && classes.today,
              classes.day,
            ])}
          >
            {shortDayOfWeek(column)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default AbsencesTableHead
