/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { Button, MenuItem, Grid } from '@mui/material'
import clsx from 'clsx'
import { Add } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isFulfilled } from '@reduxjs/toolkit'
import { saveAs } from 'file-saver'

/**
 * The internal imports
 */
import { Loader } from '../../Components'
import { Datatable } from '../index'
import useLayout from '../../Theme/Layouts'
import GetAllInvoiceByClient from '../../Store/InvoiceLine/GetAllByClient'
import DownloadReceivableBill from '../../Store/ReceivableBill/Download'
import { openModal } from '../../Utils/Modal'
import { TableColumns } from '../../Config/TableColumns'
import CalculateParams from '../../Utils/CalculateParams'

const ClientInvoices = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const layout = useLayout()

  const client = useSelector(state => state.client.fetchOne.item)
  const { data, total } = useSelector(
    state => state.receivableBill.getAllByClient.item,
  )

  const receivableBillError = useSelector(
    state => state.receivableBill.getAllByClient.error,
  )
  const createReceivableBillLoading = useSelector(
    state => state.receivableBill.create.loading,
  )
  const updateReceivableBillLoading = useSelector(
    state => state.receivableBill.update.loading,
  )
  const destroyReceivableBillLoading = useSelector(
    state => state.receivableBill.destroy.loading,
  )

  const [loading, setLoading] = useState(false)
  const [tableState, setTableState] = useState({})
  const [allMenuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: ({ id }) =>
        openModal({
          type: 'formReceivableBill',
          id,
          options: { clientId: client.id },
        }),
    },
    {
      label: t('datatables.download'),
      columns: ['id', 'reference'],
      action: ({ id, reference }) => downloadBill(id, reference),
    },
    {
      label: t('datatables.delete'),
      columns: ['id'],
      type: 'delete',
      action: ({ id }) =>
        openModal({
          type: 'destroyReceivableBill',
          id,
        }),
    },
  ])
  const [excludedColumns] = useState(['id'])

  /**
   * Download generated bill
   */
  const downloadBill = async (id, reference) => {
    setLoading(true)
    const downloadReceivableBillResponse = await dispatch(
      DownloadReceivableBill.action({
        params: {},
        url: `receivable_bills/${id}/download`,
      }),
    )

    if (isFulfilled(downloadReceivableBillResponse)) {
      const blob = new Blob([downloadReceivableBillResponse.payload], {
        type: 'application/octet-stream',
      })
      saveAs(blob, `${reference}.pdf`)
    }
    setLoading(false)
  }

  /**
   * Shows "delete" option if invoice_line status is not_attributed
   * @param {*} tableMeta object
   * @param {*} menuOptions array
   * @param {*} setAnchor function
   * @returns array of filtered menu options
   */
  const customMenuFilter = (tableMeta, menuOptions, setAnchor) => {
    const statusColIndex = TableColumns.client_invoice.findIndex(
      column => column.label === 'sending_date',
    )
    const rowDataId =
      tableMeta.rowData[
        TableColumns.client_invoice.findIndex(column => column.label === 'id')
      ]

    const isDeletable = tableMeta.rowData[statusColIndex] === null
    const filteredMenuItems = []
    menuOptions.forEach(option => {
      if (
        (option.type === 'delete' && isDeletable) ||
        option.type !== 'delete'
      ) {
        const params = CalculateParams(option, tableMeta, 'receivable_bill')
        filteredMenuItems.push(
          <MenuItem
            key={rowDataId}
            onClick={() => {
              setAnchor({ element: null, id: null })
              option.action(params)
            }}
          >
            {option.label}
          </MenuItem>,
        )
      }
    })
    return filteredMenuItems
  }

  useEffect(async () => {
    if (
      !createReceivableBillLoading &&
      !updateReceivableBillLoading &&
      !destroyReceivableBillLoading
    ) {
      await dispatch(
        GetAllInvoiceByClient.action({
          params: { tableState },
          url: `clients/${client.id}/receivable_bills`,
        }),
      )
    }
  }, [
    tableState,
    createReceivableBillLoading,
    updateReceivableBillLoading,
    destroyReceivableBillLoading,
  ])

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={() =>
            openModal({
              type: 'formReceivableBill',
              clientId: client.id,
              options: { clientId: client.id },
            })
          }
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        data={data}
        total={total}
        hasFilters
        filterSource={`clients/${client.id}/receivable_bills`}
        tableState={tableState}
        setTableState={setTableState}
        source="client_invoice"
        error={receivableBillError}
        menuOptions={allMenuOptions}
        customMenuFilter={customMenuFilter}
        excludedColumns={excludedColumns}
        rowLink={({ id }) => `/invoices/${id}`}
        elevation={1}
      />
    </div>
  )
}

export default ClientInvoices
