/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  MenuItem,
  Grid,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import FetchAllService from '../../Services/FetchAll'
import CreateInvoiceLine from '../../Store/InvoiceLine/Create'
import UpdateInvoiceLine from '../../Store/InvoiceLine/Update'
import { Loader, TextField, Select } from '../../Components/index'
import FetchOneInvoiceLine from '../../Store/InvoiceLine/FetchOne'
import useForms from '../../Theme/Components/Forms'
import FetchOneMaintenancePeriod from '../../Store/MaintenancePeriod/FetchOne'
import { formatDate } from '../../Utils/Date'

const InvoiceLineForm = ({
  id,
  clientId,
  maintenanceId,
  maintenancePeriodId,
}) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { control, handleSubmit } = useForm()

  const [invoiceLine, setInvoiceLine] = useState({})
  const [loading, setLoading] = useState(true)
  const [projects, setProjects] = useState([])
  const [kindOfCosts, setKindOfCosts] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(async () => {
    const projectsResponse = await FetchAllService({
      url: `clients/${clientId}/projects`,
    })
    setProjects(projectsResponse)

    const kindOfCostResponse = await FetchAllService({ url: 'kind_of_costs' })
    setKindOfCosts(kindOfCostResponse)

    if (id) {
      const invoiceLineResponse = await dispatch(
        FetchOneInvoiceLine.action({
          url: `clients/${clientId}/invoice_lines/${id}`,
        }),
      )
      if (isFulfilled(invoiceLineResponse)) {
        setInvoiceLine(invoiceLineResponse.payload)
      }
    } else if (maintenanceId) {
      const newInvoiceLine = {
        kind_of_cost_id: kindOfCostResponse.find(c => c.name === 'Activité').id,
        invoice_lineable_id: maintenanceId,
      }
      const maintenancePeriodResponse = await dispatch(
        FetchOneMaintenancePeriod.action({
          url: `clients/${clientId}/maintenances/${maintenanceId}/maintenance_periods/${maintenancePeriodId}`,
        }),
      )
      if (isFulfilled(maintenancePeriodResponse)) {
        const { start_date, end_date } = maintenancePeriodResponse.payload
        newInvoiceLine.wording = `Du ${formatDate(
          new Date(start_date),
        )} au ${formatDate(new Date(end_date))}`
      }
      setInvoiceLine(newInvoiceLine)
    } else {
      setInvoiceLine({})
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new invoice line form to the db
   */
  const onSubmit = async data => {
    if (id) {
      const updateInvoiceLineResponse = await dispatch(
        UpdateInvoiceLine.action({
          url: `clients/${clientId}/invoice_lines/${id}`,
          params: { invoice_line: data },
        }),
      )
      if (isFulfilled(updateInvoiceLineResponse)) {
        if (updateInvoiceLineResponse.payload.success) {
          enqueueSnackbar(t('notifications.invoice_line_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateInvoiceLineResponse.payload.errors)
        }
      }
    } else {
      if (maintenancePeriodId) {
        data.invoice_lineable_type = 'MaintenancePeriod'
        data.invoice_lineable_id = maintenancePeriodId
      } else {
        data.invoice_lineable_type = 'Project'
      }
      const newInvoiceLineResponse = await dispatch(
        CreateInvoiceLine.action({
          url: `clients/${clientId}/invoice_lines`,
          params: { invoice_line: data },
        }),
      )
      if (isFulfilled(newInvoiceLineResponse)) {
        if (newInvoiceLineResponse.payload.success) {
          enqueueSnackbar(t('notifications.invoice_line_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newInvoiceLineResponse.payload.errors[0])
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.invoice_line_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={8}>
              <Controller
                name="wording"
                control={control}
                defaultValue={invoiceLine.wording || ''}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label={t('invoice_line.wording')}
                    name="wording"
                    error={errors.wording?.length > 0}
                    helperText={errors.wording && errors.wording[0]}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name="account_split"
                control={control}
                defaultValue="1"
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label={t('invoice_line.account_split')}
                    name="account_split"
                    error={errors.account_split?.length > 0}
                    helperText={errors.account_split && errors.account_split[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Controller
            name="invoice_lineable_id"
            control={control}
            defaultValue={invoiceLine.invoice_lineable_id}
            render={({ field }) => (
              <Select
                label={t('invoice_line.invoice_lineable_id')}
                name="invoice_lineable_id"
                id="invoice_lineable_id"
                error={errors.invoice_lineable_id?.length > 0}
                helperText={
                  errors.invoice_lineable_id && errors.invoice_lineable_id[0]
                }
                {...field}
              >
                {projects.map(project => (
                  <MenuItem key={`project-${project.id}`} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          <Controller
            name="kind_of_cost_id"
            control={control}
            defaultValue={invoiceLine.kind_of_cost_id}
            render={({ field }) => (
              <Select
                label={t('invoice_line.kind_of_cost_id')}
                name="kind_of_cost_id"
                id="kind_of_cost_id"
                error={errors.kind_of_cost_id?.length > 0}
                helperText={errors.kind_of_cost_id && errors.kind_of_cost_id[0]}
                {...field}
              >
                {kindOfCosts.map(kindOfCost => (
                  <MenuItem
                    key={`kind-of-cost-${kindOfCost.id}`}
                    value={kindOfCost.id}
                  >
                    {kindOfCost.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          <Controller
            name="amount"
            control={control}
            defaultValue={invoiceLine.amount || ''}
            render={({ field }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                label={t('invoice_line.amount')}
                name="amount"
                error={errors.amount?.length > 0}
                helperText={errors.amount && errors.amount[0]}
                {...field}
              />
            )}
          />

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="dev_activity_rate"
                control={control}
                defaultValue={invoiceLine.dev_activity_rate || ''}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label={t('invoice_line.dev_activity_rate')}
                    name="dev_activity_rate"
                    error={errors.dev_activity_rate?.length > 0}
                    helperText={
                      errors.dev_activity_rate && errors.dev_activity_rate[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="web_activity_rate"
                control={control}
                defaultValue={invoiceLine.web_activity_rate || ''}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label={t('invoice_line.web_activity_rate')}
                    name="web_activity_rate"
                    error={errors.web_activity_rate?.length > 0}
                    helperText={
                      errors.web_activity_rate && errors.web_activity_rate[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default InvoiceLineForm
