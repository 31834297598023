/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { Paper, Button, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import startOfWeek from 'date-fns/startOfISOWeek'
import endOfWeek from 'date-fns/endOfISOWeek'
import addDays from 'date-fns/addDays'
import subWeeks from 'date-fns/subWeeks'
import addWeeks from 'date-fns/addWeeks'
import isSameDay from 'date-fns/isSameDay'
import getISOWeek from 'date-fns/getISOWeek'
import { useDispatch, useSelector } from 'react-redux'

/**
 * The internal imports
 */
import { LordIcon } from '../../Components'
import FetchOneUser from '../../Store/User/FetchOne'
import useStyles from '../../Theme/Components/TimeSheet'
import { formatDate, formattedOvertime } from '../../Utils/Date'
import GetWeekTimeSheet from '../../Store/TimeSheet/GetWeek'
import Day from './Day'

export default () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const userId = useSelector(state => state.user.item.id)
  const currentUser = useSelector(state => state.user.fetchOne.item)
  const [selectedDate, handleDateChange] = useState(startOfWeek(new Date()))
  const timeSheets = useSelector(state => state.timeSheet.item.days)

  useEffect(async () => {
    await dispatch(FetchOneUser.action({ url: `users/${userId}` }))
  }, [])

  useEffect(() => {
    dispatch(GetWeekTimeSheet.action({ date: selectedDate }))
  }, [selectedDate])

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Button
          variant="outlined"
          size="large"
          className={classes.button}
          onClick={() => handleDateChange(subWeeks(selectedDate, 1))}
          startIcon={<LordIcon icon="left" scale="40" stroke="120" />}
        >
          {t('pages.time_sheets.week')} {getISOWeek(selectedDate) - 1}
        </Button>
        <Typography
          className={classes.dates}
          component="span"
          variant="h5"
          align="center"
        >
          {`${formatDate(selectedDate)} au ${formatDate(
            endOfWeek(selectedDate),
          )}`}
        </Typography>
        <Button
          variant="outlined"
          size="large"
          className={classes.buttonRight}
          endIcon={<LordIcon icon="right" scale="40" stroke="120" />}
          onClick={() => handleDateChange(addWeeks(selectedDate, 1))}
        >
          {t('pages.time_sheets.week')} {getISOWeek(selectedDate) + 1}
        </Button>
      </div>
      <Box className={classes.content}>
        <Box />
        <Box display="flex" justifyContent="center">
          <Typography align="center">{t('time_sheets.start_am')}</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography align="center">{t('time_sheets.end_am')}</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography align="center">{t('time_sheets.start_pm')}</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography align="center">{t('time_sheets.end_pm')}</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography align="center">{t('time_sheets.comment')}</Typography>
        </Box>
      </Box>
      {Array(5)
        .fill(0)
        .map((_, i) => {
          const data = timeSheets.find(timeSheet =>
            isSameDay(new Date(timeSheet.date), addDays(selectedDate, i)),
          )
          return <Day key={i} date={addDays(selectedDate, i)} data={data} />
        })}
      <div className={classes.header}>
        {currentUser.overtime_balance > 0 && (
          <div className={classes.buttonWrapper}>
            {formattedOvertime(currentUser.overtime_balance)}{' '}
            {t('pages.time_sheets.overtime')}
          </div>
        )}
      </div>
    </Paper>
  )
}
