/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import GetAllOffDays from '../../Store/OffDay/GetAll'
import { Datatable } from '../index'

const MyOffDays = ({ customMenuFilter, handleEditClick }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [excludedColumns] = useState(['id', 'user.full_name'])

  // Get values from the store
  const { data, total } = useSelector(state => state.offDay.getAll.item)

  const createOffDayLoading = useSelector(state => state.offDay.create.loading)
  const updateOffDayLoading = useSelector(state => state.offDay.update.loading)

  const [tableState, setTableState] = useState({})

  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      type: 'edit',
      columns: ['id'],
      action: params => handleEditClick({ id: params }),
    },
  ])

  useEffect(async () => {
    if (!createOffDayLoading && !updateOffDayLoading) {
      await dispatch(
        GetAllOffDays.action({
          params: { tableState },
          url: 'off_days',
        }),
      )
    }
  }, [tableState, createOffDayLoading, updateOffDayLoading])

  return (
    <Datatable
      source="off_days"
      data={data}
      total={total}
      tableState={tableState}
      setTableState={setTableState}
      menuOptions={menuOptions}
      customMenuFilter={customMenuFilter}
      hasFilters={true}
      excludedColumns={excludedColumns}
      elevation={1}
    />
  )
}

export default MyOffDays
