/**
 * The external imports
 */
import React from 'react'
import Lottie from 'react-lottie'
import { Grid } from '@mui/material'

/**
 * import
 */
import emptyData from '../Theme/Lotties/upload'

function UploadLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={8}
    >
      <Grid item xs={6}>
        <Lottie options={defaultOptions} />
      </Grid>
    </Grid>
  )
}
export default UploadLoader
