/**
 * The external imports
 */
import {
  buildAsyncState,
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import { UpdatePasswordAuthService } from '../../Services/Auth'

export default {
  initialState: buildAsyncState('updatePassword'),
  action: buildAsyncActions('auth/updatePassword', UpdatePasswordAuthService),
  reducers: buildAsyncReducers({
    errorKey: 'updatePassword.error',
    loadingKey: 'updatePassword.loading',
  }),
}
