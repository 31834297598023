import common from './Common'

export default {
  mode: 'light',
  ...common,
  primary: {
    light: '#EFEBDF',
    main: '#EAB83E',
    dark: '#A3802B',
    contrastText: '#343434',
  },
  secondary: {
    light: '#5D5D5D',
    main: '#5D5D5D',
    dark: '#343434',
  },
  error: {
    light: '#E57373',
    main: '#F44336',
    dark: '#D32F2F',
    contrastText: '#FFF',
  },
  background: {
    paper: '#f1f1ed',
    default: '#F9F9F9',
  },
  publicHoliday: {
    main: '#ADD8E650',
  },
  weekend: {
    main: '#FFB6C150',
  },
  holiday: {
    main: '#ff000050',
  },
  army: {
    main: '#78866b50',
  },
  wagon: {
    main: '#E60F0550',
  },
  additional_hour: {
    main: '#00800050',
  },
  sickness: {
    main: '#A52A2A50',
  },
  other: {
    main: '#80008050',
  },
  default: {
    main: 'transparent',
  },
  pendingAbsence: {
    main: '#D3D3D3',
  },
  timeSheetText: '#232323',
  grey: {
    200: '#EFEBDF',
    500: '#ECE2C899',
    800: '#D3D3D3',
  },
}
