/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Button, MenuItem } from '@mui/material'

/**
 * The internal imports
 */
import { Datatable } from '../index'
import useLayout from '../../Theme/Layouts'
import GetAllInvoiceLineByClient from '../../Store/InvoiceLine/GetAllByClient'
import { openModal } from '../../Utils/Modal'
import { TableColumns } from '../../Config/TableColumns'

const ClientInvoices = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const layout = useLayout()

  const client = useSelector(state => state.client.fetchOne.item)
  const { data, total } = useSelector(
    state => state.invoiceLine.getAllByClient.item,
  )

  const invoiceLineError = useSelector(
    state => state.invoiceLine.getAllByClient.error,
  )

  const createInvoiceLineLoading = useSelector(
    state => state.invoiceLine.create.loading,
  )

  const updateInvoiceLineLoading = useSelector(
    state => state.invoiceLine.update.loading,
  )

  const destroyInvoiceLineLoading = useSelector(
    state => state.invoiceLine.destroy.loading,
  )

  const [tableState, setTableState] = useState({})
  const [excludedColumns] = useState(['id'])
  const [allMenuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: ({ id }) =>
        openModal({
          type: 'formInvoiceLine',
          id,
          options: { clientId: client.id },
        }),
    },
    {
      label: t('datatables.delete'),
      columns: ['id'],
      type: 'delete',
      action: ({ id }) =>
        openModal({
          type: 'destroyInvoiceLine',
          id,
        }),
    },
  ])

  /**
   * Shows "delete" option if invoice_line status is not_attributed
   * @param {*} tableMeta object
   * @param {*} menuOptions array
   * @param {*} setAnchor function
   * @returns array of filtered menu options
   */
  const customMenuFilter = (tableMeta, menuOptions, setAnchor) => {
    const statusColIndex = TableColumns.client_invoice_lines.findIndex(
      column => column.label === 'status',
    )
    const rowDataId =
      tableMeta.rowData[
        TableColumns.client_invoice_lines.findIndex(
          column => column.label === 'id',
        )
      ]

    const isDeletable = tableMeta.rowData[statusColIndex] === 'not_attributed'
    const filteredMenuItems = []
    menuOptions.forEach(option => {
      if (
        (option.type === 'delete' && isDeletable) ||
        option.type !== 'delete'
      ) {
        filteredMenuItems.push(
          <MenuItem
            key={rowDataId}
            onClick={() => {
              setAnchor({ element: null, id: null })
              option.action({ id: rowDataId })
            }}
          >
            {option.label}
          </MenuItem>,
        )
      }
    })
    return filteredMenuItems
  }

  useEffect(async () => {
    if (
      !createInvoiceLineLoading &&
      !updateInvoiceLineLoading &&
      !destroyInvoiceLineLoading
    ) {
      await dispatch(
        GetAllInvoiceLineByClient.action({
          params: { tableState },
          url: `clients/${client.id}/invoice_lines`,
        }),
      )
    }
  }, [
    tableState,
    createInvoiceLineLoading,
    updateInvoiceLineLoading,
    destroyInvoiceLineLoading,
  ])

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={() =>
            openModal({
              type: 'formInvoiceLine',
              options: { clientId: client.id },
            })
          }
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>
      <Datatable
        data={data}
        total={total}
        hasFilters
        tableState={tableState}
        setTableState={setTableState}
        source="client_invoice_lines"
        error={invoiceLineError}
        menuOptions={allMenuOptions}
        excludedColumns={excludedColumns}
        filterSource={`clients/${client.id}/invoice_lines`}
        customMenuFilter={customMenuFilter}
        elevation={1}
      />
    </div>
  )
}

export default ClientInvoices
