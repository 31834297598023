/**
 * The internal imports
 */
import api from '../index'
import { Config } from '../../Config'

export default async ({ url, params }) => {
  const {
    tableState: {
      page = 0,
      rowsPerPage = Config.ROWS_PER_PAGE,
      filter = {},
      sort = {},
      search = '',
    },
    isAdmin = false,
  } = params

  if (isAdmin) {
    const response = await api.post(`${url}/admin_search`, {
      page,
      rowsPerPage,
      filter,
      sort,
      search,
    })
    return response.data
  }
  const response = await api.post(`${url}/search`, {
    page,
    rowsPerPage,
    filter,
    sort,
    search,
  })
  return response.data
}
