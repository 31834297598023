/**
 * The external imports
 */
import React from 'react'
import AsyncSelect from 'react-select/async'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'

/**
 * The internal imports
 */
import FetchAllService from '../../Services/FetchAll'
import useStyles from '../../Theme/Components/Search.style'

const Search = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  let timer = null

  /**
   * Redirects to the right page when option is selected
   * @param option
   */
  const handleChange = option => {
    window.location.href = option.url
  }

  /**
   * Returns the formatted group labels
   * @param data
   * @returns {JSX.Element}
   */
  const formatGroupLabel = data => (
    <div className={classes.group}>
      <span className={classes.groupText}>{data.label}</span>
      <span className={classes.groupBadge}>{data.options.length}</span>
    </div>
  )

  /**
   * Returns the formatted option labels
   * @param option
   * @returns {JSX.Element}
   */
  const formatOptionLabel = option => {
    if (option.type === 'client') {
      return option.label
    }
    return `${option.label} - ${option.client}`
  }

  /**
   * Executes the query to the backend with the search term
   * @param inputValue
   * @returns {Promise<[]>}
   */
  const handleInputChange = async inputValue => {
    return FetchAllService({ url: `search?term=${inputValue}` })
  }

  /**
   * Calls the fetcher method with a delay to wait until user stops typing
   * @param inputValue
   * @returns {Promise<unknown>}
   */
  const loadOptions = inputValue =>
    new Promise(resolve => {
      clearTimeout(timer)
      timer = setTimeout(() => resolve(handleInputChange(inputValue)), 500)
    })

  return (
    <div className={classes.wrapper}>
      <AsyncSelect
        loadOptions={loadOptions}
        formatGroupLabel={formatGroupLabel}
        formatOptionLabel={formatOptionLabel}
        onChange={handleChange}
        openMenuOnClick={false}
        closeMenuOnScroll
        placeholder={t('search.placeholder')}
        styles={{
          control: (styles, state) => ({
            ...styles,
            border: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? 0 : 0,
            backgroundColor: theme.palette.grey[800],
          }),
          valueContainer: styles => ({
            ...styles,
            backgroundColor: theme.palette.grey[800],
          }),
          input: styles => ({
            ...styles,
            color: theme.palette.primary.contrastText,
          }),
          loadingIndicator: styles => ({
            ...styles,
            color: theme.palette.primary.contrastText,
          }),
          menu: styles => ({
            ...styles,
            backgroundColor: theme.palette.grey[800],
          }),
          option: (styles, state) => ({
            ...styles,
            color: theme.palette.primary.contrastText,
            paddingLeft: 30,
            cursor: 'pointer',
            backgroundColor: state.isFocused
              ? theme.palette.grey[500]
              : 'transparent',
          }),
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  )
}

export default Search
