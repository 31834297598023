/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { Container, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import { Loader, Datatable } from '../../Components'
import FetchOneSupplier from '../../Store/Supplier/FetchOne'
import useLayout from '../../Theme/Layouts'
import useStyles from '../../Theme/Pages/PrepaidHours/Show.style'
import { formatNumber } from '../../Utils/Number'
import GetAllPayableBills from '../../Store/PayableBill/GetAll'
import { openModal } from '../../Utils/Modal'

const Supplier = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { t } = useTranslation()
  const layout = useLayout()
  const classes = useStyles()

  const supplier = useSelector(state => state.supplier.fetchOne.item)
  const supplierLoading = useSelector(state => state.supplier.fetchOne.loading)
  const { data, total } = useSelector(state => state.payableBill.getAll.item)

  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({})
  const [excludedColumns] = useState(['id'])
  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: ({ id }) =>
        openModal({
          type: 'editPayableBill',
          id,
        }),
    },
  ])

  useEffect(async () => {
    await dispatch(
      FetchOneSupplier.action({
        url: `suppliers/${id}`,
      }),
    )

    setLoading(false)
  }, [])

  useEffect(async () => {
    if (!supplierLoading && supplier) {
      await dispatch(
        GetAllPayableBills.action({
          params: { tableState },
          url: `suppliers/${id}/payable_bills`,
        }),
      )
    }
  }, [tableState, supplierLoading])

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Container maxWidth="xl">
      <div className={clsx(classes.titleWrapper, layout.breadcrumb)}>
        {supplier.name}
        <div className={layout.breadcrumbSeparator} />#{supplier.reference}
        <div className={layout.breadcrumbSeparator} />
        CHF {formatNumber(supplier.paid_amount)}
      </div>
      <Datatable
        data={data}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
        menuOptions={menuOptions}
        source="payable_bills"
        excludedColumns={excludedColumns}
        elevation={1}
      />
    </Container>
  )
}

export default Supplier
