/**
 * The external imports
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { isFulfilled } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
  MenuItem,
} from '@mui/material'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import CreateService from '../../Store/Service/Create'
import UpdateService from '../../Store/Service/Update'
import FetchOneService from '../../Store/Service/FetchOne'
import { Loader, Select, TextField } from '../index'
import useForms from '../../Theme/Components/Forms'
import { capitalize } from '../../Utils/String'
import FetchAllService from '../../Services/FetchAll'

const ServiceForm = ({ id, maintenanceId }) => {
  const formClasses = useForms()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [kindOfServices, setKindOfServices] = useState([])
  const [service, setService] = useState({})
  const [errors, setErrors] = useState({})

  const client = useSelector(state => state.client.fetchOne.item)

  useEffect(async () => {
    const kindOfServicesResponse = await FetchAllService({
      url: 'kind_of_services',
    })

    setKindOfServices(kindOfServicesResponse)

    if (id) {
      const serviceResponse = await dispatch(
        FetchOneService.action({
          url: `clients/${client.id}/maintenances/${maintenanceId}/services/${id}`,
        }),
      )
      if (isFulfilled(serviceResponse)) {
        const data = serviceResponse.payload
        setService(data)
      }
    }
    setLoading(false)
  }, [])

  /**
   * Handles validation and sends the new service form to the db
   */
  const onSubmit = async data => {
    const params = {
      service: {
        ...data,
      },
    }
    if (id) {
      const updateServiceResponse = await dispatch(
        UpdateService.action({
          url: `clients/${client.id}/maintenances/${maintenanceId}/services/${id}`,
          params,
        }),
      )
      if (isFulfilled(updateServiceResponse)) {
        if (updateServiceResponse.payload.success) {
          enqueueSnackbar(t('notifications.service_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateServiceResponse.payload.errors)
        }
      }
    } else {
      const newServiceResponse = await dispatch(
        CreateService.action({
          url: `clients/${client.id}/maintenances/${maintenanceId}/services`,
          params,
        }),
      )
      if (isFulfilled(newServiceResponse)) {
        if (newServiceResponse.payload.success) {
          enqueueSnackbar(t('notifications.service_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newServiceResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.service_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Divider />
        <CardContent>
          <Controller
            name="description"
            control={control}
            defaultValue={service.description || ''}
            render={({ field }) => (
              <TextField
                required
                id="description"
                label={t('service.description')}
                name="description"
                multiline
                rows={4}
                error={errors.description?.length > 0}
                helperText={errors.description && errors.description[0]}
                {...field}
              />
            )}
          />
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Controller
                name="kind_of_service_id"
                control={control}
                defaultValue={service.kind_of_service_id || null}
                render={({ field }) => (
                  <Select
                    label={t('service.kind_of_service_id')}
                    name="kind_of_service_id"
                    id="kind_of_service_id"
                    error={errors.kind_of_service_id?.length > 0}
                    helperText={
                      errors.kind_of_service_id && errors.kind_of_service_id[0]
                    }
                    {...field}
                  >
                    {kindOfServices.map(kindOfService => (
                      <MenuItem
                        key={`kindOfService-${kindOfService.id}`}
                        value={kindOfService.id}
                      >
                        {capitalize(kindOfService.name)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="default_amount"
                control={control}
                defaultValue={service.default_amount || ''}
                render={({ field }) => (
                  <TextField
                    required
                    id="default_amount"
                    label={t('service.default_amount')}
                    name="default_amount"
                    error={errors.default_amount?.length > 0}
                    helperText={
                      errors.default_amount && errors.default_amount[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={formClasses.submitAction}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(ToggleVisibility.action())}
            >
              {t('actions.close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default ServiceForm
