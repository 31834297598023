/**
 * The external imports
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@mui/material'

/**
 * The internal imports
 */
import { openModal } from '../../Utils/Modal'
import { formatNumber } from '../../Utils/Number'
import useStyles from '../../Theme/Components/Services/Table.style'

const ServiceTable = ({ rows }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  /**
   * Opens the formService modal to edit the service
   * @param {*} id integer
   */
  const editService = id => {
    openModal({
      type: 'formService',
      id,
    })
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('service.description')}</TableCell>
            <TableCell align="right">{t('service.default_amount')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow
              key={row.id}
              hover
              classes={{ hover: classes.hover }}
              onClick={() => editService(row.id)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.description}
              </TableCell>
              <TableCell align="right">
                {formatNumber(row.default_amount)} CHF
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ServiceTable
