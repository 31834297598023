/**
 * The external imports
 */
import {
  buildAsyncState,
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import CreateService from '../../Services/Datatable/Create'

export default {
  initialState: buildAsyncState('create'),
  action: buildAsyncActions('kindOfService/create', CreateService),
  reducers: buildAsyncReducers({
    itemKey: null,
    errorKey: 'create.error',
    loadingKey: 'create.loading',
  }),
}
