/**
 * The external imports
 */
import { Tooltip } from '@mui/material'
import {
  TrendingDown,
  TrendingFlat,
  TrendingUp,
  InfoOutlined,
} from '@mui/icons-material'
import clsx from 'clsx'
import getYear from 'date-fns/getYear'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

/**
 * The internal imports
 */
import useStyles from '../Theme/Components/Stats.style'
import useLayout from '../Theme/Layouts/index'
import { longMonth } from '../Utils/Date'
import { formatNumber } from '../Utils/Number'

const Stats = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const layout = useLayout()

  const [turnoverEvolution, setTurnoverEvolution] = useState(0)
  const [incomeEvolution, setIncomeEvolution] = useState(0)
  const [today] = useState(new Date())

  const stats = useSelector(state => state.dashboard.item)

  useEffect(() => {
    setTurnoverEvolution(calculateEvolutionPercentage('turnover'))
    setIncomeEvolution(calculateEvolutionPercentage('income'))
  }, [stats])

  /**
   * Calculates the percentage evolution from last year's values
   * @param {*} type string
   * @returns percentage
   */
  const calculateEvolutionPercentage = type => {
    let current_year = 0
    let last_year = 0
    if (type === 'turnover') {
      current_year = stats.turnover_current_year
      last_year = stats.turnover_last_year
    } else {
      current_year = stats.income_current_year
      last_year = stats.income_last_year
    }

    const evolution = current_year - last_year
    if (evolution === 0 || last_year === 0) {
      return 0
    }
    return ((evolution / last_year) * 100).toFixed(2)
  }

  /**
   * Defines the trending icon to display based on value
   * @param {*} value number
   * @returns JSX
   */
  const renderIcon = value => {
    if (value > 0) {
      return <TrendingUp />
    }
    if (value < 0) {
      return <TrendingDown />
    }
    return <TrendingFlat />
  }

  return (
    <div className={classes.statsContainer}>
      <div className={layout.card}>
        <div className={classes.statLabel}>
          {t('pages.dashboard.monthly_turnover', {
            month: longMonth(today),
            year: getYear(today),
          })}
        </div>
        <div className={classes.monthlyTurnoverWrapper}>
          <div className={classes.statValue}>
            {`${formatNumber(stats.monthly_turnover)} CHF`}
          </div>
          <Tooltip
            placement="right"
            classes={{ tooltip: classes.tooltipWrapper }}
            title={
              <div className={classes.contentWrapper}>
                <div>{`${t(
                  'pages.dashboard.dev_monthly_turnover',
                )}: ${formatNumber(stats.dev_monthly_turnover)} CHF`}</div>
                <div>{`${t(
                  'pages.dashboard.web_monthly_turnover',
                )}: ${formatNumber(stats.web_monthly_turnover)} CHF`}</div>
              </div>
            }
          >
            <InfoOutlined />
          </Tooltip>
        </div>
      </div>

      <div className={layout.card}>
        <div className={classes.statLabel}>
          {t('pages.dashboard.unpaid_bills')}
        </div>
        <div className={classes.statValue}>
          {`${formatNumber(stats.unpaid_bills)} CHF`}
        </div>
      </div>

      <div className={layout.card}>
        <div className={classes.statLabel}>
          {t('pages.dashboard.turnover_current_year', {
            year: getYear(today),
          })}
        </div>
        <div className={classes.percentageWrapper}>
          <div className={classes.statValue}>
            {`${formatNumber(stats.turnover_current_year)} CHF`}
          </div>
          <div
            className={clsx([
              classes.percentageValue,
              turnoverEvolution > 0 ? classes.positive : classes.negative,
            ])}
          >
            {turnoverEvolution} % {renderIcon(turnoverEvolution)}
          </div>
        </div>
      </div>

      <div className={layout.card}>
        <div className={classes.statLabel}>
          {t('pages.dashboard.monthly_income', {
            month: longMonth(today),
            year: getYear(today),
          })}
        </div>
        <div className={classes.statValue}>
          {`${formatNumber(stats.monthly_income)} CHF`}
        </div>
      </div>

      <div className={layout.card}>
        <div className={classes.statLabel}>
          {t('pages.dashboard.unpaid_late_bills')}
        </div>
        <div className={classes.statValue}>
          {`${formatNumber(stats.unpaid_late_bills)} CHF`}
        </div>
      </div>

      <div className={layout.card}>
        <div className={classes.statLabel}>
          {t('pages.dashboard.income_current_year', {
            year: getYear(today),
          })}
        </div>
        <div className={classes.percentageWrapper}>
          <div className={classes.statValue}>
            {`${formatNumber(stats.income_current_year)} CHF`}
          </div>
          <div
            className={clsx([
              classes.percentageValue,
              incomeEvolution > 0 ? classes.positive : classes.negative,
            ])}
          >
            {incomeEvolution} % {renderIcon(incomeEvolution)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stats
