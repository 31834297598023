/**
 * The external imports
 */
import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import { isFulfilled } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  CardActions,
  Box,
} from '@mui/material'

/**
 * The internal imports
 */
import useStyles from '../../Theme/Components/Forms'
import UpdatePasswordAuth from '../../Store/Auth/UpdatePassword'
import { TextField } from '../../Components'

const ConnectionInfo = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { control, handleSubmit } = useForm()

  // Get values from the store
  const currentUser = useSelector(state => state.user.item)
  const updatePasswordError = useSelector(
    state => state.auth.updatePassword.error,
  )
  const updatePasswordLoading = useSelector(
    state => state.auth.updatePassword.loading,
  )

  useEffect(() => {
    updatePasswordError &&
      typeof updatePasswordError.message === 'string' &&
      enqueueSnackbar(updatePasswordError.message, { variant: 'error' })
  }, [updatePasswordError])

  /**
   * Send update modification for password
   */
  const onSubmit = async ({ email, password, password_confirmation }) => {
    const updatePasswordResponse = await dispatch(
      UpdatePasswordAuth.action({ email, password, password_confirmation }),
    )

    if (isFulfilled(updatePasswordResponse)) {
      enqueueSnackbar(t('notifications.password_updated'), {
        variant: 'success',
      })
    }
  }

  return (
    <Card>
      <CardHeader title={t('pages.profile.connection_info')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Divider />
        <CardContent>
          <Controller
            name="email"
            control={control}
            defaultValue={currentUser.email}
            render={({ field }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                disabled
                id="email"
                label={t('user.email')}
                name="email"
                autoFocus
                {...field}
              />
            )}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('user.password')}
                    type="password"
                    id="password"
                    error={updatePasswordError?.message?.password?.length > 0}
                    helperText={
                      updatePasswordError?.message?.password &&
                      updatePasswordError?.message?.password[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="password_confirmation"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('user.password_confirmation')}
                    type="password"
                    id="password-confirmation"
                    error={
                      updatePasswordError?.message?.password_confirmation
                        ?.length > 0
                    }
                    helperText={
                      updatePasswordError?.message?.password_confirmation &&
                      updatePasswordError?.message?.password_confirmation[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={classes.submitAction}>
            <Button
              type="submit"
              disabled={updatePasswordLoading}
              variant="contained"
              size="large"
              color="primary"
            >
              {t('actions.save')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default ConnectionInfo
