/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import Download from './Download'

export default buildSlice('expense', [Download], {
  download: {},
}).reducer
