/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import { Datatable } from '../index'
import useLayout from '../../Theme/Layouts'
import GetAllProducts from '../../Store/Product/GetAll'
import { openModal } from '../../Utils/Modal'

const ClientProducts = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const layout = useLayout()

  const client = useSelector(state => state.client.fetchOne.item)
  const { data, total } = useSelector(state => state.product.getAll.item)

  const productsError = useSelector(state => state.product.getAll.error)
  const createProductLoading = useSelector(
    state => state.product.create.loading,
  )
  const updateProductLoading = useSelector(
    state => state.product.update.loading,
  )

  const [tableState, setTableState] = useState({})
  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      columns: ['id'],
      action: ({ id }) => openModal({ type: 'formProduct', id }),
    },
    {
      label: t('datatables.maintenances.add'),
      columns: ['id'],
      action: ({ id }) =>
        openModal({
          type: 'formMaintenance',
          options: { productId: id },
        }),
    },
  ])
  const [excludedColumns] = useState(['id'])

  useEffect(async () => {
    if (!createProductLoading && !updateProductLoading) {
      await dispatch(
        GetAllProducts.action({
          params: { tableState },
          url: `clients/${client.id}/products`,
        }),
      )
    }
  }, [tableState, createProductLoading, updateProductLoading])

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={() => openModal({ type: 'formProduct' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        data={data}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
        source="products"
        error={productsError}
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
      />
    </div>
  )
}

export default ClientProducts
