/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  CardActions,
  Box,
  IconButton,
  ButtonBase,
  Button,
} from '@mui/material'

/**
 * The internal imports
 */
import useStyles from '../Theme/Components/Justifications.style'
import ToggleVisibility from '../Store/Modal/ToggleVisibility'
import { Description } from '@mui/icons-material'
import FetchJustificationsService from '../Services/FetchJustifications'
import { Loader } from './index'

const JustificationsOffDays = ({ id, source }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [justifications, setJustifications] = useState([])

  useEffect(async () => {
    const justificationsResponse = await FetchJustificationsService(source, id)
    setJustifications(justificationsResponse)
    setLoading(false)
  }, [])

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.justifications.title')} />
      <Divider />
      <CardContent>
        {justifications.map(justification => (
          <ButtonBase
            className={classes.lineButton}
            onClick={() => window.open(justification.url, '_blank')}
          >
            <div>{justification.filename}</div>
            <IconButton size="large">
              <Description />
            </IconButton>
          </ButtonBase>
        ))}
      </CardContent>
      <Divider />
      <CardActions>
        <Box className={classes.submitAction}>
          <Button
            onClick={() => dispatch(ToggleVisibility.action())}
            variant="contained"
            size="large"
            color="primary"
          >
            {t('actions.close')}
          </Button>
        </Box>
      </CardActions>
    </Card>
  )
}

export default JustificationsOffDays
