/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { Button, Collapse, Container, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/InfoOutlined'

/**
 * The internal imports
 */
import {
  Tabs,
  ClientOffers,
  ClientDocuments,
  ClientInvoices,
  ClientWorks,
  ClientMaintenances,
  ClientInvoiceLines,
  ClientPrepaidHours,
  ClientProducts,
  ClientContacts,
  Loader,
  Error,
  AddressDisplay,
} from '../../Components'
import FetchOneClient from '../../Store/Client/FetchOne'
import { openModal } from '../../Utils/Modal'
import useLayout from '../../Theme/Layouts/index'
import useStyles from '../../Theme/Pages/Clients/Show.js'

const Client = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const layout = useLayout()

  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [expanded, setExpanded] = useState(false)

  const client = useSelector(state => state.client.fetchOne.item)
  const clientLoading = useSelector(state => state.client.fetchOne.loading)
  const clientError = useSelector(state => state.client.fetchOne.error)

  const tabs = [
    {
      label: t('pages.clients.show.projects'),
      content: <ClientWorks />,
      url: '/projects',
    },
    {
      label: t('pages.clients.show.maintenance'),
      content: <ClientMaintenances />,
      url: '/maintenances',
    },
    {
      label: t('pages.clients.show.prepaid_hours'),
      content: <ClientPrepaidHours />,
      url: '/prepaid_hours',
    },
    {
      label: t('pages.clients.show.invoices'),
      content: <ClientInvoices />,
      url: '/invoices',
    },
    {
      label: t('pages.clients.show.invoice_lines'),
      content: <ClientInvoiceLines />,
      url: '/invoice_lines',
    },
    {
      label: t('pages.clients.show.documents'),
      content: <ClientDocuments />,
      url: '/documents',
    },
    {
      label: t('pages.clients.show.offers'),
      content: <ClientOffers />,
      url: '/offers',
    },
    {
      label: t('pages.clients.show.products'),
      content: <ClientProducts />,
      url: '/products',
    },
    {
      label: t('pages.clients.show.contacts'),
      content: <ClientContacts />,
      url: '/contacts',
    },
  ]

  useEffect(async () => {
    if (id) {
      await dispatch(FetchOneClient.action({ url: `clients/${id}` }))
      setLoading(false)
    }
  }, [id])

  if (clientLoading || loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  if (clientError) {
    return <Error />
  }

  return (
    <Container maxWidth="xl">
      <div className={layout.spaceBetween}>
        <div className={layout.flexBaseline}>
          <Typography variant="h4" color="inherit">
            {`#${String(client.number).padStart(3, '0')} ${client.name}`}
          </Typography>
          <EditIcon
            onClick={() => openModal({ type: 'formClient', id })}
            classes={{ root: classes.edit }}
          />
          <Button
            onClick={() => setExpanded(!expanded)}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <InfoIcon />
          </Button>
        </div>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.infoWrapper}>
          <div className={classes.infoItem}>
            <AddressDisplay
              title={t('client.mailing_address')}
              address={client.mailing_address}
            />
          </div>
          {client.billing_address && (
            <div className={classes.infoItem}>
              <AddressDisplay
                title={t('client.billing_address')}
                address={client.billing_address}
              />
            </div>
          )}
          <div className={classes.infoItem}>
            <Typography color="primary" className={classes.infoTitle}>
              {t('pages.clients.show.average_payment_days')}
            </Typography>
            {t('pages.clients.show.average_payment_days_value', {
              value: Math.round(client.average_payment_days),
            })}
          </div>
          <div>
            <Typography color="primary" className={classes.infoTitle}>
              {t('pages.clients.show.description')}
            </Typography>
            <div className={classes.description}>{client.description}</div>
          </div>
        </div>
      </Collapse>
      <Tabs tabs={tabs} baseUrl={`/clients/${client.id}`} />
    </Container>
  )
}

export default Client
