/**
 * The external imports
 */
import React, { useState } from 'react'
import { isFulfilled } from '@reduxjs/toolkit'
import { IconButton } from '@mui/material'
import { Today } from '@mui/icons-material'
import DatePicker from '@mui/lab/DatePicker'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

/**
 * The external imports
 */
import UpdateReceivableBill from '../../Store/ReceivableBill/Update'

const Picker = ({ source, id, label }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())

  const onAccept = async newDate => {
    // TODO check if we need to generalize this
    const updateResponse = await dispatch(
      UpdateReceivableBill.action({
        url: `receivable_bills/${id}`,
        params: { receivable_bill: { [label]: newDate } },
      }),
    )
    if (isFulfilled(updateResponse)) {
      if (updateResponse.payload.success) {
        enqueueSnackbar(t(`notifications.${source}_updated`), {
          variant: 'success',
        })
        setSelectedDate(newDate)
      }
    }
  }

  return (
    <DatePicker
      open={open}
      onAccept={newDate => onAccept(newDate)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={selectedDate}
      onChange={setSelectedDate}
      TextFieldComponent={() => null}
      renderInput={({ inputRef, inputProps }) => (
        <IconButton
          ref={inputRef}
          onClick={() => setOpen(!open)}
          size="large"
          {...inputProps}
        >
          <Today />
        </IconButton>
      )}
    />
  )
}

export default Picker
