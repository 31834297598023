/**
 * The external imports
 */
import React from 'react'
import { Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Block, Check, Close, HourglassEmpty } from '@mui/icons-material'
/**
 * The internal imports
 */

import useStyles from '../../Theme/Components/AbsencesTable/Info.style'
import { formatDate } from '../../Utils/Date'

const Info = ({ offDay }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  /**
   * Returns the correct icon based on offDay status
   * @returns {JSX.Element|null}
   */
  const defineStatus = () => {
    switch (offDay.status) {
      case 'pending':
        return <HourglassEmpty className={classes.pending} />
      case 'accepted':
        return <Check className={classes.accepted} />
      case 'refused':
        return <Close className={classes.refused} />
      case 'cancelled':
        return <Block className={classes.cancelled} />
      default:
        return null
    }
  }

  return (
    <Paper className={classes.tooltip}>
      <div className={classes.headerWrapper}>
        <Typography variant="h5" className={classes.header}>
          {`${offDay.user.first_name} ${offDay.user.last_name}`}
        </Typography>
        {defineStatus()}
      </div>
      <Typography className={classes.tooltipInfo}>
        {`${formatDate(new Date(offDay.start_date))} - ${formatDate(
          new Date(offDay.end_date),
        )}`}
      </Typography>
      <Typography className={classes.tooltipInfo}>
        {t(`pages.absences.type.${offDay.imputable}`)}
      </Typography>
      <Typography className={classes.tooltipInfo}>{offDay.reason}</Typography>
    </Paper>
  )
}

export default Info
