/**
 * The external imports
 */
import React from 'react'
import Lottie from 'react-lottie'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

/**
 * import
 */
import emptyData from '../Theme/Lotties/empty'

function Empty() {
  const { t } = useTranslation()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={8}
    >
      <Grid item xs={6} alignItems="flex-end">
        <Typography variant="h5" align="center">
          {t('empty')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Lottie options={defaultOptions} />
      </Grid>
    </Grid>
  )
}
export default Empty
