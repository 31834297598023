/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { isFulfilled } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import DatePicker from '@mui/lab/DatePicker'
import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  CardActions,
  Box,
  MenuItem,
  Button,
} from '@mui/material'

/**
 * The internal imports
 */
import CreateMaintenancePeriod from '../../Store/MaintenancePeriod/Create'
import { TextField, Select, TextEditor, Loader } from '..'
import GetFilterableFieldsService from '../../Services/GetFilterableFields'
import useStyles from '../../Theme/Components/Forms'
import UpdateMaintenancePeriod from '../../Store/MaintenancePeriod/Update'
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import FetchOneMaintenancePeriod from '../../Store/MaintenancePeriod/FetchOne'

const FormMaintenancePeriod = ({ id, clientId, maintenanceId }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [filterableFields, setFilterableFields] = useState({})
  const [maintenancePeriod, setMaintenancePeriod] = useState({})
  const [errors, setErrors] = useState({})

  // Get values from the store
  const createMaintenancePeriodLoading = useSelector(
    state => state.maintenancePeriod.create.loading,
  )
  const updateMaintenancePeriodLoading = useSelector(
    state => state.maintenancePeriod.update.loading,
  )

  useEffect(async () => {
    const filterableFieldsResponse = await GetFilterableFieldsService(
      `clients/${clientId}/maintenances/${maintenanceId}/maintenance_periods`,
    )
    setFilterableFields(filterableFieldsResponse)
    if (id) {
      const maintenancePeriodResponse = await dispatch(
        FetchOneMaintenancePeriod.action({
          url: `clients/${clientId}/maintenances/${maintenanceId}/maintenance_periods/${id}`,
        }),
      )
      if (isFulfilled(maintenancePeriodResponse)) {
        setMaintenancePeriod(maintenancePeriodResponse.payload)
      }
    } else {
      setMaintenancePeriod({})
    }
    setLoading(false)
  }, [])

  /**
   * Create new or update existing maintenance period
   */
  const onSubmit = async params => {
    const start_date = new Date(params.start_date)
    const end_date = new Date(params.end_date)

    if (start_date > end_date) {
      setErrors({
        start_date: [t('components.off_days.validations.in_future')],
        end_date: [t('components.off_days.validations.in_future')],
      })
      return null
    } else {
      setErrors({})
    }

    const data = {
      ...params,
      start_date,
      end_date,
    }

    if (id) {
      const updateMaintenancePeriodResponse = await dispatch(
        UpdateMaintenancePeriod.action({
          url: `clients/${clientId}/maintenances/${maintenanceId}/maintenance_periods/${id}`,
          params: data,
        }),
      )
      if (isFulfilled(updateMaintenancePeriodResponse)) {
        if (updateMaintenancePeriodResponse.payload.success) {
          enqueueSnackbar(t('notifications.off_day_updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateMaintenancePeriodResponse.payload.errors)
        }
      }
    } else {
      const newMaintenancePeriodResponse = await dispatch(
        CreateMaintenancePeriod.action({
          url: `clients/${clientId}/maintenances/${maintenanceId}/maintenance_periods`,
          params: data,
        }),
      )
      if (isFulfilled(newMaintenancePeriodResponse)) {
        if (newMaintenancePeriodResponse.payload.success) {
          enqueueSnackbar(t('notifications.off_day_created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newMaintenancePeriodResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.maintenance_period_form.title')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Divider />
        <CardContent>
          <Controller
            name="description"
            control={control}
            defaultValue={maintenancePeriod.description?.body || ''}
            render={({ field }) => (
              <TextEditor value={field.value} onChange={field.onChange} />
            )}
          />
          <Grid container spacing={4} sx={{ mt: 0 }}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="start_date"
                control={control}
                defaultValue={maintenancePeriod.start_date || new Date()}
                render={({ field }) => (
                  <DatePicker
                    mask="__.__.____"
                    label={t('maintenance_period.start_date')}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={errors.start_date}
                        helperText={errors.start_date || ' '}
                      />
                    )}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="end_date"
                control={control}
                defaultValue={maintenancePeriod.end_date || new Date()}
                render={({ field }) => (
                  <DatePicker
                    mask="__.__.____"
                    label={t('maintenance_period.end_date')}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={errors.end_date}
                        helperText={errors.end_date || ' '}
                      />
                    )}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Controller
            name="project_id"
            control={control}
            defaultValue={maintenancePeriod.project_id || maintenanceId}
            render={({ field }) => (
              <Select
                name="project_id"
                id="project_id"
                label={t('maintenance_period.project')}
                error={errors.project_id?.length > 0}
                helperText={errors.project_id && errors.project_id[0]}
                {...field}
              >
                {filterableFields.projects.map(project => (
                  <MenuItem value={project.id}>{project.name}</MenuItem>
                ))}
              </Select>
            )}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={classes.submitAction}>
            <Button
              onClick={() => dispatch(ToggleVisibility.action())}
              variant="contained"
              size="large"
              color="secondary"
              disabled={
                createMaintenancePeriodLoading || updateMaintenancePeriodLoading
              }
            >
              {t('actions.cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={
                createMaintenancePeriodLoading || updateMaintenancePeriodLoading
              }
            >
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default FormMaintenancePeriod
