/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    rootLogin: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.secondary.main,
      minHeight: '100vh',
    },
    contentBackground: {
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      top: 0,
      height: '40vh',
      position: 'absolute',
    },
    headerLogin: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      flex: 1,
      zIndex: 0,
      top: 0,
      alignItems: 'flex-end',
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      overflow: 'auto',
      display: 'flex',
    },
    contentAuth: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    auth: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 10,
      flex: 1,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(4),
    },
    logo: {
      margin: theme.spacing(6),
      maxWidth: 500,
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: 'auto',
      flex: 1,
      bottom: 0,
      display: 'flex',
      backgroundColor: 'transparent',
      color: theme.palette.secondary,
      '& > div': {
        alignSelf: 'flex-end',
      },
    },
  }
})
export default useStyles
