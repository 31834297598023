/**
 * The external imports
 */
import React from 'react'
import { Typography, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import {
  Tabs,
  SettingsGeneral,
  SettingsTechnologies,
  SettingsKindOfCost,
  SettingsKindOfDocument,
  SettingsKindOfService,
  SettingsPublicHolidays,
} from '../../Components'

const Settings = () => {
  const { t } = useTranslation()

  const tabs = [
    {
      label: t('pages.settings.tabs.general'),
      url: '/general',
      content: <SettingsGeneral />,
    },
    {
      label: t('pages.settings.tabs.public_holidays'),
      url: '/public_holidays',
      content: <SettingsPublicHolidays />,
    },
    {
      label: t('pages.settings.tabs.services'),
      url: '/services',
      content: <SettingsKindOfService />,
    },
    {
      label: t('pages.settings.tabs.costs'),
      url: '/costs',
      content: <SettingsKindOfCost />,
    },
    {
      label: t('pages.settings.tabs.documents'),
      url: '/documents',
      content: <SettingsKindOfDocument />,
    },
    {
      label: t('pages.settings.tabs.technologies'),
      url: '/technologies',
      content: <SettingsTechnologies />,
    },
  ]

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" color="inherit" gutterBottom>
        {t('pages.settings.title')}
      </Typography>

      <Tabs tabs={tabs} baseUrl="/settings" />
    </Container>
  )
}

export default Settings
