/**
 * The external imports
 */
import React, { useState } from 'react'
import { ChevronLeft, ChevronRight, ExpandMore } from '@mui/icons-material'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import { fr } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Typography,
} from '@mui/material'

/**
 * The internal imports
 */
import { capitalize } from '../Utils/String'
import useStyles from '../Theme/Components/YearMonthPicker.style'

const YearMonthPicker = ({ selectedDate, setSelectedDate }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [localDate, setLocalDate] = useState(selectedDate)
  const [months] = useState(Array.from(Array(12).keys()))

  /**
   * Formats the selected date to a readable format
   * @returns {*}
   */
  const formatCurrentDate = () => {
    const { month, year } = selectedDate
    return capitalize(
      format(new Date(year, month, 1), 'MMMM - yyyy', { locale: fr }),
    )
  }

  /**
   * Toggles the anchorEl state value
   * @param event
   */
  const togglePicker = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  /**
   * Updates the local year value
   * @param direction
   */
  const updateLocalYear = direction => {
    const { day, month, year } = localDate
    setLocalDate({ day, month, year: year + direction })
  }

  /**
   * Updates the parent currentDate values
   * @param month
   */
  const onMonthClick = month => {
    setSelectedDate({ month, day: localDate.day, year: localDate.year })
  }

  /**
   * Sets the selected date to today's date
   */
  const goToToday = () => {
    setSelectedDate({
      day: getDay(new Date()),
      month: getMonth(new Date()),
      year: getYear(new Date()),
    })
  }

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Button onClick={togglePicker}>
          <Typography variant="h5">{formatCurrentDate()}</Typography>
          <ExpandMore />
        </Button>
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
          <Paper>
            <div className={classes.yearWrapper}>
              <IconButton onClick={() => updateLocalYear(-1)} size="large">
                <ChevronLeft />
              </IconButton>
              <Typography variant="h6">{localDate.year}</Typography>
              <IconButton onClick={() => updateLocalYear(1)} size="large">
                <ChevronRight />
              </IconButton>
            </div>
            <div className={classes.todayButtonWrapper}>
              <Button onClick={goToToday} className={classes.todayButton}>
                {t('pages.absences.type.today')}
              </Button>
            </div>

            <div className={classes.monthsWrapper}>
              {months.map(month => (
                <Button
                  key={`picker-month-${month}`}
                  onClick={() => onMonthClick(month)}
                >
                  {format(new Date(localDate.year, month, 1), 'MMM', {
                    locale: fr,
                  })}
                </Button>
              ))}
            </div>
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export default YearMonthPicker
