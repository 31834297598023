/**
 * The external imports
 */
import React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import clsx from 'clsx'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

/**
 * The internal imports
 */
import { openModal } from '../../Utils/Modal'
import useStyles from '../../Theme/Components/MaintenancePeriod/Card.style'
import { formatCurrency } from '../../Utils/Number'

const InvoiceLinesTable = ({ invoiceLines, maintenancePeriodId }) => {
  const { t } = useTranslation()
  const { clientId, maintenanceId } = useParams()
  const classes = useStyles()

  /**
   * Formats the status by adding a coloured badge and translation
   * @param {*} status string
   * @returns jsx
   */
  const displayStatus = status => {
    return (
      <div>
        <div className={clsx([classes.badgeStyle, classes[status]])} />
        &nbsp;
        <div className={classes.inlineFlex}>
          {t(`invoice_line.status_items.${status}`)}
        </div>
      </div>
    )
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Typography variant="h6">{t('receivable_bill.invoice_lines')}</Typography>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.tableHeader}>
              {t('datatables.tables.client_invoice_lines.wording')}
            </TableCell>
            <TableCell align="left" className={classes.tableHeader}>
              {t('datatables.tables.client_invoice_lines.amount')}
            </TableCell>
            <TableCell align="left" className={classes.tableHeader}>
              {t('datatables.tables.client_invoice_lines.status')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceLines.map(invoiceLine => (
            <TableRow
              key={invoiceLine.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" width="50%">
                {invoiceLine.wording}
              </TableCell>
              <TableCell align="left" width="20%">
                {formatCurrency(invoiceLine.amount)}
              </TableCell>
              <TableCell align="left" width="15%">
                {displayStatus(invoiceLine.status)}
              </TableCell>
              <TableCell align="right" width="15%">
                {invoiceLine.status !== 'sent' && (
                  <>
                    <EditIcon
                      onClick={() =>
                        openModal({
                          type: 'formInvoiceLine',
                          id: invoiceLine.id,
                          options: {
                            clientId,
                            maintenanceId,
                            maintenancePeriodId,
                          },
                        })
                      }
                      classes={{ root: classes.edit }}
                    />
                    <DeleteIcon
                      onClick={() =>
                        openModal({
                          type: 'destroyInvoiceLine',
                          id: invoiceLine.id,
                        })
                      }
                      classes={{ root: classes.edit }}
                    />
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default InvoiceLinesTable
