/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import GetAll from './GetAll'
import FetchOne from './FetchOne'
import Create from './Create'
import Update from './Update'
import Destroy from './Destroy'

export default buildSlice(
  'contact',
  [GetAll, Create, Update, FetchOne, Destroy],
  {
    getAll: { item: { data: [], total: 0 } },
    fetchOne: { item: {} },
    create: {},
    update: {},
    destroy: {},
  },
).reducer
