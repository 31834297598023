import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  selected: {
    backgroundColor: `${theme.palette.grey[500]} !important`,
    borderRight: `4px ${theme.palette.primary.main} solid`,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiListItemText-root': {
      color: theme.palette.primary.main,
      '& span': {
        fontWeight: 'bold',
      },
    },
  },
}))
export default useStyles
