/**
 * The external imports
 */
import React, { cloneElement } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
  ListItem as MUIListItem,
  ListItemIcon,
  ListItemText,
  Badge,
} from '@mui/material'

/**
 * The internal imports
 */
import useStyles from '../Theme/Components/ListItem'

function ListItem({ path, label, icon, onClick, notificationNumber = 0 }) {
  const location = useLocation()
  const classes = useStyles()

  return (
    <MUIListItem
      button
      onClick={onClick}
      selected={
        (location.pathname.startsWith(path) && path !== '/') ||
        location.pathname === path
      }
      classes={{ selected: classes.selected }}
      component={path ? RouterLink : null}
      to={path}
    >
      <ListItemIcon>
        <Badge badgeContent={notificationNumber} max={9} color="primary">
          {cloneElement(icon, { selected: location.pathname === path })}
        </Badge>
      </ListItemIcon>
      <ListItemText primary={label} />
    </MUIListItem>
  )
}
export default ListItem
