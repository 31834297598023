/**
 * The external imports
 */
import React from 'react'
import { Dialog as MuiDialog } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

/**
 * The internal imports
 */
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import {
  FormRefundRequest,
  FormUsers,
  FormOffDays,
  FormClient,
  EditUsers,
  FormSupplier,
  DeleteClient,
  Justifications,
  FormTechnology,
  FormProduct,
  FormWork,
  FormMaintenance,
  FormService,
  FormMaintenancePeriod,
  FormContact,
  DeleteContact,
  FormIntervention,
  FormOffer,
  FormPrepaidHour,
  FormSpentHour,
  FormReceivableBill,
  FormSettings,
  FormKindOfCost,
  FormKindOfDocument,
  FormKindOfService,
  FormPublicHolidays,
  FormInvoiceLine,
  FormSalary,
  FormSalaries,
  DeleteInvoiceLine,
  DeleteReceivableBill,
  EditPayableBill,
} from '../../Components'
import useStyles from '../../Theme/Components/Modal/Modal'

const Modal = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const visible = useSelector(state => state.modal.visible)
  const type = useSelector(state => state.modal.type)
  const id = useSelector(state => state.modal.id)
  const options = useSelector(state => state.modal.options)

  const renderBody = () => {
    const {
      productId,
      clientId,
      prepaidHourId,
      maintenanceId,
      date,
      technologies,
      employeeId,
      maintenancePeriodId,
    } = options
    switch (type) {
      case 'formRefundRequest':
        return <FormRefundRequest id={id} />
      case 'inviteUser':
        return <FormUsers />
      case 'editUser':
        return <EditUsers id={id} />
      case 'formOffDay':
        return <FormOffDays id={id} />
      case 'formWork':
        return <FormWork id={id} />
      case 'formClient':
        return <FormClient id={id} />
      case 'formOffer':
        return <FormOffer id={id} />
      case 'formSupplier':
        return <FormSupplier id={id} />
      case 'formTechnology':
        return <FormTechnology id={id} />
      case 'formProduct':
        return <FormProduct id={id} />
      case 'formMaintenance':
        return <FormMaintenance id={id} productId={productId} />
      case 'formService':
        return <FormService id={id} maintenanceId={maintenanceId} />
      case 'formMaintenancePeriod':
        return (
          <FormMaintenancePeriod
            id={id}
            clientId={clientId}
            maintenanceId={maintenanceId}
          />
        )
      case 'formContact':
        return <FormContact id={id} />
      case 'formPrepaidHour':
        return <FormPrepaidHour id={id} />
      case 'formSalary':
        return <FormSalary id={id} employeeId={employeeId} />
      case 'formSalaries':
        return <FormSalaries />
      case 'formSpentHour':
        return (
          <FormSpentHour
            id={id}
            clientId={clientId}
            prepaidHourId={prepaidHourId}
          />
        )
      case 'formReceivableBill':
        return <FormReceivableBill id={id} clientId={clientId} />
      case 'formInvoiceLine':
        return (
          <FormInvoiceLine
            id={id}
            clientId={clientId}
            maintenanceId={maintenanceId}
            maintenancePeriodId={maintenancePeriodId}
          />
        )
      case 'formIntervention':
        return (
          <FormIntervention
            id={id}
            maintenanceId={maintenanceId}
            date={date}
            technologies={technologies}
          />
        )
      case 'formSettings':
        return <FormSettings id={id} />
      case 'formKindOfCost':
        return <FormKindOfCost id={id} />
      case 'formKindOfDocument':
        return <FormKindOfDocument id={id} />
      case 'formKindOfService':
        return <FormKindOfService id={id} />
      case 'formPublicHoliday':
        return <FormPublicHolidays id={id} />
      case 'justificationsRefundRequest':
        return <Justifications id={id} source="refund_request" />
      case 'justificationsOffDays':
        return <Justifications id={id} source="off_days" />
      case 'destroyClient':
        return <DeleteClient id={id} />
      case 'destroyContact':
        return <DeleteContact id={id} clientId={clientId} />
      case 'destroyReceivableBill':
        return <DeleteReceivableBill id={id} />
      case 'destroyInvoiceLine':
        return <DeleteInvoiceLine id={id} />
      case 'editPayableBill':
        return <EditPayableBill id={id} />
      default:
        return null
    }
  }

  return (
    <MuiDialog
      open={visible}
      fullWidth
      maxWidth="md"
      onClose={() => dispatch(ToggleVisibility.action())}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.container}>{renderBody()}</div>
    </MuiDialog>
  )
}

export default Modal
