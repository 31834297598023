/**
 * The external imports
 */
import {
  buildAsyncState,
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import DownloadService from '../../Services/Datatable/Download'

export default {
  initialState: buildAsyncState('download'),
  action: buildAsyncActions('salary/download', DownloadService),
  reducers: buildAsyncReducers({
    itemKey: null,
    errorKey: 'download.error',
    loadingKey: 'download.loading',
  }),
}
