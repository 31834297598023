import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  listSubheader: {
    'text-transform': 'uppercase',
  },
  fixedBottom: {
    position: 'fixed',
    bottom: 0,
  },
}))
export default useStyles
