/**
 * The external imports
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ListSubheader, List, Divider, Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

/**
 * The internal imports
 */
import DestroySessionAuth from '../Store/Auth/DestroySession'
import { ListItem, LordIcon } from '../Components'
import useStyles from '../Theme/Components/Sidebar'
import { openModal } from '../Utils/Modal'
import Unmask from '../Services/Unmask'

const Sidebar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const classes = useStyles()
  const notifications = useSelector(state => state.notifications.getAll.item)
  const unmasked = useSelector(state => state.user.unmasked)

  const logout = () => {
    dispatch(DestroySessionAuth.action({}))
  }

  /**
   * Logs out the impersonated user and logs the original user back in
   */
  const unmask = async () => {
    const response = await Unmask({ url: `users/${unmasked.id}/unmask` })
    if (response.success) {
      enqueueSnackbar(t('notifications.unmask_successful'), {
        variant: 'success',
      })
      history.push('/users')
    } else {
      enqueueSnackbar(t('notifications.unmask_unsuccessful'), {
        variant: 'error',
      })
    }
  }

  const currentUserRole = useSelector(state => state.user.item.role)
  const isAdmin = currentUserRole === 'admin'

  return (
    <div>
      <Divider />
      <List>
        <ListSubheader inset className={classes.listSubheader}>
          <Box letterSpacing={4} fontWeight="fontWeightBold">
            {t('side_bar.general')}
          </Box>
        </ListSubheader>
        <ListItem
          path="/"
          label={t('side_bar.dashboard')}
          icon={<LordIcon icon="dashboard" />}
        />

        {isAdmin && (
          <ListItem
            path="/clients"
            label={t('side_bar.clients')}
            icon={<LordIcon icon="clients" />}
          />
        )}
        {isAdmin && (
          <ListItem
            path="/invoices"
            label={t('side_bar.invoices')}
            icon={<LordIcon icon="receivable_bill" />}
          />
        )}
        {isAdmin && (
          <ListItem
            path="/suppliers"
            label={t('side_bar.suppliers')}
            icon={<LordIcon icon="suppliers" />}
          />
        )}
        {isAdmin && (
          <ListItem
            path="/users"
            label={t('side_bar.users')}
            icon={<LordIcon icon="employees" />}
          />
        )}
        <ListItem
          path="/refund_requests"
          label={t('side_bar.refund_requests')}
          icon={<LordIcon icon="refund_requests" />}
          notificationNumber={notifications.refund_requests.length}
        />
        <ListItem
          path="/off-days"
          label={t('side_bar.off_days')}
          icon={<LordIcon icon="off_days" />}
          notificationNumber={notifications.off_days.length}
        />
        {isAdmin && (
          <ListItem
            path="/absences"
            label={t('side_bar.absences')}
            icon={<LordIcon icon="absences" />}
          />
        )}
        <ListItem
          path="/menu"
          label={t('side_bar.menu')}
          icon={<LordIcon icon="food" />}
        />
        {isAdmin && (
          <ListItem
            path="/maintenances"
            label={t('side_bar.maintenances')}
            icon={<LordIcon icon="maintenances" />}
          />
        )}
        {isAdmin && (
          <ListItem
            path="/statistics"
            label={t('side_bar.statistics')}
            icon={<LordIcon icon="statistics" />}
          />
        )}
        {isAdmin && (
          <ListItem
            path="/settings"
            label={t('side_bar.settings')}
            icon={<LordIcon icon="settings" />}
          />
        )}
        <ListItem
          path="/profile"
          label={t('side_bar.profile')}
          icon={<LordIcon icon="profile" />}
        />
      </List>
      <Divider />
      <List>
        <ListSubheader inset className={classes.listSubheader}>
          <Box letterSpacing={4} fontWeight="fontWeightBold">
            {t('side_bar.quick_actions')}
          </Box>
        </ListSubheader>
        <ListItem
          onClick={() => openModal({ type: 'formOffDay' })}
          label={t('side_bar.off_days')}
          icon={<LordIcon icon="plus" />}
        />
        <ListItem
          onClick={() => openModal({ type: 'formRefundRequest' })}
          label={t('side_bar.refund_requests')}
          icon={<LordIcon icon="plus" />}
        />
        {isAdmin && (
          <ListItem
            path="/charges"
            label={t('side_bar.import_charges')}
            icon={<LordIcon icon="import_charges" />}
          />
        )}
        {unmasked && Object.keys(unmasked).length > 0 ? (
          <ListItem
            onClick={unmask}
            label={t('side_bar.unmask')}
            icon={<LordIcon icon="exit" />}
          />
        ) : (
          <ListItem
            onClick={logout}
            path="/auth/sign-in"
            label={t('side_bar.logout')}
            icon={<LordIcon icon="exit" />}
          />
        )}
      </List>
    </div>
  )
}

export default Sidebar
