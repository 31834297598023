/**
 * The external imports
 */
import React from 'react'
import { useTheme } from '@mui/material/styles'

function LordIcon({ icon, selected, scale = '65', stroke = '80' }) {
  const theme = useTheme()

  return (
    <lord-icon
      colors={
        selected
          ? `primary:${theme.palette.primary.main},secondary:${theme.palette.primary.contrastText}`
          : `secondary:${theme.palette.primary.main},primary:${theme.palette.primary.contrastText}`
      }
      scale={scale}
      stroke={stroke}
      trigger="hover"
      target="a"
      src={`/icons/${icon}.json`}
    />
  )
}
export default LordIcon
