/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import clsx from 'clsx'
import { Add } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import GetAllPrepaidHours from '../../Store/PrepaidHour/GetAll'
import useLayout from '../../Theme/Layouts'
import { Datatable } from '../index'
import { openModal } from '../../Utils/Modal'

const ClientPrepaidHours = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const layout = useLayout()

  const client = useSelector(state => state.client.fetchOne.item)
  const { data, total } = useSelector(state => state.prepaidHour.getAll.item)
  const createPrepaidHourLoading = useSelector(
    state => state.prepaidHour.create.loading,
  )
  const updatePrepaidHourLoading = useSelector(
    state => state.prepaidHour.update.loading,
  )

  const [tableState, setTableState] = useState({})
  const [menuOptions] = useState([
    {
      label: t('datatables.show'),
      columns: ['id'],
      action: ({ id }) =>
        history.push(`/clients/${client.id}/prepaid_hours/${id}`),
    },
    {
      label: t('datatables.edit'),
      columns: ['id', 'product.id'],
      action: params =>
        openModal({
          type: 'formPrepaidHour',
          id: params.id,
          options: { productId: params['product.id'] },
        }),
    },
    {
      label: t('datatables.prepaid_hour.add_spent_hour'),
      columns: ['id'],
      action: ({ id }) =>
        openModal({
          type: 'formSpentHour',
          options: { clientId: client.id, prepaidHourId: id },
        }),
    },
  ])
  const [excludedColumns] = useState(['id', 'product_id'])

  useEffect(async () => {
    if (!createPrepaidHourLoading && !updatePrepaidHourLoading) {
      await dispatch(
        GetAllPrepaidHours.action({
          params: { tableState },
          url: `clients/${client.id}/prepaid_hours`,
        }),
      )
    }
  }, [tableState, createPrepaidHourLoading, updatePrepaidHourLoading])

  return (
    <div>
      <div className={clsx(layout.row, layout.flex)}>
        <Button
          onClick={() => openModal({ type: 'formPrepaidHour' })}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={layout.mb}
        >
          {t('actions.add')}
        </Button>
      </div>

      <Datatable
        data={data}
        total={total}
        rowLink={({ id }) => `/clients/${client.id}/prepaid_hours/${id}`}
        tableState={tableState}
        setTableState={setTableState}
        source="prepaid_hours"
        menuOptions={menuOptions}
        excludedColumns={excludedColumns}
        elevation={1}
        hasFilters
        filterSource={`clients/${client.id}/prepaid_hours`}
      />
    </div>
  )
}

export default ClientPrepaidHours
