/**
 * The external imports
 */
import { buildSlice } from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import GetAll from './GetAll'
import FetchOne from './FetchOne'
import Create from './Create'
import Update from './Update'

export default buildSlice(
  'kindOfDocument',
  [GetAll, Create, Update, FetchOne],
  {
    getAll: { item: {} },
    fetchOne: { item: {} },
    create: {},
    update: {},
  },
).reducer
