/**
 * The external imports
 */
import React from 'react'
import { MenuItem, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'

/**
 * The internal imports
 */
import { Select, TextField } from '../index'
import { Config } from '../../Config'

const AddressForm = ({ control, addressType, errors, data }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Controller
        name={`${addressType}.id`}
        control={control}
        defaultValue={data[addressType]?.id || ''}
        render={({ field }) => <input type="hidden" {...field} />}
      />
      <Controller
        name={`${addressType}.attention_of`}
        control={control}
        defaultValue={data[addressType]?.attention_of || ''}
        render={({ field }) => (
          <TextField
            id="attention_of"
            label={t('address.attention_of')}
            name="attention_of"
            error={errors[`${addressType}.attention_of`]?.length > 0}
            helperText={
              errors[`${addressType}.attention_of`] &&
              errors[`${addressType}.attention_of`][0]
            }
            {...field}
          />
        )}
      />
      <Controller
        name={`${addressType}.street`}
        control={control}
        defaultValue={data[addressType]?.street || ''}
        render={({ field }) => (
          <TextField
            required
            id="street"
            label={t('address.street')}
            name="street"
            error={errors[`${addressType}.street`]?.length > 0}
            helperText={
              errors[`${addressType}.street`] &&
              errors[`${addressType}.street`][0]
            }
            {...field}
          />
        )}
      />
      <Controller
        name={`${addressType}.additional`}
        control={control}
        defaultValue={data[addressType]?.additional || ''}
        render={({ field }) => (
          <TextField
            id="additional"
            label={t('address.additional')}
            name="additional"
            error={errors[`${addressType}.additional`]?.length > 0}
            helperText={
              errors[`${addressType}.additional`] &&
              errors[`${addressType}.additional`][0]
            }
            {...field}
          />
        )}
      />
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Controller
            name={`${addressType}.zip_code`}
            control={control}
            defaultValue={data[addressType]?.zip_code || ''}
            render={({ field }) => (
              <TextField
                required
                id="zip_code"
                label={t('address.zip_code')}
                name="zip_code"
                type="number"
                error={errors[`${addressType}.zip_code`]?.length > 0}
                helperText={
                  errors[`${addressType}.zip_code`] &&
                  errors[`${addressType}.zip_code`][0]
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name={`${addressType}.city`}
            control={control}
            defaultValue={data[addressType]?.city || ''}
            render={({ field }) => (
              <TextField
                required
                id="city"
                label={t('address.city')}
                name="city"
                error={errors[`${addressType}.city`]?.length > 0}
                helperText={
                  errors[`${addressType}.city`] &&
                  errors[`${addressType}.city`][0]
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name={`${addressType}.country`}
            control={control}
            defaultValue={data[addressType]?.country || ''}
            render={({ field }) => (
              <Select
                label={t('address.country')}
                error={errors[`${addressType}.country`]?.length > 0}
                helperText={
                  errors[`${addressType}.country`] &&
                  errors[`${addressType}.country`][0]
                }
                {...field}
              >
                {Config.COUNTRIES.map(country => (
                  <MenuItem key={`user-${country}`} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default AddressForm
