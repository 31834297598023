/**
 * The external imports
 */
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  today: {
    textDecoration: 'underline',
    textDecorationThickness: 5,
    textDecorationColor: theme.palette.primary.main,
  },
  media: {
    height: 200,
  },
  content: {
    minHeight: 250,
  },
}))

export default useStyles
