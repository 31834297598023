/**
 * The external imports
 */
import React from 'react'
import format from 'date-fns/format'
import isWeekend from 'date-fns/isWeekend'
import { TableBody, TableCell, TableRow, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BeachAccess, Restore } from '@mui/icons-material'
import clsx from 'clsx'

/**
 * The internal imports
 */
import { dateIsBetween } from '../../Utils/Date'
import useStyles from '../../Theme/Pages/Absences/index.style'
import { Info } from '../index'

const AbsencesTableBody = ({
  columns,
  status,
  offDays,
  employeeList,
  findOffDay,
  isPublicHoliday,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  /**
   * Calculates the total absences for each date
   * @param date
   * @returns {string|number}
   */
  const calculateTotal = date => {
    if (isWeekend(date)) {
      return ''
    } else if (isPublicHoliday(date)) {
      return employeeList.length
    } else {
      let count = 0
      offDays.forEach(offDay => {
        const start_date = new Date(offDay.start_date)
        const end_date = new Date(offDay.end_date)
        if (dateIsBetween(date, start_date, end_date)) {
          count += 1
        }
      })
      return count
    }
  }

  /**
   * Disables the tooltip for weekends, publicHolidays and empty days
   * @param column
   * @param employeeId
   * @returns {boolean}
   */
  const disableTooltip = (column, employeeId) => {
    return ['weekend', 'publicHoliday', 'default'].includes(
      status(column, employeeId),
    )
  }

  return (
    <TableBody>
      {employeeList.map((employee, employeeIndex) => {
        const isLastRow = employeeIndex === employeeList.length - 1
        return (
          <TableRow key={employee.id}>
            <TableCell className={isLastRow ? classes.lastTableRow : null}>
              <div className={classes.employeeName}>{employee.full_name}</div>
              <div className={classes.balancesWrapper}>
                <div className={classes.balanceInnerWrapper}>
                  <BeachAccess className={classes.balanceIcons} />
                  {employee.holidays_balance}
                </div>
                <div className={classes.balanceInnerWrapper}>
                  <Restore className={classes.balanceIcons} />
                  {employee.overtime_balance_base}
                </div>
              </div>
            </TableCell>
            {columns.map((column, columnIndex) => (
              <Tooltip
                key={`cell-${employee.id}-${columnIndex}`}
                classes={{ tooltip: classes.tooltipWrapper }}
                disableHoverListener={disableTooltip(column, employee.id)}
                title={<Info offDay={findOffDay(column, employee.id)} />}
                placement="top"
              >
                <TableCell
                  className={clsx([
                    classes.day,
                    classes[status(column, employee.id)],
                    isLastRow && classes.lastTableRow,
                  ])}
                  align="center"
                >
                  {format(column, 'd')}
                </TableCell>
              </Tooltip>
            ))}
          </TableRow>
        )
      })}
      <TableRow>
        <TableCell className={classes.employeeName}>
          {t('pages.absences.total')}
        </TableCell>
        {columns.map((column, index) => (
          <TableCell
            key={`footer-cell-${index}`}
            className={clsx([
              classes.totalRow,
              classes[status(column)],
              classes.day,
            ])}
            align="center"
          >
            {calculateTotal(column)}
          </TableCell>
        ))}
      </TableRow>
    </TableBody>
  )
}

export default AbsencesTableBody
