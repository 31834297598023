/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { isFulfilled } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  CardActions,
  Box,
  MenuItem,
  Button,
} from '@mui/material'

/**
 * The internal imports
 */
import CreateOffer from '../../Store/Offer/Create'
import GetFilterableFieldsService from '../../Services/GetFilterableFields'
import { TextField, Select, FileUploader, Loader } from '../../Components'
import useStyles from '../../Theme/Components/Forms'
import UpdateOffer from '../../Store/Offer/Update'
import ToggleVisibility from '../../Store/Modal/ToggleVisibility'
import FetchOneOffer from '../../Store/Offer/FetchOne'

const FormOffer = ({ id }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [filterableFields, setFilterableFields] = useState({})
  const [offer, setOffer] = useState({})
  const [documents, setDocuments] = useState([])
  const [documentsToRemove, setDocumentsToRemove] = useState([])
  const [errors, setErrors] = useState({})

  // Get values from the store
  const clientId = useSelector(state => state.client.fetchOne.item.id)
  const createOfferLoading = useSelector(state => state.offer.create.loading)
  const updateOfferLoading = useSelector(state => state.offer.update.loading)

  useEffect(async () => {
    const filterableFieldsResponse = await GetFilterableFieldsService('offers')
    setFilterableFields(filterableFieldsResponse)
    if (id) {
      const offerResponse = await dispatch(
        FetchOneOffer.action({ url: `clients/${clientId}/offers/${id}` }),
      )
      if (isFulfilled(offerResponse)) {
        setOffer(offerResponse.payload)
        setDocuments(
          offerResponse.payload.documents_info.map(document => ({
            id: document.id,
            path: document.filename,
          })),
        )
      }
    } else {
      setOffer({})
    }
    setLoading(false)
  }, [])

  /**
   * Create new offer
   */
  const onSubmit = async params => {
    const data = {
      ...params,
      documents: documents.filter(document => !document.id),
    }
    if (id) {
      data.documentsToRemove = documentsToRemove
      const updateOfferResponse = await dispatch(
        UpdateOffer.action({ clientId, offerId: id, params: data }),
      )
      if (isFulfilled(updateOfferResponse)) {
        if (updateOfferResponse.payload.success) {
          enqueueSnackbar(t('notifications.updated'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(updateOfferResponse.payload.errors)
        }
      }
    } else {
      const newOfferResponse = await dispatch(
        CreateOffer.action({ clientId, params: data }),
      )
      if (isFulfilled(newOfferResponse)) {
        if (newOfferResponse.payload.success) {
          enqueueSnackbar(t('notifications.created'), {
            variant: 'success',
          })
          dispatch(ToggleVisibility.action())
        } else {
          setErrors(newOfferResponse.payload.errors)
        }
      }
    }
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={t('components.offer.form')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Divider />
        <CardContent>
          <Controller
            name="status"
            control={control}
            defaultValue={offer.status}
            render={({ field }) => (
              <Select
                label={t('offer.status')}
                name="imputable"
                id="imputable"
                error={errors.status?.length > 0}
                helperText={errors.status && errors.status[0]}
                {...field}
              >
                {filterableFields.status.map(statusItem => (
                  <MenuItem value={statusItem}>
                    {t(`offer.status_items.${statusItem}`)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="dev_amount"
                control={control}
                defaultValue={offer.dev_amount || ''}
                render={({ field }) => (
                  <TextField
                    label={t('offer.dev_amount')}
                    name="dev_amount"
                    required
                    error={errors.dev_amount?.length > 0}
                    helperText={errors.dev_amount && errors.dev_amount[0]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="maintenance_amount"
                control={control}
                defaultValue={offer.maintenance_amount || ''}
                render={({ field }) => (
                  <TextField
                    label={t('offer.maintenance_amount')}
                    name="maintenance_amount"
                    required
                    error={errors.maintenance_amount?.length > 0}
                    helperText={
                      errors.maintenance_amount && errors.maintenance_amount[0]
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Controller
            name="description"
            control={control}
            defaultValue={offer.description || ''}
            render={({ field }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                label={t('offer.description')}
                name="description"
                multiline
                rows={4}
                error={errors.description?.length > 0}
                helperText={errors.description && errors.description[0]}
                {...field}
              />
            )}
          />

          <FileUploader
            files={documents}
            setFiles={setDocuments}
            filesToRemove={documentsToRemove}
            setFilesToRemove={setDocumentsToRemove}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box className={classes.submitAction}>
            <Button
              onClick={() => dispatch(ToggleVisibility.action())}
              variant="contained"
              size="large"
              color="secondary"
              disabled={createOfferLoading || updateOfferLoading}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={createOfferLoading || updateOfferLoading}
            >
              {t('actions.validate')}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default FormOffer
