/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

/**
 * The internal imports
 */
import GetAllOffDays from '../../Store/OffDay/GetAll'
import { Datatable } from '../index'
import { Config } from '../../Config'

const AllOffDays = ({
  customMenuFilter,
  handleEditClick,
  defaultFilters = {},
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [excludedColumns] = useState(['id'])

  // Get values from the store
  const { data, total } = useSelector(state => state.offDay.getAll.item)
  const createOffDayLoading = useSelector(state => state.offDay.create.loading)
  const updateOffDayLoading = useSelector(state => state.offDay.update.loading)

  const [tableState, setTableState] = useState({
    page: 0,
    rowsPerPage: Config.ROWS_PER_PAGE,
    filter: { ...defaultFilters },
    sort: {},
    search: '',
  })

  const [menuOptions] = useState([
    {
      label: t('datatables.edit'),
      type: 'edit',
      columns: ['id'],
      action: params => handleEditClick({ id: params }),
    },
  ])

  useEffect(async () => {
    if (!createOffDayLoading && !updateOffDayLoading) {
      await dispatch(
        GetAllOffDays.action({
          params: { tableState, isAdmin: true },
          url: 'off_days',
        }),
      )
    }
  }, [tableState, createOffDayLoading, updateOffDayLoading])

  return (
    <Datatable
      source="off_days"
      data={data}
      total={total}
      tableState={tableState}
      setTableState={setTableState}
      menuOptions={menuOptions}
      customMenuFilter={customMenuFilter}
      hasFilters={true}
      excludedColumns={excludedColumns}
      elevation={1}
    />
  )
}

export default AllOffDays
