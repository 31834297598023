import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  optionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  submitButton: {
    marginLeft: 20,
  },
  intervalField: {
    width: 50,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
  },
  sectorSelect: {
    marginLeft: 10,
    marginRight: 10,
    width: 150,
  },
  flexSeparator: {
    flexGrow: 1,
  },
  tableHeader: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 18,
  },
  yearPicker: {
    alignSelf: 'flex-end',
  },
  tableRow: {
    '&:hover': {
      borderTopWidth: 1.5,
      borderBottomWidth: 1.5,
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      borderTopColor: theme.palette.primary.main,
      borderBottomColor: theme.palette.primary.main,
    },
  },
  totalsRow: {
    borderTopWidth: 1.5,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.primary.main,
  },
  mainRow: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  subRowTitle: {
    paddingLeft: 40,
  },
  positive: {
    color: theme.palette.success.main,
  },
  negative: {
    color: theme.palette.error.main,
  },
}))
export default useStyles
