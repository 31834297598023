/**
 * The external imports
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import format from 'date-fns/format'
import { isFulfilled } from '@reduxjs/toolkit'

/**
 * The internal imports
 */
import useStyles from '../../Theme/Components/TimeSheet'
import { TextField, Box, Typography } from '@mui/material'
import {
  minToString,
  stringToMin,
  isToday,
  dayAndMonth,
  formattedOvertime,
} from '../../Utils/Date'
import UpdateTimeSheet from '../../Store/TimeSheet/Update'
import FetchOneUser from '../../Store/User/FetchOne'

export default ({ date, data }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const userId = useSelector(state => state.user.item.id)
  const [workingDay, setWorkingDay] = useState({
    date,
    am_start: '',
    am_end: '',
    pm_start: '',
    pm_end: '',
  })

  useEffect(() => {
    if (data) {
      setWorkingDay(data)
    } else {
      setWorkingDay({
        date: format(date, 'yyyy-MM-dd'),
        overtime_balance: 0,
        am_start: '',
        am_end: '',
        pm_start: '',
        pm_end: '',
        comment: '',
      })
    }
  }, [data])

  /**
   * Updates the local working day data
   * @param {*} field string
   * @param {*} value string
   */
  const updateWorkingDay = async (field, value) => {
    setWorkingDay(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  /**
   * updates the database then updates the overtime calculated by the database
   */
  const saveWorkingDay = async event => {
    if (event.target.value !== '') {
      const result = await dispatch(
        UpdateTimeSheet.action({
          url: 'working_days/create_or_update',
          params: workingDay,
        }),
      )

      if (isFulfilled(result)) {
        await dispatch(FetchOneUser.action({ url: `users/${userId}` }))
        setWorkingDay({
          ...workingDay,
          overtime_balance: result.payload.overtime_balance || 0,
        })
      }
    }
  }

  return (
    <Box
      className={`${classes.content} ${isToday(date) ? classes.today : null}`}
    >
      <Box>
        {dayAndMonth(date)}
        <br />
        <Typography
          component="span"
          variant="subtitle1"
          className={
            workingDay.overtime_balance >= 0
              ? classes.positive
              : classes.negative
          }
        >
          {'overtime_balance' in workingDay &&
            workingDay.overtime_balance !== 0 &&
            formattedOvertime(workingDay.overtime_balance)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <TextField
          type="time"
          variant="outlined"
          value={minToString(workingDay.am_start)}
          onChange={e =>
            updateWorkingDay('am_start', stringToMin(e.target.value))
          }
          onBlur={saveWorkingDay}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <TextField
          type="time"
          variant="outlined"
          className={classes.day}
          value={minToString(workingDay.am_end)}
          onChange={e =>
            updateWorkingDay('am_end', stringToMin(e.target.value))
          }
          onBlur={saveWorkingDay}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <TextField
          type="time"
          variant="outlined"
          className={classes.day}
          value={minToString(workingDay.pm_start)}
          onChange={e =>
            updateWorkingDay('pm_start', stringToMin(e.target.value))
          }
          onBlur={saveWorkingDay}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <TextField
          type="time"
          variant="outlined"
          className={classes.day}
          value={minToString(workingDay.pm_end)}
          onChange={e =>
            updateWorkingDay('pm_end', stringToMin(e.target.value))
          }
          onBlur={saveWorkingDay}
        />
      </Box>
      <TextField
        variant="outlined"
        className={classes.day}
        value={workingDay.comment}
        onChange={e => updateWorkingDay('comment', e.target.value)}
        onBlur={saveWorkingDay}
      />
    </Box>
  )
}
