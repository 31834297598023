/**
 * The external imports
 */
import {
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import { GetAllClientMaintenancesService } from '../../Services/Maintenance'

export default {
  initialState: {
    getAllClient: {
      item: [],
      loading: false,
      error: null,
    },
  },
  action: buildAsyncActions(
    'maintenance/getAllClient',
    GetAllClientMaintenancesService,
  ),
  reducers: buildAsyncReducers({
    itemKey: 'getAllClient.item',
    errorKey: 'getAllClient.error',
    loadingKey: 'getAllClient.loading',
  }),
}
