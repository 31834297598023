/**
 * The external imports
 */
import React from 'react'
import clsx from 'clsx'

/**
 * The internal imports
 */
import useStyles from '../../Theme/Components/Datatable'

export default ({ value }) => {
  const classes = useStyles()
  return <div className={clsx(classes.boolean, value && classes.booleanTrue)} />
}
