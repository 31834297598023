/**
 * The external imports
 */
import {
  buildAsyncState,
  buildAsyncReducers,
  buildAsyncActions,
} from '@thecodingmachine/redux-toolkit-wrapper'

/**
 * The internal imports
 */
import DestroyService from '../../Services/Datatable/Destroy'

export default {
  initialState: buildAsyncState('destroy'),
  action: buildAsyncActions('client/destroy', DestroyService),
  reducers: buildAsyncReducers({
    itemKey: null,
    errorKey: 'destroy.error',
    loadingKey: 'destroy.loading',
  }),
}
