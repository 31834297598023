import i18n from '../Translations'

export const Config = {
  COUNTRIES: ['CH', 'FR'],
  ROWS_PER_PAGE: 15,
  DATE_FORMAT: 'dd.MM.yyyy',
  DATE_TIME_FORMAT: 'dd.MM.yyyy HH:mm',
  ABSENCE_TYPES: [
    'weekend',
    'publicHoliday',
    'holiday',
    'wagon',
    'army',
    'additional_hour',
    'sickness',
    'other',
    'pending',
  ],
  SECTORS: ['web', 'dev', 'all'],
  MAINTENANCE_STATUSES: ['ongoing', 'cancel', 'finish'],
  MAINTENANCE_KEYS: [
    'start_date',
    'missed_maintenances',
    'next_maintenance',
    'planned_intervention',
  ],
  DATATABLE_TRANSLATIONS: {
    body: {
      noMatch: i18n.t('datatables.body.no_match'),
      toolTip: i18n.t('datatables.body.tool_tip'),
      columnHeaderTooltip: column =>
        i18n.t('datatables.body.column_header_tooltip', { item: column.label }),
    },
    pagination: {
      next: i18n.t('datatables.pagination.next'),
      previous: i18n.t('datatables.pagination.previous'),
      rowsPerPage: i18n.t('datatables.pagination.rows_per_page'),
      displayRows: i18n.t('datatables.pagination.display_rows'),
      jumpToPage: i18n.t('datatables.pagination.jump_to_page'),
    },
    toolbar: {
      search: i18n.t('datatables.toolbar.search'),
      downloadCsv: i18n.t('datatables.toolbar.download_csv'),
      print: i18n.t('datatables.toolbar.print'),
      viewColumns: i18n.t('datatables.toolbar.view_columns'),
      filterTable: i18n.t('datatables.toolbar.filter_table'),
    },
    filter: {
      all: i18n.t('datatables.filter.all'),
      title: i18n.t('datatables.filter.title'),
      reset: i18n.t('datatables.filter.reset'),
    },
    viewColumns: {
      title: i18n.t('datatables.view_columns.title'),
      titleAria: i18n.t('datatables.view_columns.title_aria'),
    },
    selectedRows: {
      text: i18n.t('datatables.selected_rows.text'),
      delete: i18n.t('datatables.selected_rows.delete'),
      deleteAria: i18n.t('datatables.selected_rows.delete_aria'),
    },
  },
}
